import React, {useEffect, useRef} from 'react'
import ContentEditable from 'react-contenteditable'
import './InteractiveReferencesInput.css'

const InteractiveReferencesInput = ({
    referencesQuery,
    setReferencesQuery,
}: {
    referencesQuery: string
    setReferencesQuery: any
}) => {
    const ref = useRef()
    useEffect(() => {
        // const ele = document.getElementById('interactive-references-input')
        // const listener = function (e) {
        //     debugger
        //     if (ele?.innerHTML) setReferencesQuery(ele.innerHTML)
        // }
        // ele.addEventListener('input', listener)
        // return ele.removeEventListener('input', listener)
    })
    const handleChange = e => setReferencesQuery(e.target.value)

    return (
        <div
            style={{display: 'inline-flex'}}
            onClick={e => {
                e.stopPropagation()
            }}
        >
            &nbsp;{'to'}&nbsp;"
            <ContentEditable
                placeholder="..."
                innerRef={ref}
                html={referencesQuery} // innerHTML of the editable div
                disabled={true} // use true to disable editing
                onChange={handleChange} // handle innerHTML change
            />
            {/* <div
                id="interactive-references-input"
                contentEditable={true}
                className="interactive-references-input"
            >
                {referencesQuery}
            </div> */}
            "
        </div>
    )
}

export default InteractiveReferencesInput
