import * as React from 'react'
import {useDispatch, useSelector} from 'react-redux'
import TopLevelContext from '../../../../Contexts/TopLevelContext'
import {selectTitleIds, setTitleIds} from '../../../../Slices/AppSlice'
import ClosePageButton from '../../Titles/ClosePageButton'

const SwapPagesButtonNew = () => {
    const {switchPage} = React.useContext(TopLevelContext)
    const dispatch = useDispatch()
    const titleIds = useSelector(selectTitleIds)

    const onClick = e => {
        switchPage(titleIds[1])
        dispatch(setTitleIds([titleIds[1], titleIds[0]]))
    }
    return <ClosePageButton {...{onClick, symbol: '⇄'}} />
}

export default SwapPagesButtonNew
