const domainOfEmail = (email: string) => {
    const atIndex = email.indexOf('@')
    if (!(atIndex >= 0)) return
    const relevantPart = email.slice(atIndex + 1)
    return relevantPart
}
const isAuthorizedEmail = (email: string) => {
    return ['williams.edu', 'princeton.edu'].includes(domainOfEmail(email))
}

export default isAuthorizedEmail
