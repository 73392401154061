import {useContext, useEffect, useMemo, useState} from 'react'
import React from 'react'
import {useDispatch, useSelector} from 'react-redux'
import {gbl, simpleFirebaseWriter} from '../../PlexusDataContainer'
import {Button} from 'react-bootstrap'
import {Editor} from 'slate'
import {addPage, processPage} from '../../../UnifiedMutations/UnifiedMutations'
import {PlexusPage} from '../../../Algorithms/EntityRecognizer/PageInterfaces'

const ConceptifyButtonInternal = ({
    phrase,
    editor,
    hideToolbar,
    page,
    setValue,
    secondary,
}: {
    phrase: string
    editor: Editor
    hideToolbar
    page: PlexusPage
    setValue: Function
    secondary: boolean
}) => {
    const [expand, setExpand] = useState(false)
    const dispatch = useDispatch()

    const conceptify = event => {
        const selection = editor.selection

        const highlightedText = selection ? Editor.string(editor, selection) : ''
        if (highlightedText) {
            const {page: newPage, promise} = addPage(highlightedText, gbl, simpleFirebaseWriter)
            promise.then(() => {
                // editor.selection = undefined
                //then process this page again
                processPage(page, gbl, simpleFirebaseWriter, setValue).then(() => {
                    //Add underneath the current page
                    // simpleFirebaseWriter.dragAndDropNew(newPage.id, undefined, page.id)
                })
            })
        }
    }
    const handleClick = event => {
        if (phrase && phrase.length > 0) conceptify(event)
        hideToolbar()
    }

    return (
        <Button
            title={'Make page for "' + phrase + '"'}
            onClick={handleClick}
            id="conceptifyIcon"
            className={expand ? 'expand' : ''}
        />
    )
}

export default ConceptifyButtonInternal
