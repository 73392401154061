import {remove} from 'lodash'

type stripOptions = {
    stripFromBack: boolean
    stripFromFront: boolean
    stripCaps: boolean
    removeContractions?: boolean
}

export function splitSentences(sentences: string): string[] {
    return sentences ? cleanSentences(sentences.split('.')) : []
}
export const cleanSentences = (sentences: string[]) => {
    return sentences
        .filter((w: string) => w !== '' && w !== ' ')
        .map(sentence => sentence.trim())
}

export function splitNote(note: string): string[] {
    return note.split(' ').filter((w: string) => w !== '' && w !== ' ')
}

export function normalizeNoteText(
    text: string,
    stripOptions?: stripOptions
): {strippedWords: string[]; decoratorsPerWord: DecoratorsData[]} {
    if (typeof text === 'string') {
        const decoratedWords = splitNote(text)
        const strippedWordData = decoratedWords.map(word =>
            strip(
                word,
                ...(stripOptions
                    ? [
                          stripOptions.stripFromBack,
                          stripOptions.stripFromFront,
                          stripOptions.stripCaps,
                          stripOptions.removeContractions,
                      ]
                    : [])
            )
        )
        const strippedWords = strippedWordData.map(wordData => wordData.strippedText)
        const decoratorsPerWord: DecoratorsData[] = strippedWordData.map(
            wordData => wordData.decoratorsData
        )
        return {strippedWords, decoratorsPerWord}
    }
}

export const hackOffContractionEnds = (sentence: string): string =>
    stripTextPerWord(sentence, {
        stripFromBack: false,
        stripFromFront: false,
        stripCaps: false,
        removeContractions: true,
    })
export function stripTextPerWord(text: string, options?: stripOptions) {
    const {strippedWords} = normalizeNoteText(text, options)
    return strippedWords.reduce(
        (concatted, nextWord, index) => concatted + (index != 0 ? ' ' : '') + nextWord,
        ''
    )
}

export function strippedVersionsEqual(a: string, b: string) {
    return stripTextPerWord(a) === stripTextPerWord(b)
}

export interface DecoratorData {
    type: DECORATOR_TYPE
    payload: Array<any> | string
}

/**
 * Stored in order of how they should be reapplied
 */
export type DecoratorsData = DecoratorData[]

export function decorate(text: string, decorators: DecoratorsData): string {
    return decorators.reduce((previousText: string, nextDecoratorData: DecoratorData) => {
        const decorator: Decorator = decoratorBuilder(
            nextDecoratorData.type,
            nextDecoratorData.payload
        )
        return decorator(previousText)
    }, text)
}

export function decorateNoteText(text: string, decorators: DecoratorsData[]): string {
    if (text === null) {
        return text
    }
    const textArray = splitNote(text)
    let decoratedText = ''
    for (let i = 0; i < textArray.length && i < decorators.length; i++) {
        decoratedText = decoratedText.concat(decorate(textArray[i], decorators[i]) + ' ')
    }
    return decoratedText.trim()
}

/**
 * **
 * Strips a specified string of all beginning/ending non-alphanumeric characters and all capitalization, recording each change so it can be reversed later.
 *  The intended purpose of this function is to normalize the given text for knowledge-graph-building analysis.
 * This fn uses `unshift` rather than `push`, so that when the puncuation/capitalization is added back later,
 *  it'll be added in the correct order
 * @param text a specified text string
 * @param shouldStripFromBack whether to strip punctuation from the back of the given text
 * @param shouldStripFromFront whether to strip punctuation from the front of the given text
 * @param shouldStripCaps whether to strip capitalization from the given text
 * @returns the normalized text, as well as a list of all the operations that
 *  were performed to produce it from the starting text string.
 */
export function strip(
    text: string,
    shouldStripFromBack: boolean = true,
    shouldStripFromFront: boolean = true,
    shouldStripCaps: boolean = true,
    removeContractions: boolean = false
): {strippedText: string; decoratorsData: DecoratorsData} {
    let decoratorsData: DecoratorsData = []
    let strippedText = text

    //Define the three kinds of stripping
    const stripFromBeginning = () => {
        //check if starts with a non-alphanumeric character
        while (
            strippedText.length > 0 &&
            !isAlphaNumeric(strippedText.charAt(0)) &&
            //# causes firebase issue to exclude
            !['@'].includes(strippedText.charAt(0))
        ) {
            const firstChar = strippedText.charAt(0)
            decoratorsData.unshift({
                type: DECORATOR_TYPE.INSERT_BEFORE,
                payload: firstChar,
            })

            //Delete the string's last character
            strippedText = strippedText.substring(1)
        }
    }
    function stripFromBack() {
        //Then check if ends with non-alphanumeric characters or " 's "
        while (
            strippedText.length > 0 &&
            (!isAlphaNumeric(strippedText.charAt(strippedText.length - 1)) ||
                endsWithApostrS(strippedText))
        ) {
            //record the character that is to be removed and added back
            const lastChars = endsWithApostrS(strippedText)
                ? "'s"
                : strippedText.charAt(strippedText.length - 1)

            decoratorsData.unshift({
                type: DECORATOR_TYPE.INSERT_AFTER,
                payload: lastChars,
            })

            //Delete the string's last characters.
            strippedText = strippedText.substring(0, strippedText.length - lastChars.length)
        }
    }
    function stripCaps() {
        //check if capitalized--if so, which letters
        //if they're all capitalized, don't bother. e.g., NBA, YEAH. it's probably an acronym
        let charIndices: number[] = []

        //first check if they're more than one letter and all capitalized--don't do anything in that case.
        //'US' <--> 'us'
        //But 'I' and 'A' should be treated as equivalent to 'i' and 'a'
        if (!(strippedText.length > 1 && strippedText === strippedText.toUpperCase())) {
            //Go through character by character
            for (let i = 0; i < strippedText.length; i++) {
                const theChar = strippedText.charAt(i)
                //if the given character is uppercase
                if (theChar === theChar.toUpperCase()) {
                    //Record this index, to be capitalized later
                    charIndices.push(i)
                    //Convert the given character to lowercase
                    strippedText =
                        strippedText.substring(0, i) +
                        theChar.toLowerCase() +
                        strippedText.substring(i + 1)
                }
            }
            if (charIndices.length > 0)
                decoratorsData.unshift({
                    type: DECORATOR_TYPE.CAPITALIZE,
                    payload: charIndices,
                })
        }
    }

    //Execute them. Important that capitalization is last.

    //only execute if at least one character is alphanumeric
    if (atLeastOneAlphaNumChar(strippedText)) {
        if (shouldStripFromFront) stripFromBeginning()
        if (shouldStripFromBack) stripFromBack()
        if (shouldStripCaps) stripCaps()
        if (removeContractions) strippedText = removeEndContraction(strippedText)
    }

    return {strippedText, decoratorsData: decoratorsData}
}

export const insertAfter = (text: string, appendix: string) => text + appendix
export const insertBefore = (text: string, prependix: string) => prependix + text
/**
 *
 * @param text
 * @param charsIndices these are indices in the stripped version of the text, not in the decorated (i.e.,  punctuated) verison
 */
export const capitalize = (text: string, charsIndices: number[]) => {
    return charsIndices.reduce(
        (prevText: string, nextIndex: number) => {
            return (
                prevText.substring(0, nextIndex) +
                prevText.charAt(nextIndex).toUpperCase() +
                prevText.substring(nextIndex + 1)
            )
        },
        text ? text : ''
    )
}
//always apply capitalization punctuators before grammar punctuation punctuators

//Whether the whole string is alphanumeric
export function isAlphaNumeric(str: string, emptyStringCounts: boolean = true) {
    var code, i, len

    if (str === '' && !emptyStringCounts) return false

    for (i = 0, len = str.length; i < len; i++) {
        code = str.charCodeAt(i)
        if (
            !(code > 47 && code < 58) && // numeric (0-9)
            !(code > 64 && code < 91) && // upper alpha (A-Z)
            !(code > 96 && code < 123) // lower alpha (a-z)
        ) {
            return false
        }
    }
    return true
}

export function charIsAlphaNumeric(
    char: string,
    disregardAlpha?: boolean,
    disregardNumeric?: boolean,
    justEnglish: boolean = false
) {
    const code = char.charCodeAt(0)
    return (
        (!disregardNumeric && charCodeIsNumeric(code)) ||
        (!disregardAlpha && (justEnglish ? charCodeIsAlpha(code) : isCharacterALetter(char))) //non english
    )
}

//for multilingual
export function isCharacterALetter(char): boolean {
    return RegExp(/^\p{L}/, 'u').test(char)
    // char.toLowerCase() != char.toUpperCase()
}

export function anyCharIsAlphanumeric(text: string, disregardNumeric: boolean = false) {
    for (let i = 0; i < text.length; i++) {
        if (charIsAlphaNumeric(text[i], false, disregardNumeric)) return true
    }
    return false
}

// !(code > 47 && code < 58) && // numeric (0-9)
//         !(code > 64 && code < 91) && // upper alpha (A-Z)
//         !(code > 96 && code < 123) // lower alpha (a-z)
export function charCodeIsNumeric(code: number) {
    return code > 47 && code < 58
}

export function charIsEnglishLetter(char: string) {
    return charCodeIsAlpha(char.charCodeAt(0))
}
export function charCodeIsAlpha(code: number) {
    return (
        (code > 64 && code < 91) || // upper alpha (A-Z)
        (code > 96 && code < 123)
    )
}

export enum DECORATOR_TYPE {
    INSERT_BEFORE = 'before',
    INSERT_AFTER = 'after',
    CAPITALIZE = 'capitalize',
}

interface Decorator {
    (text: string): string
}
function decoratorBuilder(type: DECORATOR_TYPE, payload) {
    switch (type) {
        case DECORATOR_TYPE.INSERT_BEFORE:
            console.assert(typeof payload === 'string')
            return (text: string) => insertBefore(text, payload)
        case DECORATOR_TYPE.INSERT_AFTER:
            console.assert(typeof payload === 'string')
            return (text: string) => insertAfter(text, payload)
        case DECORATOR_TYPE.CAPITALIZE:
            console.assert(payload.length)
            return (text: string) => capitalize(text, payload)
        default:
            console.error('Decorator builder not called with proper parameters.', type, payload)
            return (text: string) => text
    }
}

const atLeastOneAlphaNumChar = (text: string) => {
    for (let i = 0; i < text.length; i++) {
        const char: string = text.charAt(i)
        if (isAlphaNumeric(char)) return true
    }
    return false
}

/**
 * Used on input text to match against suggestions
 * @param text
 */
export const stripCurrentTyping = (
    text: string
): {strippedText: string; decoratorsData: DecoratorsData} => {
    return strip(text, false)
}

/**
 * Strips every word in a given string then re-concats
 *
 */
export const stripNoteText = (noteText: string) => {
    return normalizeNoteText(noteText).strippedWords.join(' ')
}

//add quotations to beginning and end
export const wrapWithQuotations = (str: string) => {
    const firstCharIsQuote = str.startsWith('"') || str.startsWith("'")
    const lastCharIsQuote = str.endsWith('"') || str.endsWith("'")
    const newStr = `"${str.slice(firstCharIsQuote ? 1 : 0, lastCharIsQuote ? -1 : undefined)}"`
    return newStr
}

export const endsWithApostrS = (word: string) => {
    const apostropheS = ["'s", '’s']
    return apostropheS.includes(word.substr(-2))
}

export const endsWithApostr = (word: string) => {
    const apostropheS = ["'", '’']
    return apostropheS.includes(word[word.length - 2])
}

export const removeEndContraction = (word: string) => {
    return endsWithApostr(word) && word.length > 2 ? word.slice(0, -2) : word
}
