import React from 'react'
import ReactDOM from 'react-dom'
import './index.css'
import 'bootstrap/dist/css/bootstrap.min.css'

import App from './Components/App'
import {Provider} from 'react-redux'
import store from './store'
import firebase from 'firebase/app'
import firebaseConfig from './FirebaseConfig'
import {FirebaseAuthProvider} from '@react-firebase/auth'
import 'bootstrap/dist/css/bootstrap.min.css'
import * as FullStory from '@fullstory/browser'

FullStory.init({orgId: '172HS4'})

ReactDOM.render(
    <Provider store={store}>
        <App />
    </Provider>,
    document.getElementById('root')
)

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
// serviceWorker.unregister();
