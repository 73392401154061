import React, {useContext, useMemo, useState} from 'react'
import {useDispatch, useSelector} from 'react-redux'
import TopLevelContext from '../../../Contexts/TopLevelContext'
import {selectTitleIds, setTitleIds} from '../../../Slices/AppSlice'
import {selectPages} from '../../../Slices/PagesSlice'
import {isMobile} from '../../App'
import FolderLogic from '../../PageStore/FolderLogic/FolderLogic'
import PageSectionTitle from '../References/PageSectionTitle/PageSectionTitle'
import '../References/References.css'
import ReferenceSectionTitle from '../References/ReferenceSectionTitle'

const PageChildren = ({page}) => {
    const pages = useSelector(selectPages)
    const {switchPage} = useContext(TopLevelContext)
    const children = useMemo(() => {
        const result = FolderLogic.findChildren(page, pages).sort(
            (a, b) => b.lastUpdated - a.lastUpdated
        )
        return result
    }, [page, pages])
    const [open, setOpen] = useState(true)
    const titleIds = useSelector(selectTitleIds)
    const dispatch = useDispatch()
    return children && children.length > 0 ? (
        <div className="references">
            <div>
                {open ? (
                    children.map(child => (
                        <div
                            className="references-from-a-page"
                            onClick={() => {
                                if (child.id !== titleIds[0])
                                    isMobile
                                        ? switchPage(child.id)
                                        : dispatch(setTitleIds([titleIds[0], child.id]))
                            }}
                        >
                            <ReferenceSectionTitle page={child} />
                        </div>
                    ))
                ) : (
                    <></>
                )}
            </div>
        </div>
    ) : (
        <></>
    )
}
export default PageChildren
