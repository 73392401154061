import React, {useContext} from 'react'
import {useDispatch, useSelector} from 'react-redux'
import TopLevelContext from '../../../Contexts/TopLevelContext'
import {selectTitleIds, setTitleIds} from '../../../Slices/AppSlice'
import {isMobile} from '../../App'

const ReferenceSectionTitle = ({page}) => {
    const {switchPage} = useContext(TopLevelContext)
    const titleIds = useSelector(selectTitleIds)
    const dispatch = useDispatch()
    return (
        <div
            className="reference-item-title"
            onClick={() => {
                if (page.id !== titleIds[0])
                    isMobile
                        ? switchPage(page.id)
                        : dispatch(setTitleIds([titleIds[0], page.id]))
            }}
        >
            {`${page.title}`}
        </div>
    )
}

export default ReferenceSectionTitle
