import React, {useEffect} from 'react'
import '../sidebar.css'
import Actions from './Actions'
import {useSelector} from 'react-redux'
import {selectSeeSettings} from '../../../Slices/DisplaySlice'
import '../generalSidebar.css'

function RightSidebar() {
    const seeSettings = useSelector(selectSeeSettings)

    useEffect(() => {
        // let sa = document.getElementById('plexusLogo')
        let sb = document.getElementById('sidebar')
        if (!seeSettings) {
            let sb = document.getElementById('sidebar')
            sb.classList.add('sidebar-in')
            // sa.classList.add('rotate-up')
            // sa.classList.remove('rotate-down')
        } else {
            sb.classList.remove('sidebar-in')
            // sa.classList.add('rotate-down')
            // sa.classList.remove('rotate-up')
        }
    }, [seeSettings])
    return (
        <div className="sidebar-general sidebar sidebar-in" id="sidebar">
            {/* <Settings /> */}
            <Actions />
            {/* <Shortcuts shortcuts={KeyFunctionMap} /> */}
        </div>
    )
}
export default RightSidebar

/* if(comp.nodes().size()>3){
                let clusters = comp.kMedoids(
                  {k: 2,
                  attributes: [
                   function( node ){ return Math.random() }
                ]});
                //let realClusters =[];
                let realClusters = clusters.map(c => {
                  console.log('cxted edges: ' + c.connectedEdges().map(ele=> ele.data('name')))
                  return c.union(c.connectedEdges())
                });
                console.log('clusters', realClusters);
            
            return realClusters.map(cluster=>{
              return (<div className={classes} id ={cluster[0].id()} onClick={()=>setTitleNode(cluster[0])}>
                  <CytoscapeComponent
                      autounselectify={true}
                      elements={cluster.jsons()}
                      style={ { width: '200px', height: '100px' } }
                      layout={{name: 'cose'}}
                      userPanningEnabled={false}
                      userZoomingEnabled={false}
                      autoungrabify={true}
                      boxSelectionEnabled={false}
                  />
                  <p className="componentTitle">{cluster[0].data('name')}</p>
              </div>
            )});
            } */
