import React, {useContext, useState} from 'react'
import {useDispatch, useSelector} from 'react-redux'
import {selectSeeSettings, setSeeSettings} from '../../../../Slices/DisplaySlice'
import {selectShowStore} from '../../../../Slices/ConceptStoreSlice'

import './SeeSettingsButton.css'
import '../HeaderButtons.css'
import {isMobile} from '../../../App'
const SeeSettingsButton = () => {
    const seeSettings = useSelector(selectSeeSettings)
    const dispatch = useDispatch()
    const [clicked, setClicked] = useState(false)
    const showStore = useSelector(selectShowStore)

    return isMobile && showStore ? (
        <></>
    ) : (
        <div
            id="seeSettingsButton"
            className={'headerButton' + (seeSettings ? ' on' : '')}
            onClick={() => {
                dispatch(setSeeSettings(!seeSettings))
                setClicked(prev => !prev)
            }}
            title={'See settings'}
        >
            <div id="seeSettingsIcon" style={{fontSize: '1.4em'}}>
                &#x2699;
            </div>
        </div>
    )
}

export default SeeSettingsButton
