import {createSlice} from '@reduxjs/toolkit'
import {analytics} from '../Components/App'

export const UploadSlice = createSlice({
    name: 'upload',
    initialState: {
        uploading: false,
        modalShow: false,
        uploadFile: '',
        uploadText: 'none',
        showExportModal: false,
    },
    reducers: {
        uploading: (state, payload?: Object) => {
            state.uploading = true
        },
        doneUploading: state => {
            state.uploading = false
        },
        showModal: state => {
            state.modalShow = true
        },
        hideModal: state => {
            state.modalShow = false
        },
        showUploadFile: state => {
            state.uploadFile = ''
            state.uploadText = 'none'
        },
        noUploadFile: state => {
            state.uploadFile = 'none'
            state.uploadText = ''
        },
        setShowExportModal: (state, action: {payload: boolean}) => {
            analytics.logEvent('looking_to_export')
            state.showExportModal = action.payload
        },
    },
})

export const {
    uploading,
    doneUploading,
    showModal,
    hideModal,
    showUploadFile,
    noUploadFile,
    setShowExportModal,
} = UploadSlice.actions
export const selectUpload = state => state.upload.uploading
export const selectModal = state => state.upload.modalShow
export const selectUploadFile = state => state.upload.uploadFile
export const selectUploadText = state => state.upload.uploadText
export const selectShowExportModal = state => state.upload.showExportModal

export default UploadSlice.reducer
