import React, {useCallback, useEffect, useState} from 'react'
import {useDispatch, useSelector} from 'react-redux'
import {modes} from 'react-transition-group/SwitchTransition'
import {PlexusPage} from '../../Algorithms/EntityRecognizer/PageInterfaces'
import PlexusLogo from '../../Assets/PlexusLogo.png'
import {selectTitleIds} from '../../Slices/AppSlice'
import {selectPage, selectPages, setPageFull} from '../../Slices/PagesSlice'
import {timeFunction} from '../../Testing/NotesTesting'
import {processPage} from '../../UnifiedMutations/UnifiedMutations'
import {analytics} from '../App'
import {SlateNoteElement} from '../Page/SimplerSlateEditor/EditorContainer/slateConfig'
import {gbl, simpleFirebaseWriter} from '../PlexusDataContainer'
import './DetectEntitiesButton.css'

const DetectEntitiesButton = ({setValue, editor, secondary}) => {
    // const [detected, setDetected ] = useS
    const titleIds = useSelector(selectTitleIds)
    const titleId = titleIds[0]
    const secondaryTitleId = titleIds[1]
    const page: PlexusPage = useSelector(selectPage(secondary ? secondaryTitleId : titleId))
    const dispatch = useDispatch()
    const pages = useSelector(selectPages)
    const [loading, setLoading] = useState(false)

    /**
     * process the page in dictionary (and implicitly in firebase)
     * find entities and make new slate value (TODO: implicitly in firebase)
     * force update the current page
     */
    const processThisPage = () => {
        setLoading(true)
        analytics.logEvent('find_connections_in_page', {
            numberOfLinesOnPage: page && page.slateValue ? page.slateValue.length : 0,
            auth_id: simpleFirebaseWriter.auth_id,
        })
        setTimeout(
            () =>
                processPage(page, gbl, simpleFirebaseWriter, setValue, pages).finally(() => {
                    setLoading(false)
                }),
            2
        )
    }

    const processingNames = ['CONNECT', 'lumos']
    const [nameIndex, setNameIndex] = useState(0)
    return (
        <div
            title="Process page for meaningful repeating entities (option + p)."
            className="stupid-button"
            onClick={processThisPage}
        >
            {loading ? 'connecting...' : 'CONNECT'}
        </div>
    )
}

export default DetectEntitiesButton
