import {SlateNoteElement} from '../../Components/Page/SimplerSlateEditor/EditorContainer/slateConfig'
import {PageMap} from '../GraphBuilding/GraphBuilderLite/GraphBuilderLite'

//Contains a lot of interfaces concerning the structure of pages and paragraphs of notes in Plexus.
// These representations are used throughout the client logic, and also correspond to how
//  page data is stored in Firebase.

//for testing
export interface PlexusPageCore {
    id: string //unchanging, not text based
    slateValue: SlateNoteElement[] //paragraph array
    paragraphs?: {[id: string]: PlexusParagraph}
    incomers: PageMap //plexus page locations that reference this one
    outgoers: PageMap
    domain?: string
}

export enum PageOriginStory {
    'MANUAL' = 'MANUAL',
    'AUTOMATIC' = 'AUTOMATIC',
    'TRANSFER' = 'TRANSFER',
    'ACCEPTED' = 'ACCEPTED',
}

//the real one to use
export interface PlexusPage extends PlexusPageCore {
    author_id: string
    title: string
    originStory?: PageOriginStory
    lastUpdated?: number //second since 1970
    children?: PlexusPage[]
    parents?: PlexusPage[]
}

//for both incomers and outgoers
export interface PlexusPageLocation {
    id: string //document id
    location: PlexusLocationInPage
}

export interface PlexusParagraph {
    pageId: string //document id
    paragraphId: string
    paragraphIndex?: number
    text: string
    wordIndex?: number //word index?
    cleanWords: string[]
}

export interface PlexusWordInParagraph extends PlexusParagraph {
    rawWordIndex: number //index of the raw word
    word?: string //maybe the word itself is provided, assume it's decorated
}

export interface PlexusLocationInPage {
    paragraphIndex: number
    startWordIndex?: number
    startCharIndex?: number
    text?: string
}
