// Initialize Firebase
const firebaseConfig = {
    apiKey: 'AIzaSyA6nNO8RWXZQ5MzBT27rTtGafX0CAmwHfw',
    authDomain: 'plexusnotes-feacf.firebaseapp.com',
    databaseURL: 'https://plexusnotes-feacf.firebaseio.com',
    projectId: 'plexusnotes-feacf',
    storageBucket: 'plexusnotes-feacf.appspot.com',
    messagingSenderId: '689832187834',
    appId: '1:689832187834:web:7748282d2d0a2387ad6f83',
    measurementId: 'G-PRJRRE9BB5',
}

export default firebaseConfig
