import React from 'react'
import PumpingPlexLoader from './PumpingPlexLoader'

const Loading = ({text = 'Waking'}) => (
    <p id="loading">
        {/* {text} */}
        <br></br>
        <PumpingPlexLoader />{' '}
    </p>
)
export default Loading
