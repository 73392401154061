import React from 'react'
import Typist from 'react-typist'

const OneLiner = () => {
    return (
        <div className="oneLiner">
            <Typist
                avgTypingDelay={80}
                stdTypingDelay={8}
                cursor={{
                    show: true,
                    blink: true,
                    element: '|',
                    hideWhenDone: true,
                    hideWhenDoneDelay: 1000,
                }}
            >
                <Typist.Delay ms={3000} />
                Plexus <Typist.Delay ms={100} /> is a notebook that organizes itself,
                <br /> <Typist.Delay ms={200} />
                linking <Typist.Delay ms={100} />
                <span className="importantPhrase">people</span>, <Typist.Delay ms={100} />
                <span className="importantPhrase">places</span>, and <Typist.Delay ms={100} />
                <span className="importantPhrase">ideas</span> across <Typist.Delay ms={100} />
                scattered pages.
            </Typist>
        </div>
    )
}

export default OneLiner
