///import {} from './ModifyNotesTesting';
// function test(cy, home, setHome) {
//     // testEmphasis(cy);
//     // testEdgesComeInPairs(cy);
//     // console.log(cy.nodes()[0].data('clicksObj'))
//     // resetAllClicksObjects(cy, home, setHome);
//     let Davey = cy.nodes()[0]
//     let Mark = cy.nodes('[name="Mark"]')[0]
//     // console.log('Edges between first and second nodes', Davey.edgesWith(minds).map(e => e.data('name')))
//     // console.log(Davey.edgesWith(Mark).map(e => [e.data('isInverse'), e.data('name')]))
//     console.log('hello')
//     window.addEventListener('scroll', function (evt) {
//         console.log('scroll target is ', evt.target)
//         // document.getElementById('showScroll').innerHTML = window.pageYOffset + 'px';
//     })
// }
// //Testing functionality of making graph have no labels on edges, converting all labeled edges
// function testLabelessGraph(cy) {
//     console.log('testing labelless graph')
//     console.log(cy.edges(edge => edge.data('name')).map(ele => ele.data()))
// }
// function testEdgeWeightSetting(cy) {
//     //console.log('testEdgeEmphases');
//     let cyTest = copyElements(cy) //get copy, not reference, of original cy object for testing purposes.
//     let Davey = cyTest.nodes('[name="Davey"]')[0]
//     //console.log('Davey', Davey.data());
//     let elesForTest = Davey.closedNeighborhood()
//     //console.log('Eles for test', elesForTest.filter('node').map(ele=> ele.data()))
//     let relevantEdges = elesForTest.edgesWith(elesForTest)
//     // let realTestEles = elesForTest.union(relevantEdges);
//     // console.log('real eles for test', realTestEles.map(ele=> ele.data()));
//     elesForTest = elesForTest.filter('node')
//     console.log(
//         'eles for test',
//         elesForTest.map(node => node.data())
//     )
//     //now do emphasis calculations here
//     //need to define each edge's weight prior to using the thang
//     //initializeEdgeWeights(cyTest);
//     // console.log('rel edges', relevantEdges.map(edge => edge.data()));
//     //console.log('new weights', elesForTest.filter('edge').map(edge => [edge.data('name'), edge.data('weight')]))
//     // testGetTotalIncidence(Davey);
//     testAllWeightsAreDefined(cyTest)
// }

// function testEmphasis(cyTest) {
//     let Davey = cyTest.nodes()[0]
//     console.log('Davey node data. ', Davey.data('clicksObj'))

//     console.log('testing how related')
//     //testHowRelated(cyTest);
// }
// // function exploreFreqToVersusOutgoers(){
// //     let Davey = cyTest.nodes()[0];
// //     let a = getTotalOutgoingWeight(Davey);
// //     let b = getTotalFreqOut(Davey);
// //     let ft = Object.entries(Davey.data('freqTo'))
// //     let outgoers = Davey.outgoers('edge');
// //     console.log('Daveys freqTo', ft);
// //     console.log( 'Daveys outgoers', outgoers);
// //     //get keys that are different between the the two
// //     let outIds = outgoers.toArray().map(ele => {
// //         let otherNode = ele.connectedNodes().difference(Davey);
// //         return otherNode.id();
// //     } );
// //     console.log('outgoer ids', outIds)
// //     console.log('ft keys', Object.keys(Davey.data('freqTo')));
// //     let difference = arr_diff(outIds, Object.keys(Davey.data('freqTo')));
// //     console.log('difference', difference, '\ndiff names', difference.map(ele => cyTest.getElementById(ele).data('name')) );

// //     console.log('davey freqto', Davey.data('freqTo'), 'davey outgoing tally: ', a,  'davey outgoing freqs', b );

// // }

// function copyElements(cy) {
//     let cyTestElements = Object.assign([], cy.elements()).map(ele => {
//         return {data: ele.data()}
//     })
//     let cyTest = cytoscape({elements: cyTestElements})
//     //checks that the copy of cy (cyTest) is really a copy and not a reference
//     //console.log(cy.elements().length!=cyTest.elements().length);
//     return cyTest
// }
// export default test

// function testInitializeEmptyFreqs(cy) {
//     let cyTest = copyElements(cy)
//     initializeEmptyFreqs(cyTest)
//     console.log(
//         'testing InitializeEmptyFreqs fx',
//         cyTest.nodes().map(node => node.data('freqTo'))
//     )
// }
// function didFreqChangeWork(cy) {
//     cy.nodes().forEach(node => {
//         node.outgoers('node').forEach(node2 => {
//             if (node.id() !== node2.id()) {
//                 if (!node2.id() in node.data('freqTo')) {
//                     console.log(
//                         'No freqTo relation between',
//                         node.data('name'),
//                         'and',
//                         node2.data('name')
//                     )
//                 } else
//                     console.log(
//                         'freqTo relation between',
//                         node.data('name'),
//                         'and',
//                         node2.data('name')
//                     )
//             }
//         })
//     })
// }
// function testGetTotalIncidence(Davey) {
//     console.log('total incidence of Davey', getTotalFreqOut(Davey))
// }

// function testAllWeightsAreDefined(cy) {
//     if (
//         cy
//             .edges()
//             .filter(edge => edge.data('weight') == undefined)
//             .size() == 0
//     ) {
//         console.log('All edge weights are defined.')
//     } else {
//         console.log(
//             `The following are the undefined weights: \n
//             ${cy
//                 .edges()
//                 .filter(edge => edge.data('weight') == undefined)
//                 .map(edge => [
//                     edge.source().data('name'),
//                     edge.target().data('name'),
//                     edge.data('weight'),
//                 ])}`
//         )
//     }
// }
// function testGetTotalOutgoingTally() {
//     let cyTest = cytoscape({
//         elements: {nodes: nodes_for_emph_test, edges: edges_for_emph_test},
//     })
//     console.log(
//         'Elements for testGetTotalOutgoingWeight are:',
//         cyTest.elements().map(ele => ele.data())
//     )
//     initializeEdgeWeights(cyTest, 1)
//     let A = cyTest.nodes()[0]
//     console.log('Test element A: ', A.data())
//     console.log('Total outgoing tally for element A:', getTotalOutgoingWeight(A))
// }
// function testGetUniqueId(cy) {
//     console.log('Testing get unique id')
//     console.log('getting a unique id', getUniqueId(cy, 'emphasis'))
// }
// // function testRecordClick(cy){
// //     updateNotes(cy.nodes()[0]);
// //     console.log('New click on ?', newTitle.data('name'), newTitle.data('clicks') )
// // }

// function testingEmphParams() {
//     // for(let howGradual = 1; howGradual==1; howGradual++){
//     //     sumssums[howGradual] = calcEmph(cyTest.nodes(), 8, howGradual, true, null, null, true);
//     // }
//     // for(let numIters = 1; numIters<=34; numIters+=10){
//     //     calcEmph(cyTest.nodes(), numIters, 1, true, null, null, true);
//     // }
//     //console.log('sum Sums', sumssums.map(row => row.slice(0,10)));
// }

// export function logPerEle(eles, prop, message) {
//     console.log(
//         message,
//         eles.map(ele => [ele.data('name'), ele.data(prop)])
//     )
// }

// function arr_diff(a1, a2) {
//     var a = [],
//         diff = []

//     for (var i = 0; i < a1.length; i++) {
//         a[a1[i]] = true
//     }

//     for (var i = 0; i < a2.length; i++) {
//         if (a[a2[i]]) {
//             delete a[a2[i]]
//         } else {
//             a[a2[i]] = true
//         }
//     }

//     for (var k in a) {
//         diff.push(k)
//     }

//     return diff
// }

// export function getOtherNode(edge, node) {
//     return edge.connectedNodes().difference(node)
// }
// function testAddInverseTagForEveryEdgePair() {
//     let cyTest = cytoscape({
//         elements: {nodes: nodes_simple, edges: edgesForIsInverse},
//     })
//     addInverseTagForEveryEdgePair(cyTest)
//     console.log(cyTest.edges().map(e => e.data()))
// }
// function testGetTotalRelevantFreq(cy) {
//     let cyTest = copyElements(cy)
//     let Davey = cyTest.nodes()[0]
//     let outgoingSum = getTotalRelevantFreq(Davey)
//     console.log('sum of outgoing weights from Davey: ', outgoingSum)
//     let list = Davey.outgoers('node')
//         .map(node => Davey.data('freqTo')[node.id()] / outgoingSum)
//         .filter(ele => ele != 0)
//     console.log('weights of outgoing edges from Davey: ', list, sum(list))
// }
export function sum(obj) {
    var sum = 0
    for (var el in obj) sum += parseFloat(obj[el])
    return sum
}

export const timeFunction = (fx, functionName, threshold = 0) => {
    let t0 = performance.now() //for debugging
    let result = fx()
    let t1 = performance.now()
    const msElapsed = t1 - t0
    if (msElapsed >= threshold)
        console.log('Call to ' + functionName + ' took ' + (t1 - t0) + ' milliseconds.')
    return result
}

export const msElapsedWithResult = fx => {
    let t0 = performance.now() //for debugging
    const result = fx()
    let t1 = performance.now()
    return [t1 - t0, result]
}

export const msElapsed = fx => {
    let t0 = performance.now() //for debugging
    fx()
    let t1 = performance.now()
    return t1 - t0
}

export const testEdgesComeInPairs = cy => {
    let inverse
    let edgeIs
    let invIs
    cy.edges().forEach(edge => {
        inverse = cy.$id(edge.data('inverse_id'))
        edgeIs = edge.data('isInverse') || false
        invIs = inverse.data('isInverse') || false
        if (edgeIs && invIs) {
            console.log(edge.data('name'), 'and', inverse.data('name'), 'both inverses')
        }
        // else if(edgeIs && !invIs) {
        //     console.log(edge.data('name'), 'but not', inverse.data('name'), 'are inverses')
        // } else if(!edgeIs && invIs) {
        //     console.log('not', edge.data('name'), 'but', inverse.data('name'), 'are inverses')
        // }
        else if (edgeIs && invIs) {
            console.log(edge.data('name'), 'nor', inverse.data('name'), 'are inverses')
        }
    })
}

// console.log('rankings for ', newTitle.data('name'), stringifyBulletStructure(rankings));
// let rankings = rankBullets(recentNodes, numClicks, .5, 0.0001, newTitle, cyNotes, indentThresh, 10);
