import {anyCharIsAlphanumeric, strip} from '../../TextNormalization/TextNormalization'
import GanorbergTrie from './GanorbergTrie'
import PlexusEntityRecognizer from '../../EntityRecognizer/EnglishEntitiesHelpers/PlexusEntityRecognizer'
import {arraysEqual} from '../../../Miscellaneous/Miscellaneous'
import {
    PlexusPage,
    PlexusPageCore,
    PlexusParagraph,
    PlexusWordInParagraph,
} from '../../EntityRecognizer/PageInterfaces'
import {Node} from 'slate'
import {getRawWords} from '../../EntityRecognizer/EnglishEntitiesHelpers/EnglishEntities'
import FirebaseWriter22 from '../../../BackendDataManagement/Jan2022/FirebaseWriter22'
import {SlateNoteElement} from '../../../Components/Page/SimplerSlateEditor/EditorContainer/slateConfig'
import uniqid from 'uniqid'
import {timeFunction} from '../../../Testing/NotesTesting'
import {Record} from 'immutable'
import {paragraphFromSlateNote} from '../../../UnifiedMutations/UnifiedMutations'

export interface ParagraphMap {
    [paragraphId: string]: PlexusParagraph
}
export interface PlexusPagesMap {
    [pageId: string]: any
}
export interface PlexusMapOfPages {
    [pageId: string]: PlexusPage
}
export interface PageMap {
    [pageId: string]: ParagraphMap
}

/**
 * An object that stores all the locations where a given word occurs.
 */
export interface AllWordLocations {
    [pageId: string]: PageWords
}

/**
 * An object that stores all paragraph-locations in a given page where a given word occurs.
 * @param word the cleaned word (lemmatized, stripped of outside punctuation)
 * the number value associated with each word represents that word's word index in the given paragraph.
 */
export interface PageWords {
    [paragraphId: string]: ParagraphWords
}
export interface ParagraphWords {
    [word: string]: number
}

/**
 * Is the class responsible for creating + storing + updating a person's dictionary of words.
 * GraphBuilderLite.words is a trie of all the words the person has ever used.
 * Each word's entry (returned by from GraphBuilderLite.words.get(word)) stores all of the locations in which that word occurs.
 *
 * The GraphBuilderLite.words trie store every word the person has used across all of their Plexus documents.
 * @SashaLopoukhine asked why every word? Rather than just significant words?
 *   Answer: important to store every word, even if it doesn't seem significant or rare,
 *       because the person might want to query for a phrase with that word in it eventually.
 *          (Either directly via the search bar,
 *            or indirectly via page-processing, which looks for repetition)
 *
 * Purposes of this class:
 *  (All the purposes center around the trie)
 * 1. Enables near-constant time repetition lookup
 * 2. Enable very efficient keyword search for traditional search bar
 *
 *
 * TODO rename this class to something more intelligible. current name is based on a previous version, but doesn't reflect its current function.
 */
class GraphBuilderLite {
    pages: {[id: string]: PageWords} //word index //pageIds // sort of abandoning this lookup function for now. not saved to firebase. (eventually, maybe save, but for now has no clear purpose, esp when text is saved individually in each word)
    words: GanorbergTrie<PageMap>
    backendWriter: FirebaseWriter22

    constructor(backendWriter?: FirebaseWriter22, pageWords?: AllWordLocations) {
        this.backendWriter = backendWriter
        this.pages = pageWords ? pageWords : {}
        this.words = new GanorbergTrie<PageMap>()
    }

    initializePageWords = (pagesWords: AllWordLocations) => {
        this.pages = pagesWords
    }

    initializeDictionary = (val: {[wordKey: string]: PageMap}) => {
        if (!val) return
        Object.keys(val).forEach((serialWordKey: string) => {
            const pageMap: PageMap = val[serialWordKey]

            //get deserialized version of the wordKey
            const wordKey: string = FirebaseWriter22.getWordFromKey(serialWordKey)

            //don't set through setWord here, because don't want to update firebase. update directly
            this.words.put(wordKey, pageMap)
        })
    }
    ensurePage = (pageId: string): {[paragraphId: string]: {[word: string]: number}} => {
        if (!(pageId in this.pages) || typeof this.pages[pageId] !== 'object')
            this.pages[pageId] = {}
        return this.pages[pageId]
    }
    ensureParagraph = (pageId: string, paragraphId: string) => {
        const pageRef = this.ensurePage(pageId)
        try {
            if (!(paragraphId in pageRef)) pageRef[paragraphId] = {}
        } catch (e) {
            debugger
        }
        return pageRef[paragraphId]
    }

    //this and the delete section only other location where words are updated
    ensureWordRecordedForPage = (
        word: string,
        pageId: string,
        paragraphId: string,
        index: number = -1
    ) => {
        this.ensureParagraph(pageId, paragraphId)
        const cleanedWord = GraphBuilderLite.cleanWord(word)

        //Update here
        this.pages[pageId][paragraphId][cleanedWord] = index
        //Update firebase
        this.backendWriter.setPageWord(cleanedWord, pageId, paragraphId, index)
    }

    //Reprocess this page in GraphBuilderLite.
    // Which means: delete and add back all of its paragraphs to this.words and to this.pages
    reprocessPagePerParagraph = (page: PlexusPage) => {
        //Janky way to make sure paragraph IDs are different
        let paragraphIdsAlready = {}
        //Get all plexusparagraphs in this page
        const paragraphs = page.slateValue.map((slateChild, i) =>
            //Convert slate child to plexus paragraph
            paragraphFromSlateNote(slateChild, page.id, i, paragraphIdsAlready)
        )
        if (!paragraphs) return

        //Reprocess page by reprocessing each paragraph
        paragraphs.forEach(paragraph => {
            //Convert to plexusparagraph
            this.reprocessParagraph(paragraph)
        })
    }

    reprocessPage = (page: PlexusPage) => {
        this.removePage(page)
        this.addAndFactorPage(page)
    }

    //Reprocesses a paragraph for a given slate note
    reprocessSlateNote = (
        slateNote: SlateNoteElement,
        pageId: string,
        noteIndex: number,
        paragraphIdsAlready = {}
    ) => {
        const paragraph = paragraphFromSlateNote(
            slateNote,
            pageId,
            noteIndex,
            paragraphIdsAlready
        )
        this.reprocessParagraph(paragraph)
    }

    reprocessParagraph = (paragraph: PlexusParagraph) => {
        //Really need to remove the old version of the paragraph.
        //NEED TO WRITE THIS FUNCTION
        this.removeOldParagraph(paragraph.paragraphId, paragraph.pageId)
        this.addAndFactorParagraph(paragraph)
    }

    addAndFactorPage = (page: PlexusPage) => {
        const paragraphIdsHere = {}
        page.slateValue.map((note: SlateNoteElement, i) => {
            //Convert slate note to paragraph
            const thisParagraph = paragraphFromSlateNote(note, page.id, i, paragraphIdsHere)

            //log words in this paragraph for future reference-lookup
            this.addAndFactorParagraph(thisParagraph)
        })
    }

    addAndFactorParagraph = (paragraph: PlexusParagraph) => {
        //Process each word, wiping out previous data
        const strippedWords = paragraph.cleanWords
            ? paragraph.cleanWords
            : GraphBuilderLite.cleanPhrase(paragraph.text)
        strippedWords.forEach((strippedWord: string, wordIndex: number) => {
            //add this word to the page
            this.ensureWordRecordedForPage(
                strippedWord,
                paragraph.pageId,
                paragraph.paragraphId,
                wordIndex
            )

            //Make word if doesn't exist
            const x = this.words.get(strippedWord)
            const pastPageMap: PageMap = x ? x : {}

            //Make page if doesn't exist
            const pastPageRecord: ParagraphMap = pastPageMap[paragraph.pageId]
                ? pastPageMap[paragraph.pageId]
                : {}

            const paragraphAtThisWord: PlexusParagraph = {
                ...paragraph,
                wordIndex,
                cleanWords: strippedWords,
            }

            //Remake para regardless of whether exists
            const newPageRecord: ParagraphMap = {
                ...pastPageRecord,
                [paragraph.paragraphId]: paragraphAtThisWord,
            }
            const newPageMap: PageMap = {...pastPageMap, [paragraph.pageId]: newPageRecord}
            this.setWord(strippedWord, newPageMap)
        })
    }

    deleteWord(word: string) {
        this.setWord(word, null)
    }

    /**
     * The one dictionary-updating function.
     * So that firebase logic to update dictionary doesn't need to be written more than once
     * @param word
     * @param pageMap null if this is meant to be a delete update (undefined will cause error)
     */
    setWord(word: string, pageMap: PageMap | null) {
        const cleanedWord = GraphBuilderLite.cleanWord(word)

        //update locally
        if (pageMap) {
            this.words.put(cleanedWord, pageMap)
        } else {
            this.words.delete(cleanedWord)
        }

        //update graph builder, if firebase writer is initialized
        try {
            if (this.backendWriter) this.backendWriter.setWord(cleanedWord, pageMap)
        } catch (e) {
            console.error(e)
        }
    }
    setPageWords(
        pageId: string,
        pageWords: {[paragraphId: string]: {[word: string]: number}}
    ) {}

    includes(word: string) {
        return this.words.hasWord(word)
    }

    findPerfectTitles(phrase: string): PlexusParagraph[] {
        return this.filterForPerfect(phrase, this.findTitlesWhereOccurs(phrase))
    }

    //Possibly will use this one
    filterForPerfect(phrase: string, occurrences: PlexusParagraph[]): PlexusParagraph[] {
        const cleanedWords = GraphBuilderLite.cleanPhrase(phrase)
        const perfectTitles = occurrences.filter(titleParagraph => {
            const cleanedOtherWords = titleParagraph.cleanWords
                ? titleParagraph.cleanWords
                : GraphBuilderLite.cleanPhrase(titleParagraph.text)
            return cleanedOtherWords.length == cleanedWords.length
        })
        return perfectTitles
    }

    findTitlesWhereOccurs(phrase: string): PlexusParagraph[] {
        const whereOccurs = this.findWhereOccurs(phrase)
        const pagesWhereOccurs = whereOccurs.filter(para => para.paragraphIndex == 0)
        return pagesWhereOccurs
    }
    findWhereOccurs(phrase: string, prefix: boolean = false): PlexusParagraph[] {
        const cleanPhraseWords = timeFunction(
            () => GraphBuilderLite.cleanPhrase(phrase),
            'clean query phrase'
        )
        const result = timeFunction(
            () => this.findMinWordParagraphsDetailed(phrase),
            'find min paragraph'
        )
        const paragraphs: ParagraphMap = result ? result.paragraphs : {}
        const onesWithPhrase: PlexusParagraph[] = Object.values(paragraphs)
            .map(para => {
                const cleanParaWords = timeFunction(
                    () =>
                        para.cleanWords
                            ? para.cleanWords
                            : GraphBuilderLite.cleanPhrase(para.text),
                    'clean para phrase'
                )
                //Get the word index at which this phrase occurs within the paragraph
                //possibly uses para.wordIndex. but there's a chance para.wordIndex isn't updated.
                // (if person hasn't pressed save on the page). //try it out if it exists
                //para.wordIndex also isn't necessarily the wordIndex that the phrase begins, but the word index of the min-map-root-word.
                const theIndex = timeFunction(
                    () =>
                        findWordIndex(
                            cleanParaWords,
                            cleanPhraseWords,
                            para.wordIndex - result.minMapWordIndex
                        ),
                    'findWordIndex'
                )
                if (theIndex != -1) {
                    return {...para, wordIndex: theIndex}
                } else return undefined
            })
            .filter(para => para)

        return onesWithPhrase
    }
    findMinWordParagraphsDetailed(
        phrase: string
    ): {paragraphs: ParagraphMap; minMapWordIndex: number} {
        const words = GraphBuilderLite.cleanPhrase(phrase)

        if (!words || words.length == 0) return
        const whereTheyOccur = words.map(word => this.words.get(word))

        //if doesn't exist
        if (whereTheyOccur.findIndex(e => !e || e == null || Object.keys(e).length == 0) != -1)
            return

        //Otherwise find the one with the smallest map
        let minMapWordIndex = -1
        const minMap: {min: PageMap; index: number} = whereTheyOccur.reduce(
            (
                tempMinMap: {min: PageMap; index: number},
                nextMap,
                index
            ): {
                min: PageMap
                index: number
            } => {
                let resultingMinMap = tempMinMap
                const nextWordQuery = {min: nextMap, index}
                //if doesn't exist
                if (!tempMinMap) {
                    resultingMinMap = nextWordQuery
                    minMapWordIndex = index
                }
                //if already exists
                else {
                    const prevMinSize = Object.keys(tempMinMap).length
                    const nextSize = nextMap ? Object.keys(nextMap).length : 0
                    if (prevMinSize > nextSize) {
                        resultingMinMap = nextWordQuery
                        minMapWordIndex = index
                    }
                }

                return resultingMinMap
            },
            undefined
        )

        const paragraphs: ParagraphMap = Object.values(minMap.min).reduce(
            (paragraphs: ParagraphMap, nextParaMap: ParagraphMap) => {
                return {...paragraphs, ...nextParaMap}
            },
            {}
        )
        return {paragraphs, minMapWordIndex}
    }

    findWhereOccursByPage(phrase: string, prefix: boolean = false): locsByPage {
        const paragraphs = this.findWhereOccurs(phrase, prefix)
        if (!paragraphs) return {}

        const pageLocs: locsByPage = paragraphs.reduce((locs: locsByPage, nextParagraph) => {
            //add page entry if doesn't already exist in locs
            if (!locs[nextParagraph.pageId]) locs[nextParagraph.pageId] = []

            //add this paragraph to the page entry
            locs[nextParagraph.pageId].push(nextParagraph)

            return locs
        }, {})
        return pageLocs
    }

    // occursElseWhere(phrase: string, noteIdToExclude: string, pageIdToExclude: string) {
    //     const map = this.findWhereOccurs(phrase)
    //     return Object.keys(map).filter(e => e !== noteIdToExclude && map[e].pageId).length > 0
    // }
    splitText(phrase: string): string[] {
        return phrase.split(' ').map(word => strip(word).strippedText)
    }

    static cleanWord(word: string): string {
        const strippedWord = strip(word).strippedText
        // const stem = PlexusEntityRecognizer.winkStem(strippedWord)
        const possibleLemma = PlexusEntityRecognizer.winkWordTag(strippedWord).lemma
        const lemma = possibleLemma ? possibleLemma : strippedWord
        return lemma
    }

    static cleanPhrase(phrase: string): string[] {
        if (!phrase) return []
        return phrase
            .split(' ')
            .filter(e => anyCharIsAlphanumeric(e))
            .map(word => GraphBuilderLite.cleanWord(word))
    }

    /**
     * deletes a page's words from the dictionary, given its id
     * more precisely: for each word that the page contains, delete its entry from the dictionary
     * @param pageId id of a page to be deleted
     */
    removePage(page: PlexusPage) {
        //Check whether new method has been applied yet
        //how to tell? //first paragraph
        //Only use the new method if there is data about which to use the new method
        try {
            if (!isNaN(Object.values(Object.values(this.pages[page.id])[0])[0])) {
                const oldParas = this.pages[page.id]
                //For each paragraph
                if (oldParas)
                    Object.keys(oldParas).forEach(paragraphId => {
                        //New function, see if this works, command z if not
                        this.removeOldParagraph(paragraphId, page.id)
                    })
            }
        } catch (e) {
            console.warn(e)
        }
        // //slowly phasing out this method. cuz it's a lot slower and less reliable. uses words on current page for reference (which might have change since last time page was processed for dictionary)
        // else {
        //     //Get each of its words
        //     const paragraphs: PlexusParagraph[] = getParagraphsFromPage(page)
        //     paragraphs.forEach(paragraph => {
        //         this.removeParagraphForRemovePage(paragraph)
        //     })
        // }
    }

    //New function
    removeOldParagraph(paragraphId: string, pageId: string) {
        //retrieve the old paragraphs words
        try {
            const oldParagraphWords: ParagraphWords = this.pages[pageId][paragraphId]
            if (!oldParagraphWords) return
            const oldWordArr = Object.keys(oldParagraphWords)
            if (!oldWordArr) return

            //For each old word instance, delete it from the word entry, and delete it from pages
            oldWordArr.forEach(word => {
                //delete from word entry
                this.removeWordInstanceFromWordEntry(word, paragraphId, pageId)

                //delete from pages
                this.removeWordInstanceFromPagesRecord(word, paragraphId, pageId)
            })
        } catch (e) {
            //console.log('no previous record')
        }
    }

    //Old function
    removeParagraph(paragraph: PlexusParagraph) {
        //get each of words (don't need to be cleaned yet)
        //rawWords might include blanks ("")
        const rawWords: string[] = getRawWordsFromParagraph(paragraph)
        rawWords.forEach((word, rawWordIndex) => {
            this.removeWordParagraph({...paragraph, rawWordIndex, word})
        })
    }

    //Old function
    removeParagraphForRemovePage(paragraph: PlexusParagraph, givenPageId?: string) {
        const pageId = givenPageId ? givenPageId : paragraph.pageId
        const rawWords: string[] = getRawWordsFromParagraph(paragraph)
        rawWords.forEach((word, rawWordIndex) => {
            this.removePageFromWordEntry(word, pageId)
        })
    }

    /**
     * Clears a page's data from a given word.
     * Intended for use in resetting the dictionary for a given page before processing that page.
     * @param word
     * @param pageId
     */
    private removePageFromWordEntry = (word: string, pageId: string) => {
        const cleanedWord = GraphBuilderLite.cleanWord(word)

        //remove here and firebase
        this.removePageFromCleanedWordEntry(cleanedWord, pageId)
    }

    //Removes paragraph-word-instance from this.words entry, here and in firebase
    private removeWordInstanceFromWordEntry = (
        word: string,
        paragraphId: string,
        pageId: string
    ) => {
        const cleanedWord = GraphBuilderLite.cleanWord(word)
        this.removeParagraphFromCleanedWordEntry(cleanedWord, paragraphId, pageId)
    }

    //Removes paragraph-word-instance from this.pages
    private removeWordInstanceFromPagesRecord = (
        word: string,
        paragraphId: string,
        pageId: string
    ) => {
        const cleanedWord = GraphBuilderLite.cleanWord(word)
        this.removeCleanedWordInstanceFromPagesRecord(cleanedWord, paragraphId, pageId)
    }
    private removeCleanedWordInstanceFromPagesRecord = (
        cleanedWord: string,
        paragraphId: string,
        pageId: string
    ) => {
        const paragraphRecord = this.pages[pageId][paragraphId]
        if (!paragraphRecord) return
        if (paragraphRecord && cleanedWord in paragraphRecord) {
            //delete locally and in firebase
            delete paragraphRecord[cleanedWord]
            this.backendWriter.deletePageWord(cleanedWord, pageId, paragraphId)

            //if paragraph has no more words, delete it entirely? Nah, fine to keep it
        }
    }

    //Removes from Firebase as well
    private removeParagraphFromCleanedWordEntry = (
        cleanedWord: string,
        paragraphId: string,
        pageId: string
    ) => {
        const wordEntry: PageMap = this.words.get(cleanedWord)
        if (wordEntry && pageId in wordEntry) {
            if (paragraphId in wordEntry[pageId]) {
                delete wordEntry[pageId][paragraphId]

                //For firebase too, reset this part. See if works better now.
                this.backendWriter.setPageWord(cleanedWord, pageId, paragraphId, null)

                //Then, if empty, delete the entry page from the word entry
                this.removePageFromWordEntryIfEmpty(pageId, cleanedWord)
            }
        }
    }

    //Deletes a page from a word's entry if that page has no locations where the word occurs
    removePageFromWordEntryIfEmpty = (pageId: string, cleanedWord: string) => {
        const wordEntry: PageMap = this.words.get(cleanedWord)
        if (wordEntry && pageId in wordEntry) {
            if (Object.keys(wordEntry[pageId]).length == 0) {
                this.removePageFromWordEntry(cleanedWord, pageId)
            }
            //possibly remove word entry
            this.removeWordEntryIfEmpty(cleanedWord)
        }
    }

    //Deletes a word's entire entry if it's empty
    private removeWordEntryIfEmpty = (cleanedWord: string) => {
        const wordEntry: PageMap = this.words.get(cleanedWord)
        if (Object.keys(wordEntry).length == 0) {
            //delete word
            this.deleteWord(cleanedWord)
        }
    }

    //But also need to delete from firebase
    removePageFromCleanedWordEntry = (cleanedWord: string, pageId: string) => {
        const wordEntry: PageMap = this.words.get(cleanedWord)
        if (wordEntry && pageId in wordEntry) {
            delete wordEntry[pageId]
            //remove in firebase
            this.backendWriter.setWord(cleanedWord, wordEntry)
        }
    }

    /**
     * Just mutates the PlexusPage object in place
     * @param word
     */
    removeWordParagraph(word: PlexusWordInParagraph, pageMap?: PageMap) {
        const wordString =
            typeof word.word === 'string'
                ? word.word
                : getRawWordsFromParagraph(word)[word.rawWordIndex]
        // if no word string, just return, and move on
        if ([' ', ''].includes(wordString)) return

        //For this word, remove this word-paragraph reference from the dictionary if exists
        const cleanedWord = GraphBuilderLite.cleanWord(wordString)
        const wordPageMap = pageMap ? pageMap : this.words.get(cleanedWord)

        //if there's an entry for this page in this word's page map
        if (wordPageMap && word.pageId in wordPageMap) {
            const paragraphMap = wordPageMap[word.pageId]
            //if there's an entry for this paragraph in the pageMap of this word, delete that entry
            if (word.paragraphId in paragraphMap) {
                //depending on the paragraph count eventually, could just decrement. But for now, always delete it
                delete paragraphMap[word.paragraphId]
            }
            //possibly delete the page, if its paragraph map is now empty
            this.emptyWordPageCheck(wordString, word.pageId)
        }
        //possibly delete the whole word entry, if it has not more pages
        this.emptyWordCheck(wordString)
    }

    //delete word's entry if empty
    emptyWordCheck(decoratedWord: string) {
        const cleanedWord = GraphBuilderLite.cleanWord(decoratedWord)
        const wordPageMap = this.words.get(cleanedWord)

        //If the entry exists (non null) but is empty, delete it. (If already null, it's already not there.)
        if (wordPageMap && Object.keys(wordPageMap).length <= 0) {
            //delete word
            this.setWord(cleanedWord, null)
        }
    }

    //delete word-page entry if empty
    emptyWordPageCheck(decoratedWord: string, pageId: string) {
        const cleanedWord = GraphBuilderLite.cleanWord(decoratedWord)
        const wordPageMap = this.words.get(cleanedWord)
        //possibly delete the page from this word's entry, when: if the word indeed has an entry, if the page exists within it, but if if its paragraph map is now empty
        if (
            wordPageMap &&
            wordPageMap[pageId] &&
            Object.keys(wordPageMap[pageId]).length <= 0
        ) {
            delete wordPageMap[pageId]
        }
    }
}

/**
 *
 * @param page
 * @returns
 */
const getParagraphsFromPage = (page: PlexusPage): PlexusParagraph[] => {
    const slateValue = page.slateValue
    if (!slateValue) return []
    const paraStrings: string[] = slateValue.map(child => {
        let str
        try {
            str = Node.string(child)
        } catch (e) {
            //undefined issue for some reason
            console.log(e)
        }
        return str
    })
    const paragraphIdsHere = {}
    const paragraphs: PlexusParagraph[] = paraStrings.map((str, index) => {
        //need to come back later and give this an actual id
        const noteId =
            slateValue[index].id && !(slateValue[index].id in paragraphIdsHere)
                ? slateValue[index].id
                : page.id + '-' + uniqid()
        paragraphIdsHere[noteId] = true //make sure no duplicate paragraph ids
        return {
            pageId: page.id,
            paragraphId: noteId,
            text: str,
            cleanWords: GraphBuilderLite.cleanPhrase(str),
            paragraphIndex: index,
        }
    })
    return paragraphs
}
const getRawWordsFromParagraph = (paragraph: PlexusParagraph): string[] => {
    const rawWords: string[] = paragraph.text ? getRawWords(paragraph.text) : []
    return rawWords
}

export default GraphBuilderLite

interface locsByPage {
    [pageId: string]: PlexusParagraph[]
}

export const findWordIndex = (
    body: string[],
    query: string[],
    startWordIndex: number
): number => {
    let index = -1
    const stratOne = false

    //Strategy one: use startWordIndex if provided
    if (stratOne) {
        const i = startWordIndex
        const bodySlice = body.slice(i, i + query.length)
        if (arraysEqual(bodySlice, query)) index = i
    } else {
        for (let i = 0; i < body.length; i++) {
            if (arraysEqual(body.slice(i, i + query.length), query)) {
                index = i
            }
        }
    }
    return index
}
