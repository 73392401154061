import {createSlice} from '@reduxjs/toolkit'

import {SummarizationFilterOptions} from '../Interfaces/SummarizationFilterOptions'

//Will be overwritten by builder
/**
 * @param howManyDays the number of days from which to show notes. Not used for the time being
 *      This isn't how many days back from the present to show notes on the universe page,
 *  but rather how many days that have notes on them from the present. For example
 *      If took notes not today, but yesterday, two days ago, four days ago, and six days ago, but not three days ago nor five days ago
 *          then howManyDaysBack = 4 would show four days of notes, in addition to today,
 *              meaning today's notes, yetserday's notes, two day's ago's notes, four days ago's notes, and six day's ago notess
 */

const initialState = {
    groupsMerged: true,
    suggestionsOn: true,
    includeReferences: true,
    includeParentNotes: true,
    onlyShowConfirmedConcepts: false,
    metaPressed: false,
    howManyDays: 999999999, //The number of days from which to show notes.
    moreDaysOfActionsThanShown: true,
    summarizationFilterOptions: {
        mustBeReferenced: false,
        mustHaveChildren: false,
        mustBeParentless: true,
        allowMadeInline: true,
    },
    topLevelBullets: true,
    stepIndex: 0,
    isFirstLogin: false,
    implicitMerge: false,
    starredOnUniverse: true,
    timestampsOnUniverse: true,
    seeSettings: false,
    dontSuggestConcepts: false,
    // showDates:
    // sidePanelThought: null,
}
export const DisplaySlice = createSlice({
    name: 'display',
    initialState,
    reducers: {
        setDisplaySettings: (state, action) => {
            state = {...action.payload}
        },
        setGroupsMerged: (state, action) => {
            state.groupsMerged = action.payload
        },
        setSuggestionsOn: (state, action) => {
            state.suggestionsOn = action.payload
        },
        setIncludeReferences: (state, action) => {
            state.includeReferences = action.payload
        },
        setIncludeParentNotes: (state, action) => {
            state.includeParentNotes = action.payload
        },
        setOnlyShowConfirmedConcepts: (state, action) => {
            state.onlyShowConfirmedConcepts = action.payload
        },
        setMetaPressed: (state, action) => {
            state.metaPressed = action.payload
        },
        setHowManyDays: (state, action) => {
            state.howManyDays = action.payload
        },
        incrementHowManyDays: state => {
            state.howManyDays = state.howManyDays + 1
        },
        setSummarizationFilterOptions: (state, action) => {
            state.summarizationFilterOptions = action.payload
        },
        setMoreDaysOfActionsThanShown: (state, action) => {
            state.moreDaysOfActionsThanShown = action.payload
        },
        setTopLevelBullets: (state, action) => {
            state.topLevelBullets = action.payload
        },
        setStepIndex: (state, action) => {
            state.stepIndex = action.payload
        },
        setIsFirstLogin: (state, action) => {
            state.isFirstLogin = action.payload
        },
        setImplicitMerge: (state, action) => {
            state.implicitMerge = action.payload
        },
        setStarredOnUniverse(state, action) {
            state.starredOnUniverse = action.payload
        },
        setTimestampsOnUniverse(state, action) {
            state.timestampsOnUniverse = action.payload
        },

        setSeeSettings(state, action) {
            state.seeSettings = action.payload
        },
        setDontSuggestConcepts(state, action: {payload: boolean}) {
            state.dontSuggestConcepts = action.payload
        },
    },
})

export const {
    setDisplaySettings,
    setGroupsMerged,
    setSuggestionsOn,
    setIncludeReferences,
    setIncludeParentNotes,
    setOnlyShowConfirmedConcepts,
    setMetaPressed,
    setHowManyDays,
    incrementHowManyDays,
    setSummarizationFilterOptions,
    setMoreDaysOfActionsThanShown,
    setTopLevelBullets,
    setStepIndex,
    setIsFirstLogin,
    setImplicitMerge,
    setStarredOnUniverse,
    setTimestampsOnUniverse,
    setSeeSettings,
    setDontSuggestConcepts,
    // setSidePanelThought
} = DisplaySlice.actions

export const selectDisplaySettings = state => state.display
export const selectGroupsMerged = state => state.display.groupsMerged
export const selectSuggestionsOn = state => state.display.suggestionsOn
export const selectIncludeReferences = state => state.display.includeReferences
export const selectIncludeParentNotes = state => state.display.includeParentNotes
export const selectOnlyShowConfirmedConcepts = state => state.display.onlyShowConfirmedConcepts
export const selectMetaPressed = (state): boolean => state.display.metaPressed
export const selectHowManyDays = (state): number => state.display.howManyDays
export const selectSummarizationFilterOptions = (state): SummarizationFilterOptions =>
    state.display.summarizationFilterOptions
export const selectMoreDaysOfActionsThanShown = state =>
    state.display.moreDaysOfActionsThanShown
export const selectTopLevelBullets = state => state.display.topLevelBullets
export const selectStepIndex = state => state.display.stepIndex
export const selectIsFirstLogin = state => state.display.isFirstLogin

export const selectImplicitMerge = state => state.display.implicitMerge
export const selectStarredOnUniverse = state => state.display.starredOnUniverse
export const selectTimestampsOnUniverse = state => state.display.timestampsOnUniverse
export const selectSeeSettings = state => state.display.seeSettings
export const selectGraphDisplay = state => state.display.graphDisplay
export const selectDontSuggestConcepts = state => state.display.dontSuggestConcepts

export default DisplaySlice.reducer
