import React, {createContext, Dispatch, SetStateAction} from 'react'
import {PlexusPage} from '../Algorithms/EntityRecognizer/PageInterfaces'

/**
 * Some "global" variables within the Plexus React components
 */
let TopLevelContext = React.createContext<
    | {
          switchPage: (titleId: string) => PlexusPage //ok to keep
          logout: (event: any) => void //ok to keep
      }
    | undefined
>(undefined)
export default TopLevelContext
