import React from 'react'
import InteractiveReferencesInput from './InteractiveReferencesInput'

const PageSectionTitle = ({
    references,
    setOpen,
    open,
    sectionTitle,
    referencesQuery,
    setReferencesQuery,
}) => {
    return (
        <div
            className={'references-title' + (references.length > 0 ? '' : ' empty')}
            onClick={() => setOpen(prev => !prev)}
        >
            {references.length > 0 ? (
                <div
                    id="concept-caret"
                    style={{
                        visibility: references && references.length > 0 ? 'visible' : 'hidden',
                        fontStyle: 'normal',
                    }}
                    className={open ? ' ninety-degrees' : ''}
                >
                    ▸
                </div>
            ) : (
                <></>
            )}
            {sectionTitle}
            {/* very janky for now: */}
            {sectionTitle.toLocaleLowerCase() === 'references' ? (
                <InteractiveReferencesInput {...{referencesQuery, setReferencesQuery}} />
            ) : (
                <></>
            )}
        </div>
    )
}

export default PageSectionTitle
