import React from 'react'
import './PasteButton.css'

const PasteButton = ({paraArr, handlePaste, visibility}) => {
    return (
        <div
            style={{visibility}}
            className="paste-button"
            title="Paste these references into the page above"
            onClick={e => {
                e.stopPropagation()
                handlePaste()
            }}
        >
            ^
        </div>
    )
    // &#8593;
}

export default PasteButton
