import React, {useContext, useEffect, useMemo} from 'react'
import {useDispatch, useSelector} from 'react-redux'
import './Page.css'
import './Titles/HeaderButtons.css'

import {selectShowStore} from '../../Slices/ConceptStoreSlice'
import EditorContainer from './SimplerSlateEditor/EditorContainer/EditorContainer'
import TopLevelContext from '../../Contexts/TopLevelContext'
import {selectSecondaryTitleIds, setTitleIds} from '../../Slices/AppSlice'
import {selectPage, selectPages} from '../../Slices/PagesSlice'

const Page = ({emptyArray, listKey, pageId, secondary}) => {
    const conceptStoreOpen = useSelector(selectShowStore)

    return (
        <div
            key={listKey + '-container'}
            className={
                'panel' +
                (secondary ? ' secondary' : '') +
                (conceptStoreOpen ? ' conceptStoreOpen' : '')
            }
        >
            <EditorContainer {...{pageId, secondary}} />
        </div>
    )
}

export default Page
