import {createSlice} from '@reduxjs/toolkit'
import {Selection} from 'slate'
import {NodePath} from '../Algorithms/NodePath'
export type SelectionPayload = {
    focusAtStart?: boolean
    focusAtEnd?: boolean
    selection?: Selection
}
export enum ExpansionLevel {
    FULL = 'FULL',
    PARTIAL_FIXED = 'PARTIAL_FIXED', //when we exactly want to show a fixed set of children nodes
    PARTIAL_SPATIAL = 'PARTIAL_SPATIAL', //only indentation nation
    PARTIAL_FILTERED = 'PARTIAL_FILTERED', // have a list of node ids to use as filters for what to show
}
type ExpansionPayload = {
    expansionLevel: ExpansionLevel
    titleNodeIDs: string[]
    filterNodeIDs?: string[]
}
type NeedToPayload = SelectionPayload | ExpansionPayload

export type DeclarativeActionObject = {needTo: boolean; path: NodePath; payload: NeedToPayload}
export interface SelectionAction extends DeclarativeActionObject {
    payload: SelectionPayload
}

//presumably every expasion status here is expanded in some capacity, e.g.,
//has "need-to-expand" implicitly as true, which is why needTo is undefined
export interface ExpansionStatus extends DeclarativeActionObject {
    payload: ExpansionPayload
}

//presumably every expasion status here is expanded in some capacity, e.g., has needTo as true
//otherwise, will unexpand by default
//the type of object that keeps track of whether notes are expanded or not
export type ExpansionRecord = ExpansionStatus[]

const initialState: {
    titleIds: string[] //includes both primary
    secondaryTitleIds: string[]
} = {
    titleIds: [],
    secondaryTitleIds: [],
}
export const AppSlice = createSlice({
    name: 'app',
    initialState,
    reducers: {
        setTitleIds: (state, action: {payload: string[]}) => {
            state.titleIds = action.payload
        },
        setSecondaryTitleIds: (state, action: {payload: string[]}) => {
            state.titleIds = action.payload
        },
    },
})

export const {setTitleIds, setSecondaryTitleIds} = AppSlice.actions

export const selectTitleIds = (state): string[] => state.app.titleIds
export const selectSecondaryTitleIds = (state): string[] => state.app.secondaryTitleIds

export default AppSlice.reducer
