import React, {useContext, useEffect, useMemo, useState} from 'react'
import './References.css'
import GraphBuilderLite from '../../../Algorithms/GraphBuilding/GraphBuilderLite/GraphBuilderLite'
import {PlexusPage, PlexusParagraph} from '../../../Algorithms/EntityRecognizer/PageInterfaces'
import {useSelector} from 'react-redux'
import {selectPages} from '../../../Slices/PagesSlice'
import ReferenceItem from './ReferenceItem'
import TopLevelContext from '../../../Contexts/TopLevelContext'
import ReferencesFromAPage from './ReferencesFromAPage'
import {Editor} from 'slate'
import PageSectionTitle from './PageSectionTitle/PageSectionTitle'

const References = ({
    page,
    gbl,
    editor,
}: {
    editor: Editor
    page: PlexusPage
    gbl: GraphBuilderLite
}) => {
    const [open, setOpen] = useState(true)
    const {switchPage} = useContext(TopLevelContext)
    const pages = useSelector(selectPages)
    const [referencesQuery, setReferencesQuery] = useState('')
    useEffect(() => {
        setReferencesQuery(page.title)
    }, [page.title])
    const references = useMemo(() => {
        const x = gbl.findWhereOccursByPage(referencesQuery)
        return x
            ? Object.entries(x)
                  .filter(([thisPageId, thisParaArr]) => thisPageId !== page.id)
                  .sort((pageMapA, pageMapB) => {
                      const ids = {a: pageMapA[0], b: pageMapB[0]}
                      if (!(ids.a in pages) || !pages[ids.a].lastUpdated) return 1
                      else if (!(ids.b in pages) || !pages[ids.b].lastUpdated) return -1
                      else {
                          const pageA = pages[ids.a]
                          const pageB = pages[ids.b]
                          return -pageA.lastUpdated + pageB.lastUpdated
                      }
                  })
            : []
    }, [referencesQuery])

    return references.length > 0 ? (
        <div className="references">
            <PageSectionTitle
                {...{
                    references,
                    setOpen,
                    open,
                    sectionTitle: 'References',
                    referencesQuery,
                    setReferencesQuery,
                }}
            />

            {open ? (
                <div className="reference-content">
                    {(references ? references : []).map(([thisPageId, thisParaArr]) => {
                        return pages[thisPageId] ? (
                            <ReferencesFromAPage
                                key={thisPageId}
                                page={pages[thisPageId]}
                                paraArr={[...thisParaArr].sort(
                                    (a, b) => a.paragraphIndex - b.paragraphIndex
                                )}
                                editor={editor}
                            />
                        ) : (
                            <div key={thisPageId} />
                        )
                    })}
                </div>
            ) : (
                <></>
            )}
        </div>
    ) : (
        <div></div>
    )
}

export default References
