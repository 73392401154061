import {useEffect, useRef} from 'react'

/**
 * function to keep track of variables in a react state despite rerenders
 * @param value
 */
export function usePrevious(value) {
    const ref = useRef()
    useEffect(() => {
        ref.current = value
    })
    return ref.current
}
