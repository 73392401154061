import {remove, uniq, uniqueId} from 'lodash'
import {Descendant, Editor, Node} from 'slate'
import {findEntitiesInParagraph} from '../Algorithms/EntityRecognizer/EnglishEntitiesHelpers/EnglishEntities'
import {
    configureGraphForRepeatingEntities,
    getSlateNoteFromWordsAndEntities,
} from '../Algorithms/EntityRecognizer/GenerateSlateValue'
import {
    PageOriginStory,
    PlexusPage,
    PlexusParagraph,
} from '../Algorithms/EntityRecognizer/PageInterfaces'
import GraphBuilderLite, {
    PlexusPagesMap,
} from '../Algorithms/GraphBuilding/GraphBuilderLite/GraphBuilderLite'
import FirebaseWriter22 from '../BackendDataManagement/Jan2022/FirebaseWriter22'
import Page from '../Components/Page/Page'
import {
    PinnedNoteElement,
    SlateNoteElement,
} from '../Components/Page/SimplerSlateEditor/EditorContainer/slateConfig'
import uniqid from 'uniqid'
import {Dispatch, SetStateAction} from 'react'
import {addFolderRelation, removeOldFolderRelation} from './DragAndDropHelpers'
import {analytics} from '../Components/App'

//This file houses each semantically meaningful mutation in the Plexus web app that affects...
//  ...both (1) the person's dictionary and (2) other parts of firebase
//  For now, each unified function should stand alone. Have no global dependencies.

/**
 * Adds a new Plexus page.
 * Two steps: adds the page to the plexus pages in Firebase and! adds the page to the person's dictionary.
 *
 * Used when person highlights a phrase and clicks the dot,
 *    when they click "new page," and in every other instance where a new page is created.
 * @param title title text for the new page
 * @param gbl deals with repetition word-indexing
 * @param simpleFirebaseWriter deals with backend storage
 * @param secondLine
 * @param pageOrigin
 * @returns
 */
export const addPage = (
    title: string,
    gbl: GraphBuilderLite,
    simpleFirebaseWriter: FirebaseWriter22,
    secondLine: boolean = false,
    pageOrigin: PageOriginStory = PageOriginStory.MANUAL,
    providedSlateValue?: SlateNoteElement[]
): {promise: Promise<any>; page: PlexusPage} => {
    const {page, promise} = simpleFirebaseWriter.addPage(title, pageOrigin, providedSlateValue)
    return {promise: promise.then(e => gbl.addAndFactorPage(page)), page}
}

/**
 * Add a new page and open it.
 * @param title the title text of the new page
 * @param gbl the person's dictionary object, so the new page can be processed for repetition
 * @param simpleFirebaseWriter the person's firebase-writer instance, so changes can be saved to firebase
 * @param openPage a function to open a given page
 * @param origin a string indicating how/why this page was created.
 */
export const addPageAndFocus = (
    title: string,
    gbl: GraphBuilderLite,
    simpleFirebaseWriter: FirebaseWriter22,
    openPage: (id: string) => void,
    origin: PageOriginStory
) => {
    const page = simpleFirebaseWriter.addPageAndSelect(title, openPage, origin).page
    gbl.addAndFactorPage(page)
}

/**
 * All the functions that fire when you drag one page into another
 * @param childPage the page the person dragged and dropped
 * @param gbl deals with mutations to the person's dictionary
 * @param simpleFirebaseWriter deals with other mutations to the person's account records/notes in firebase
 * @param switchPage opens a page, given a title id
 * @param setParentValue sets the Slate Editor value of an opened page.
 *  Use of this function, as opposed to just updated firebase, is necessary because, otherwise, setting firebase alone won't cause an update to the currently open page.
 * @param parentPage the page that the child page was dropped into
 * @param pageMap all of the pages in this person's account. Used for word-indexing in `gbl`
 * @param oldParent the previous parent of `childPage`.
 * @returns
 */
export const dragAndDrop = (
    childPage: PlexusPage,
    gbl: GraphBuilderLite,
    simpleFirebaseWriter: FirebaseWriter22,
    switchPage: (id: string) => void,
    setParentValue?: Dispatch<SetStateAction<Descendant[]>>,
    parentPage?: PlexusPage,
    pageMap?: PlexusPagesMap,
    oldParent?: PlexusPage
) => {
    //If the new parent is the same as the child page, don't do anything.
    //  Don't want recursive self-folder loops.
    if (parentPage.id === childPage.id) return

    //process child page
    processPage(childPage, gbl, simpleFirebaseWriter, undefined, pageMap).then(() => {
        //remove the old folder relation if the old parent is provided
        const removeOldRelationPromise = oldParent
            ? removeOldFolderRelation(childPage, oldParent, simpleFirebaseWriter, gbl)
            : new Promise(resolve => setTimeout(() => resolve('fake resolution'), 1)) //dummy promise that takes one ms.

        //Afterward, add the new folder relation
        removeOldRelationPromise.then(() => {
            if (parentPage)
                addFolderRelation(
                    simpleFirebaseWriter,
                    parentPage,
                    childPage,
                    gbl,
                    switchPage,
                    setParentValue
                )
        })
    })
}

/**
 * Deletes all pages in your account.
 * Only for test accounts, or for clearing onboarding content. Not for real-use.
 * "Delete all" button calls this (which appears in the side panel menu)
 * @param pages
 * @param gbl
 * @param simpleFirebaseWriter
 */
export const deleteAllAccountData = (
    pages: {[key: string]: PlexusPage},
    gbl: GraphBuilderLite,
    simpleFirebaseWriter: FirebaseWriter22
) => {
    deleteAllPages(pages, gbl, simpleFirebaseWriter)
    //also delete everything under this person's name
    simpleFirebaseWriter.deleteAllPersonData()
    gbl = undefined
}

/**
 * Deletes all of a person's pages
 * @param pages
 * @param gbl
 * @param simpleFirebaseWriter
 */
export const deleteAllPages = (
    pages: {[key: string]: PlexusPage},
    gbl: GraphBuilderLite,
    simpleFirebaseWriter: FirebaseWriter22
) => {
    Object.values(pages).forEach(page => deletePage(page, gbl, simpleFirebaseWriter))
}

//A page line threshold and paragraph char limit threshold. Meant to prevent too things:
// super long processing times, where people are waiting and don't know what's going on
// malicious overload of our database.
//@TODO implement a threshold for pages in general, independent of processing.
//Haven't fended against malicious attacks yet, will want to do so before we openly release beta.
export const paragraphCharLimit = 2000
export const pageLineLimit = 500

/**
 * Plexus' special sauce.
 * Finds meaningful entities in a current page, and saves the page with its new context.
 *
 * The words "note" and "paragraph" are used interchangeably throughout @Davey's commenting.
 *      He just means to refer to a line-breaked unit of text in a slate page
 *
 * @param page the page to be processed
 * @param gbl deals with word-indexing, so evaluating repetition is quick
 * @param simpleFirebaseWriter writes to backend, to save new data and delete old
 * @param setCurrentPageSlateValue sets the SlateEditorValue of a currently-open page
 * @param pageMap a map of all the pages in this person's account
 * @param givenSlateValue a non-processed slate value. if provided, will be processed instead of page.slateValue
 */
export const processPage = (
    page: PlexusPage,
    gbl: GraphBuilderLite,
    simpleFirebaseWriter: FirebaseWriter22,
    setCurrentPageSlateValue?: Function,
    pageMap?: PlexusPagesMap,
    givenSlateValue?: Descendant[],
    blurEditor?: Function
) => {
    if (blurEditor) blurEditor()
    analytics.logEvent('processing page', {val: page})

    //1. Deal with person's personal dictionary!
    //   delete page's words from dictionary then add back.
    // eventually just reprocess per paragraph
    gbl.reprocessPagePerParagraph(page)

    // In each paragraph: deal with entities, new pages, connections, slate editor value.
    // 2. Update the page itself in firebase (other than the person's personal dictionary)
    const resultPromise = new Promise((resolve, reject) => {
        //Start by checking whether number of paragraphs is too many to process
        const disable = page.slateValue.length > pageLineLimit
        if (disable) {
            window.alert(
                'Page has too many lines to be processed! You can try splitting the text across multiple pages.'
            )
            reject()
            return
        }

        //Keeps track of whether at least one paragraph is too large to process. For now, still process the others.
        let atLeastOneParagraphDisabled = false

        //Reset the current page's record in firebase, then add it back again.
        const resetPagePromise = simpleFirebaseWriter.resetPage(page.id, false)

        //Then, find entities in this page and construct its new slate value.
        resetPagePromise.then(() => {
            //The whole page's new slate editor value (composed of individual paragraphs' slate editor values)
            const newVal: Descendant[] = []

            //The slate editor value to process for the given page.
            const slateValueToProcess = givenSlateValue ? givenSlateValue : page.slateValue

            //Janky way to keep track of paragraph ids that have been processed already. Don't want duplicate ids
            //   There shouldn't be duplicate ids, but paragraphs ids originally were (stupidly) set to their int indices on the page,
            //   meaning that there was some overlap. Recording each new paragraph id in this obj is meant to prevent
            const paragraphsIdsHere = {}

            //For each paragraph, process it.
            for (let i = 0; i < slateValueToProcess.length; i++) {
                let newNote: SlateNoteElement | PinnedNoteElement
                const note = slateValueToProcess[i] as SlateNoteElement | PinnedNoteElement
                if (note.type === 'pinnedNote') {
                    newNote = note
                } else {
                    const thisParagraph = paragraphFromSlateNote(
                        note,
                        page.id,
                        i,
                        paragraphsIdsHere
                    )
                    //Whether or not this paragraph is disabled
                    const thisParagraphDisabled = thisParagraph.text.length > paragraphCharLimit
                    let entities = []
                    if (thisParagraphDisabled) {
                        atLeastOneParagraphDisabled = true
                    } else {
                        //Find the entities in this paragraph (repeating & meaningful)
                        entities = findEntitiesInParagraph(thisParagraph, gbl, pageMap)
                    }

                    // Configure graph for repeating entities
                    const updatedEntities = configureGraphForRepeatingEntities(
                        entities,
                        simpleFirebaseWriter,
                        gbl,
                        thisParagraph
                    )

                    //Get the new Slate Note Editor State from the paragraph + its entities.
                    newNote = getSlateNoteFromWordsAndEntities(thisParagraph, updatedEntities)
                    if (i == 0) newNote.isTitle = true
                }
                newVal.push(newNote)
            }

            //Update slate value, found above
            //firebase update with new slate value (eventually, just this is ideal)
            simpleFirebaseWriter.setSlateValue(page.id, newVal, resolve, reject)
            //force update the current page's interface
            if (setCurrentPageSlateValue) {
                setCurrentPageSlateValue(newVal)
                resolve(true)
            }
            if (atLeastOneParagraphDisabled)
                window.alert(
                    'At least one paragraph was too large for Plexus to process. \nIf you would like all the text to be parsed for connections, make sure each paragraph has fewer than ' +
                        paragraphCharLimit +
                        ' characters, e.g., by putting sentences on different lines. \nThen try again!'
                )
        })
    })

    return resultPromise
}

//gets a Plexus Paragraph from a slate note
export const paragraphFromSlateNote = (
    note: SlateNoteElement,
    pageId: string,
    paragraphIndex: number,
    paragraphIdsAlready: {[key: string]: boolean}
): PlexusParagraph => {
    const noteId =
        note.id && !(note.id in paragraphIdsAlready) ? note.id : pageId + '-' + uniqid()

    const text: string = Node.string(note)

    const thisParagraph: PlexusParagraph = {
        pageId,
        paragraphId: noteId,
        paragraphIndex,
        text,
        cleanWords: GraphBuilderLite.cleanPhrase(text),
    }
    paragraphIdsAlready[noteId] = true
    return thisParagraph
}
export const resetPage = (
    page: PlexusPage,
    gbl: GraphBuilderLite,
    simpleFirebaseWriter: FirebaseWriter22,
    actuallyDelete: boolean = false
) => {
    //Delete from dictionary
    gbl.removePage(page)
    //Delete from firebase
    simpleFirebaseWriter.resetPage(page.id, actuallyDelete)
}
export const deletePage = (
    page: PlexusPage,
    gbl: GraphBuilderLite,
    simpleFirebaseWriter: FirebaseWriter22
) => resetPage(page, gbl, simpleFirebaseWriter, true)

/**
 * Processes a paragraph fully (firebase, slate, references, everything.)
 */
export const processParagraph = () => {}

//Causes a recursive mess rn: processPage > entity detection > page gets readded
//Add the page, and process it with GBL
export const addAndProcessPage = (
    title: string,
    backendWriter: FirebaseWriter22,
    gbl: GraphBuilderLite
): {promise: Promise<any>; page: PlexusPage} => {
    const {promise, page} = backendWriter.addPage(title)
    promise.then(() => {
        //also process. don't need to set UI forcefully directly?
        processPage(page, gbl, backendWriter)
    })
    //won't necessarily happen after the processing, or even after the page gets loaded
    //might cause problems later, but see if works for now.
    return {promise, page}
}

export const pasteFromReference = (
    newDescendants: Descendant[],
    parentPage: PlexusPage,
    gbl: GraphBuilderLite,
    simpleFirebaseWriter: FirebaseWriter22,
    setParentValue: Function
) => {
    //Insert line at the top, synchronous
    const newSlateValue = [...parentPage.slateValue, ...newDescendants]

    //process page again for new line
    processPage(parentPage, gbl, simpleFirebaseWriter, setParentValue, undefined, newSlateValue)
}

/**
 * Not an important function, just a consecutive series of steps that lets you make a phrase into a child document.
 * A consecutive series of steps:
 *  add a new page for a given phrase,
 *  process another parent page,
 *  then add a folder relation between the parent and the new
 *
 * When is this used? Not implemented (well) currently, but a shortcut Davey wanted for when you're typing in the middle of a parent page.
 *  it would make a new page for a currently selected phrase, or currently focused line of text.
 *
 * @param text
 * @param parentPage
 * @param fbWriter
 * @param gbl
 * @param setParentEditorValue
 * @param switchPage
 */
export const folderShortcut = (
    text: string,
    parentPage: PlexusPage,
    fbWriter: FirebaseWriter22,
    gbl: GraphBuilderLite,
    setParentEditorValue: Function,
    switchPage?: Function
) => {
    const {promise, page} = addPage(text, gbl, fbWriter, true)
    promise.then(() => {
        processPage(parentPage, gbl, fbWriter, setParentEditorValue).then(() => {
            if (switchPage) switchPage(page.id)
        })
    })
}
