import {wordTag} from '../EnglishEntitiesHelpers/PlexusEntityRecognizer'
import {ENGLISH_ENTITY_STATUS} from './EnglishEntities'
import {endsWithApostrS, isAlphaNumeric} from '../../TextNormalization/TextNormalization'
import {
    isBridgeWord,
    isEntityWord,
    recursiveEntityEval as entityRarityCheck,
} from './EnglishEntityQualifications'
import {stringContainsOneOf} from '../../../Miscellaneous/Miscellaneous'

export const checkEntityStatus = (
    word: wordTag,
    subsequentPhrase: wordTag[],
    subsPhraseScore: number,
    note?: wordTag[] //not sure if necessary
): {status: ENGLISH_ENTITY_STATUS; candidateScore: number} => {
    let status: ENGLISH_ENTITY_STATUS
    let candidateScore: number

    //TODO: refind wordPos and lemma if not provided
    //Strip apostrophe s from word
    const wordText = preprocessWordForEntityCheck(word[0] as string)

    //find better location for this logic, maybe one level out.
    //don't look at entities that have words with internal punctuation, because part of speech would not have been properly found
    if (disqualifiesEntity(wordText)) return {status, candidateScore}

    //determine whether subsequent phrase exists, or if this is the first one.
    const isFirstWord = !subsequentPhrase || !(subsequentPhrase.length > 0)
    if (isEntityWord(word, false, isFirstWord)) {
        const {meetsThreshold, score} = entityRarityCheck(word, subsPhraseScore)
        candidateScore = score
        if (meetsThreshold) {
            //check for repetition
            status = ENGLISH_ENTITY_STATUS.YES
        } else {
            //is a candidate
            status = ENGLISH_ENTITY_STATUS.MAYBE
        }
    } else {
        //First check if !first and preposition. If so, give back a maybe.
        if (!isFirstWord && isBridgeWord(word[0] as string)) {
            status = ENGLISH_ENTITY_STATUS.MAYBE
            candidateScore = subsPhraseScore
        } else status = ENGLISH_ENTITY_STATUS.NO
    }

    return {status, candidateScore}
}

//Helpers

function preprocessWordForEntityCheck(word: string): string {
    return endsWithApostrS(word) ? word.slice(0, -2) : word
}

//this needs to be consistent with internal punctuation check
//Currently, just about whether any char isn't alphanumeric (except the first)
const disqualifiesEntity = (word: string): boolean => {
    //Check if it's a stop word (contains an apostrophe or dash). if so, cut off search.
    const sliceToExamine = word.slice(1, word.length - 1)
    return sliceToExamine.length > 0 && !isAlphaNumeric(sliceToExamine, false)
}

const containsStopCharacter = (word: string): boolean => {
    const stopCharacters = ["'", '-']
    return stringContainsOneOf(word, stopCharacters)
}
