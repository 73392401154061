export const bulletDash = ' –  '
const NDASH = '–'
export const TAB = '\t'
export const lineIsBullet = (lineText: string) => {
    charLoop: for (let i = 0; i < lineText.length; i++) {
        const char = lineText.charAt(i)
        if ([TAB].includes(char)) {
            continue charLoop
        } else if (lineText.slice(i, i + bulletDash.length) === bulletDash) {
            return true
        } else return false
    }
}

export const getBulletPrefix = (lineText: string) => {
    let i
    charLoop: for (i = 0; i < lineText.length; i++) {
        const char = lineText.charAt(i)
        if ([TAB].includes(char)) {
            continue charLoop
        } else if (lineText.slice(i, i + bulletDash.length) === bulletDash) {
            break charLoop
        } else return undefined
    }
    return lineText.slice(0, i + bulletDash.length)
}
