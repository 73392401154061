import React, {createContext} from 'react'

const AuthContext = createContext<{
    user: {uid: string; email: string}
    setUser: Function
}>({
    user: null,
    setUser: null,
})

export default AuthContext
