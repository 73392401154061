import React, {useState, useEffect, Fragment, useMemo, useCallback} from 'react'
import RightSidebar from '../Sidebar/RightSidebar/RightSidebar'
import Loading from '../Loaders/Loading'

import {gbl} from '../PlexusDataContainer'
import TopLevelContext from '../../Contexts/TopLevelContext'

import {shallowEqual, useDispatch, useSelector} from 'react-redux'

import {hideModal, selectModal, selectShowExportModal} from '../../Slices/UploadSlice'
import KeyboardEventHandler from 'react-keyboard-event-handler'

import {selectSecondaryTitleIds, selectTitleIds, setTitleIds} from '../../Slices/AppSlice'
import {setMetaPressed} from '../../Slices/DisplaySlice'

import {useParams, useHistory} from 'react-router-dom'
import Page from '../Page/Page'
import './PlexusNotes.css'
import {selectShowStore} from '../../Slices/ConceptStoreSlice'
import PageStore from '../PageStore/PageStore'
import {selectPages} from '../../Slices/PagesSlice'
import {PlexusPage} from '../../Algorithms/EntityRecognizer/PageInterfaces'
import DetectEntitiesButton from '../DetectEntitiesButton/DetectEntitiesButton'
import SeeSettingsButton from '../Page/Titles/SeeSettingsButton/SeeSettingsButton'
import PageStoreTitleButton from '../PageStore/PageStoreTitleButton'
import {usePrevious} from '../Miscellaneous/UsePreviousHook'

/**
 * PlexusNotes is where much of the action occurs.
 * PlexusDataContainer feeds the user's note data and id into here.
 * Then, Plexus Notes deals with much of the logic, returns all the other big components
 * @param firebaseRef a reference to the user's location in the firebase database
 * @param numberOfClicks the user's total number of clicks, ever. Used for emphasis calculations.
 * @param setNumberOfClicks sets the user's number of clicks. (THIS SHOULD PROBABLY BE REPLACED WITH FUNCTION TO INCREMENT)
 * @param eles
 */
function PlexusNotes({firebaseRef, logout, needFreshEles, setNeedFreshEles, user}) {
    const dispatch = useDispatch() //for Redux
    const pages = useSelector(selectPages)

    //the notes to focus on (in header, as "titles"), for which to show related notes
    const titleIds = useSelector(selectTitleIds, shallowEqual)
    const secondaryTitleId = titleIds[1]
    const titlePages: PlexusPage[] = useMemo(
        () => (titleIds ? titleIds : []).map(id => pages[id]).filter(e => e),
        [titleIds]
    )
    const titlePage = useMemo(
        () => ((titlePages ? titlePages : []).length > 0 ? titlePages[0] : undefined),
        [titlePages]
    )

    const secondaryTitleIds = useSelector(selectSecondaryTitleIds, shallowEqual)

    // whether to show the upload modal
    const modalShow = useSelector(selectModal)

    const showExportModal = useSelector(selectShowExportModal)

    //What's this for?
    //Marks the very first title render, so we can do some stuff afterward (after the person has interacted)
    //Bit of a janky way to make sure we don't add to action record every time you load the page and titles get reset to universe
    const [afterFirstTitle, setAfterFirstTitle] = useState(false)

    //Page switching logic:

    //For managing browser history
    const history = useHistory()
    const {titleID} = useParams() //the id of the "focus note" in the url

    //Steps:
    // 1) set the new url with the corresponding title ids
    // 2) useEffect runs every time there's a change to the url to retrieve the titles corresponding to those IDs
    //set titles state variable based on the ids in the url that were retrieved
    //not really configured for multiple titles yet
    const switchPage = useCallback(
        (titleId: string) => {
            // debugger
            history.push(`/plex/${titleId}`)
            return pages[titleId]
        },
        [titleID, pages, history]
    )
    useEffect(() => {
        dispatch(setTitleIds(titleID && titleID.length > 0 ? [titleID, secondaryTitleId] : []))
    }, [titleID])

    useEffect(() => {
        console.log('titleIds', titleIds)
    }, [titleIds])

    //Dictionary processing
    //Then do for secondary later
    //See if this processing works
    const previousTitleId: any = usePrevious(titleIds[0])
    // useEffect(() => {
    //     //@ts-ignore
    //     if (previousTitleId && typeof pages !== 'undefined' && previousTitleId in pages) {
    //         gbl.reprocessPage(pages[previousTitleId])
    //     }
    // }, [titleIds[0]])

    /**
     * THINGS TO DO WHEN THE TITLE CHANGES
     *
     * Update the neighbors if it's the OK to sort them again.
     * @param {*} newTitle
     * @param {*} keepScrollLocation
     * @param {*} noSort
     */
    useEffect(() => {
        // if title nodes array is defined, and this is not the first title being set
        // (ie it is a title being set by the person rather than by the reload)
        setAfterFirstTitle(true)

        if (!(titleIds && afterFirstTitle)) return

        //Set notesDisplayed for web view
    }, [titlePages])

    //change tab title every time title changes
    useEffect(() => {
        document.title = `Plexus`
    }, [])

    //useEffect for expansion
    useEffect(() => {
        document.addEventListener('keydown', event => {
            if (event.key === 'Meta') {
                dispatch(setMetaPressed(true))
            } else {
                dispatch(setMetaPressed(false))
            }
        })
        document.addEventListener('keyup', event => {
            if (event.key === 'Meta') {
                dispatch(setMetaPressed(false))
            }
        })

        //Test
        // testFunction()
    }, [])

    const listKey = titlePages && titlePages.length > 0 ? titlePages[0].id : 'universe'
    const emptyArray = useMemo(() => [], [])

    const isMobile = useMemo(
        () =>
            /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
                navigator.userAgent
            ),
        []
    )
    const openForBusiness = useSelector(selectShowStore)

    return (
        <TopLevelContext.Provider
            value={{
                switchPage: switchPage,
                logout,
            }}
        >
            {/* <ReactJoyride /> */}

            {pages || true ? (
                <div className="header-and-pages">
                    <PageStoreTitleButton />
                    <SeeSettingsButton />

                    {/* Only show pages for now, not concept map */}
                    {true ? (
                        <div className="pages-container">
                            {!openForBusiness ? <div></div> : <PageStore />}
                            {titleIds.length > 0 && titleIds[0] in pages ? (
                                <Page
                                    {...{
                                        pageId: titleIds[0],
                                        emptyArray,
                                        listKey,
                                        secondary: false,
                                    }}
                                />
                            ) : (
                                <span></span>
                            )}
                            {titleIds.length > 1 && titleIds[1] in pages ? (
                                <Page
                                    {...{
                                        pageId: titleIds[1],
                                        emptyArray,
                                        listKey: titleIds[1] + '-panel',
                                        secondary: true,
                                    }}
                                />
                            ) : (
                                <span></span>
                            )}
                            {/* {secondaryTitleIds?.length > 0 ? (
                                <Page
                                    {...{
                                        titleIds: secondaryTitleIds,
                                        emptyArray,
                                        listKey:
                                            secondaryTitleIds.length > 0
                                                ? secondaryTitleIds[0]
                                                : 'universe',
                                        secondary: true,
                                    }}
                                />
                            ) : [GraphDisplay.LIST_WEB].includes(graphDisplay) ? (
                                <ConceptMap partialScreen={true} />
                            ) : (
                                <div className="disappearOnConstrict"></div>
                            )} */}
                        </div>
                    ) : (
                        // <ConceptMap partialScreen={false} />
                        <></>
                    )}

                    <RightSidebar />
                </div>
            ) : (
                <Loading />
            )}

            {/* For easily triggering a function to debug */}
            <KeyboardEventHandler
                handleKeys={['shift+t']}
                handleEventType={'keydown'}
                onKeyEvent={(key, e) => {}}
            />
        </TopLevelContext.Provider>
    )
}
export default PlexusNotes
