import React, {useContext} from 'react'
import {useDispatch} from 'react-redux'
import {PageOriginStory} from '../../Algorithms/EntityRecognizer/PageInterfaces'
import TopLevelContext from '../../Contexts/TopLevelContext'
import {setShowStore} from '../../Slices/ConceptStoreSlice'
import {isMobile} from '../App'
import {simpleFirebaseWriter} from '../PlexusDataContainer'

const AddPageButton = ({openForBusiness, focusEditor}) => {
    const {switchPage} = useContext(TopLevelContext)
    const dispatch = useDispatch()

    return (
        <div
            title="Create a new page"
            className={'concept-in-store ' + (openForBusiness ? 'open' : 'closed') + ' button'}
            onClick={event => {
                // focusEditor()
                simpleFirebaseWriter.addPageAndSelect('', switchPage, PageOriginStory.MANUAL)
                if (isMobile) dispatch(setShowStore(false))
            }}
        >
            <span className="concept-store-button-words">New Page</span>
        </div>
    )
}

export default AddPageButton
