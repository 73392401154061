//React
import React, {useState, useEffect, useContext, useCallback} from 'react'

//Other Components
import 'react-bootstrap-typeahead/css/Typeahead.css'

import firebase from 'firebase'

//Plexus files
import PlexusNotes from './PlexusNotes/PlexusNotes'
import LoadingSymbol from './Loaders/Loading'
import {auth} from './App'
import {Switch, BrowserRouter, Route, useRouteMatch} from 'react-router-dom'
import {useSelector, useDispatch} from 'react-redux'
import AuthContext from '../Contexts/AuthContext'
import FirebaseWriter22 from '../BackendDataManagement/Jan2022/FirebaseWriter22'
import {deletePage, selectPages, setPageFull, setPages} from '../Slices/PagesSlice'
import GraphBuilderLite from '../Algorithms/GraphBuilding/GraphBuilderLite/GraphBuilderLite'
import {PageOriginStory, PlexusPage} from '../Algorithms/EntityRecognizer/PageInterfaces'
import {
    onboardingPages,
    sampleUseCasePages,
} from '../BackendDataManagement/Jan2022/InitOnboardingContent'
import {processPage} from '../UnifiedMutations/UnifiedMutations'
import FolderLogic from './PageStore/FolderLogic/FolderLogic'
import {Node} from 'slate'

//Two application-level classes. Deal with backend writing and word-indexing.
// Both are very central to Plexus.
export let simpleFirebaseWriter: FirebaseWriter22
export let gbl: GraphBuilderLite
//both are initialized below
/**
 * Overarching component, used for loading data into PlexusNotes
 *
 * @param {Object} user
 */
function PlexusDataContainer() {
    const dispatch = useDispatch()

    let routeMatch = useRouteMatch()

    const {user, setUser} = useContext(AuthContext)
    const pages = useSelector(selectPages)

    // Stores whether the graph should be initialized, i.e. whether the user is new
    const [initialize, setInitialize] = useState(false)
    const [needFreshEles, setNeedFreshEles] = useState(false)
    //Stored in firebase for user:
    const [firebaseRef, setRef] = useState<any>() //reference to Firebase database
    const [isLoading, setLoading] = useState<boolean>(true) //Whether the program is still initializing

    //the user has clicked throughout history of their account, yet to be initialized

    const initializeRef = () => {
        const databaseRef = firebase.database().ref()
        const thePerson = user

        //firebse writer
        simpleFirebaseWriter = new FirebaseWriter22(databaseRef, thePerson.uid)
        gbl = new GraphBuilderLite(simpleFirebaseWriter)

        const userRef = databaseRef.child('people').child(thePerson.uid)
        setRef(userRef)
        return userRef
    }
    //Fetch elements data to start.
    useEffect(() => {
        const fetchData = async () => {
            const userRef = initializeRef() //initialized firebase Writer too

            const root = firebase.database().ref()

            //Get dictionary on every update (should only happen once though, cuz we update dictionary directly, for speed)
            const dictionaryQuery = root.child(`people/${user.uid}/dictionary`)
            dictionaryQuery
                .once(
                    'value',
                    dataSnap => {
                        const val = dataSnap.val()
                        //initialize gbl
                        if (val) {
                            gbl.initializeDictionary(val)
                        }
                    },
                    e => console.log('issue loading dictionary from firebase' + e)
                )
                .then(() => {
                    const pagesWordsQuery = root.child(`people/${user.uid}/pages`)
                    pagesWordsQuery.once(
                        'value',
                        dataSnap => {
                            const val = dataSnap.val()
                            //initialize page words in gbl
                            if (val) {
                                gbl.initializePageWords(val)
                            }
                        },
                        e => console.log('issue loading dictionary from firebase' + e)
                    )
                })

            //Get pages every time updates
            const allPagesRef = root.child('pages')
            const pagesQuery = allPagesRef.orderByChild('author_id').equalTo(user.uid)
            pagesQuery.once(
                'value',
                dataSnap => {
                    if (!dataSnap.exists()) {
                        //Preload account with content. then return
                        let firstPageId: string
                        const pageSets = [onboardingPages]
                        pageSets.forEach(samplePages => {
                            samplePages.forEach((thisPage, i) => {
                                const text = Node.string(thisPage[0])
                                const {promise, page} = simpleFirebaseWriter.addPage(
                                    text
                                        ? //@ts-ignore
                                          text
                                        : '',
                                    PageOriginStory.MANUAL,
                                    thisPage,
                                    undefined,
                                    i == 0 ? 99999999999999 : undefined
                                )
                                promise.then(() => {
                                    //folder relation with sub pages
                                    if (i > 0) {
                                        simpleFirebaseWriter.addFolderRelation(
                                            firstPageId,
                                            page.id
                                        )

                                        //process page
                                        processPage(
                                            page,
                                            gbl,
                                            simpleFirebaseWriter,
                                            () => undefined,

                                            undefined,
                                            page.slateValue
                                        )
                                    } else firstPageId = page.id
                                })
                            })
                        })

                        return fetchData()
                    }
                    const val = dataSnap.val()

                    //Write pages to redux
                    //seing what firebase looks like
                    dispatch(setPages(val ? val : []))
                    setStuffLoaded()
                },
                e => console.log('pages issue' + e)
            )

            //Keep client-side pages list up to date
            const childEvents = pagesQuery.on(
                'child_added',
                dataSnap => {
                    const val = dataSnap.val()
                    //Write pages to redux
                    dispatch(setPageFull({pageId: dataSnap.key, value: val}))
                },
                e => console.log('child events issue' + e)
            )
            const childEvents2 = pagesQuery.on(
                'child_changed',
                dataSnap => {
                    const val = dataSnap.val()
                    if (dataSnap.key.includes('qq9v')) debugger
                    //Write pages to redux
                    dispatch(setPageFull({pageId: dataSnap.key, value: val}))
                },
                e => console.log('child events issue' + e)
            )
            const childEvents3 = pagesQuery.on(
                'child_removed',
                dataSnap => {
                    const val = dataSnap.val()
                    //Write pages to redux
                    dispatch(deletePage({pageId: dataSnap.key}))
                },
                e => console.log('child events issue' + e)
            )

            // Old data retrieval
        }
        //If builder doesn't already has elements
        fetchData()
    }, [])

    const setStuffLoaded = () => {
        setLoading(false)
        setInitialize(true)
    }

    /**
     * Logs the current user out (and should, as a result, redirect them to the login page)
     */
    const logout = useCallback(() => {
        auth.signOut()
            .then(() => {
                gbl = undefined
                console.log(user, 'signed out')
                setUser(undefined)
            })
            .catch(err => console.log(err.message))
    }, [])

    const PlexusNotesWithParams: any = (
        <div id="whole-thing">
            {!isLoading ? (
                <PlexusNotes
                    firebaseRef={firebaseRef}
                    {...{needFreshEles, setNeedFreshEles}}
                    user={user}
                    logout={logout}
                />
            ) : (
                <LoadingSymbol />
            )}
        </div>
    )

    return (
        <BrowserRouter>
            <Switch>
                <Route path={`${routeMatch.path}/:titleID`}>{PlexusNotesWithParams}</Route>
                <Route path={`${routeMatch.path}`}>{PlexusNotesWithParams}</Route>
            </Switch>
        </BrowserRouter>
    )
}
export default PlexusDataContainer
