import React, {useContext, useEffect, useMemo, useState} from 'react'
import {useDispatch, useSelector} from 'react-redux'
import TopLevelContext from '../../Contexts/TopLevelContext'

import {setShowStore} from '../../Slices/ConceptStoreSlice'

import '@szhsin/react-menu/dist/index.css'
import '@szhsin/react-menu/dist/transitions/slide.css'
import {PlexusPage} from '../../Algorithms/EntityRecognizer/PageInterfaces'
import {deletePage} from '../../UnifiedMutations/UnifiedMutations'
import {gbl, simpleFirebaseWriter} from '../PlexusDataContainer'

const PageInStore = ({
    openForBusiness,
    titleIds,
    maxChars,
    index,
    page,
    title,
    isTopQueriedPage,
}: {
    openForBusiness
    titleIds
    maxChars
    index
    page: PlexusPage
    title: string
    isTopQueriedPage: boolean
}) => {
    const dispatch = useDispatch()
    const {switchPage} = useContext(TopLevelContext)
    const isMobile = useMemo(
        () =>
            /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
                navigator.userAgent
            ),
        []
    )
    const [hover, setHover] = useState(false)
    const isTitle = useMemo(() => titleIds.slice(0, 1).includes(page.id), [titleIds, page])
    const textToShow = abbreviateSimple(title)
    const [selected, setSelected] = useState(false)
    //for the race condition
    const [justClicked, setJustClicked] = useState(false)

    useEffect(() => {
        setJustClicked(false)
    }, [titleIds[0]])

    const handleClick = (e: any) => {
        setJustClicked(true)

        //move close concept store to title switch logic
        switchPage(page.id)

        if (isMobile) {
            dispatch(setShowStore(false))
        }
    }
    return (
        <div
            key={'CONCEPT_IN_STORE-' + page.id}
            className={
                'concept-in-store ' +
                (openForBusiness ? 'open' : 'closed') +
                (titleIds[0] === page.id || justClicked ? ' title' : '') +
                (isMobile ? ' isMobile' : '') +
                (isTopQueriedPage ? ' topQueriedPage' : '')
            }
            onClick={handleClick}
            onMouseOver={() => setHover(true)}
            onMouseOut={() => setHover(false)}
        >
            {/* include this onclick handler for mobile, so no need to double tab */}
            <span
                onClick={e => {
                    e.stopPropagation()
                    handleClick(e)
                }}
            >
                {textToShow.length > 0 ? textToShow : 'Untitled'}
            </span>

            <div style={{display: 'flex', alignItems: 'center'}}>
                <span title="Click to toggle starred"></span>
                <div
                    className={
                        'page-in-store-delete-button' + (hover || isMobile ? ' visible' : '')
                    }
                    onClick={e => {
                        e.stopPropagation()
                        if (
                            window.confirm(
                                `Are you sure you'd like to delete "${page.title}"? Can't be undone.`
                            )
                        )
                            deletePage(page, gbl, simpleFirebaseWriter)
                    }}
                >
                    ✕
                </div>
                {/* <TripleDotMenu id={page.id} hover={hover} isConceptStoreItem={true} /> */}
            </div>
        </div>
    )
}

export default PageInStore

export const abbreviateSimple = (text: string) => {
    const ellipses = '...'
    const maxChars = 40
    return text.length > maxChars ? text.slice(0, maxChars) + ellipses : text
}
