import React, {useContext} from 'react'
import './PinnedNote.css'
import '../../References/References.css'
import {PinnedNoteElement} from '../EditorContainer/slateConfig'
import {
    PlexusPage,
    PlexusParagraph,
} from '../../../../Algorithms/EntityRecognizer/PageInterfaces'
import {Node, Transforms} from 'slate'
import {ReactEditor, useFocused, useSelected, useSlate} from 'slate-react'
import TopLevelContext from '../../../../Contexts/TopLevelContext'

const PinnedNote = ({props, pages}) => {
    //reretrieve the proper paragraph
    const {switchPage} = useContext(TopLevelContext)
    const {paragraphId, pageId} = props.element.paragraph as PlexusParagraph
    const updatedPage = pages[pageId] as PlexusPage
    const updatedParagraph = updatedPage
        ? updatedPage.slateValue.filter(e => e.id === paragraphId)[0]
        : []
    const updatedText = ''

    const selected = useSelected()
    const focused = useFocused()
    const editor = useSlate()
    return updatedText ? (
        <div
            {...props.attributes}
            className="slate-paragraph reference-item pinned"
            style={{outline: selected && focused ? 'solid 2px gainsboro' : undefined}}
            contentEditable={true}
        >
            {' '}
            <div style={{display: 'flex', flexDirection: 'column'}} contentEditable={false}>
                <span
                    className="pinned-note-title"
                    contentEditable={false}
                    onClick={() => switchPage(pageId)}
                >
                    {updatedPage.title}
                </span>
                <span>
                    {updatedText}
                    {/* <span
                        // style={{fontSize: '2em'}}
                        onClick={e => {
                            //this is how to delete a node within the node
                            const path = ReactEditor.findPath(editor, props.element)
                            Transforms.delete(editor, {at: path})
                        }}
                    >
                        ●
                    </span> */}
                </span>
            </div>
            {props.children}
        </div>
    ) : (
        <></>
    )
}

export default PinnedNote
