import React from 'react'
import {useDispatch, useSelector} from 'react-redux'
import {selectShowStore, setShowStore} from '../../Slices/ConceptStoreSlice'

const ToggleStoreCaret = ({symbol}) => {
    const showStore = useSelector(selectShowStore)
    const dispatch = useDispatch()

    return (
        <div
            id="concept-caret"
            className={'toggles-store' + (true ? ' one-eighty-degrees' : '')}
            onClick={() => {
                dispatch(setShowStore(false))
            }}
        >
            {symbol ? symbol : '▸'}
            {/* '▸' '❯'*/}
        </div>
    )
}

export default ToggleStoreCaret
