import {title} from 'process'
import {Descendant, Node} from 'slate'
import {PageOriginStory, PlexusPage} from '../../Algorithms/EntityRecognizer/PageInterfaces'
import {PlexusPagesMap} from '../../Algorithms/GraphBuilding/GraphBuilderLite/GraphBuilderLite'
import {anyCharIsAlphanumeric} from '../../Algorithms/TextNormalization/TextNormalization'
import {arraysEqual} from '../../Miscellaneous/Miscellaneous'
import {SlateNoteElement} from '../Page/SimplerSlateEditor/EditorContainer/slateConfig'
import {gbl} from '../PlexusDataContainer'
import FolderLogic from './FolderLogic/FolderLogic'
import {FOLDER_FEATURE_ON} from './PageTreeInStore.tsx/PageTreeInStore'

// don't look at first line (title)
export const emptySlateValue = (slateValue: Descendant[]) => {
    if (!(slateValue && slateValue.length > 1)) return true
    let empty = true
    slateValue.slice(1).forEach((desc: Descendant) => {
        if (!emptyDesc(desc)) empty = false
    })
    return empty
}

export const emptyDesc = (desc: Descendant) => {
    //@ts-ignore

    const string = desc.children ? Node.string(desc) : ''
    if (anyCharIsAlphanumeric(string)) return false

    //@ts-ignore
    if (desc.type === 'reference') return false
    return true
}

/**
 * Currently, shows page if it's made manually or has content
 * @param page
 * @param titleId
 */
export const shouldShowPage = (
    page: PlexusPage,
    titleId: string,
    pagesMap: PlexusPagesMap
): boolean => {
    const parents = FolderLogic.findParents(page, pagesMap)
    return (
        !FOLDER_FEATURE_ON ||
        //Either no parents
        (!(parents && parents.length > 0) && pageIsContentfulOrManuallyMade(page))
    )
}

export const pageIsContentfulOrManuallyMade = (page: PlexusPage) => {
    return (
        ![PageOriginStory.AUTOMATIC, PageOriginStory.ACCEPTED].includes(page.originStory) ||
        !emptySlateValue(page.slateValue)
    )
}

export const hasIncomers = (page: PlexusPage) =>
    page.incomers && Object.keys(page.incomers).length > 0
export const hasOutgoers = (page: PlexusPage) =>
    page.outgoers &&
    Object.keys(page.outgoers).filter(outgoerPageId => outgoerPageId !== page.id).length > 0
