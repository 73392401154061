// @flow strict
import './SearchPages.css'

import * as React from 'react'
import {createEditor, Descendant, Editor, Node, Transforms} from 'slate'
import {Editable, ReactEditor, Slate, withReact} from 'slate-react'
import {withHistory} from 'slate-history'
import TopLevelContext from '../../Contexts/TopLevelContext'
import {setShowStore} from '../../Slices/ConceptStoreSlice'
import {useDispatch} from 'react-redux'
import {CREATE_NEW_ID} from './PageStore'
import {addPage, addPageAndFocus} from '../../UnifiedMutations/UnifiedMutations'
import {gbl, simpleFirebaseWriter} from '../PlexusDataContainer'
import {PageOriginStory} from '../../Algorithms/EntityRecognizer/PageInterfaces'
import {isMobile} from '../App'

export const initialSearchValue = [
    {
        type: 'note' as 'note',
        children: [{text: ''}],
        id: 'search',
    },
]
function SearchPages({
    setQueryFocused,
    queryFocused,
    searchEditor,
    searchValue,
    setSearchValue,
    topQueriedPageId,
}: {
    setQueryFocused
    queryFocused
    searchEditor
    searchValue
    setSearchValue
    topQueriedPageId: string
}) {
    const dispatch = useDispatch()
    const onChange = (newVal: Descendant[]) => setSearchValue(newVal)
    const {switchPage} = React.useContext(TopLevelContext)
    const editorRef = React.useRef<any>()
    const focusHere = () => {
        setTimeout(() => ReactEditor.focus(searchEditor), 1)
        Transforms.select(searchEditor, Editor.end(searchEditor, []))
    }
    React.useEffect(focusHere, [])
    React.useEffect(() => {
        document.addEventListener('keydown', event => {
            if (event.key === 'l' && event.metaKey) {
                event.preventDefault()

                focusHere()
            }
        })
    }, [])
    return (
        <div className="search-pages concept-in-store" onClick={focusHere}>
            <Slate {...{editor: searchEditor, value: searchValue, onChange}}>
                <Editable
                    placeholder="Search..."
                    style={{minWidth: '5em'}}
                    onBlur={() => setQueryFocused(false)}
                    onFocus={() => setQueryFocused(true)}
                    onClick={e => {
                        // e.stopPropagation()
                    }}
                    onKeyDown={event => {
                        if (event.key.toLocaleLowerCase() === 'enter') {
                            event.preventDefault()
                            if (topQueriedPageId) {
                                if (topQueriedPageId === CREATE_NEW_ID) {
                                    addPageAndFocus(
                                        Node.string(searchEditor),
                                        gbl,
                                        simpleFirebaseWriter,
                                        switchPage,
                                        PageOriginStory.MANUAL
                                    )
                                    searchEditor.selection = undefined
                                    setSearchValue(initialSearchValue)
                                } else switchPage(topQueriedPageId)
                            }
                            if (isMobile) dispatch(setShowStore(false))
                            // ReactEditor.blur(searchEditor)
                            // searchEditor.selection = undefined
                            // setSearchValue(initialSearchValue)
                            Transforms.setSelection(searchEditor, {
                                anchor: Editor.start(searchEditor, []),
                                focus: Editor.end(searchEditor, []),
                            })
                        }
                    }}
                />
            </Slate>
        </div>
    )
}

export default SearchPages
