import React, {useContext, useEffect, useMemo, useRef, useState} from 'react'
import closingGif from '../../Assets/plexusAnimateClosing.gif'
import openingStatic from '../../Assets/plexusStaticOpen.jpg'
import {Redirect, withRouter} from 'react-router-dom'
import {Link} from 'react-router-dom'
import './StartingPage.css'
import {FirebaseAuthConsumer} from '@react-firebase/auth'
import AuthContext from '../../Contexts/AuthContext'
import {Button, Form, FormControl, InputGroup} from 'react-bootstrap'
import 'bootstrap/dist/css/bootstrap.min.css'
import './EmailSignup.css'
import {auth} from '../../Components/App'
import ContentEditable from 'react-contenteditable'
import isAuthorizedEmail from './validEmail'
import './ReferList.css'
import OneLiner from './OneLiner'
import WaitlistSignup from './WaitlistSignup'
import FoldingAnimation from './FoldingAnimation'
import {SocialIcon} from 'react-social-icons'
import GoogleLogin from './GoogleLogin'

const StartingPage = () => {
    const [showInitialOpenLogo, setShowInitialOpenLogo] = useState(false)
    const [plexHover, setPlexHover] = useState(false)
    const [gif, setGif] = useState(openingStatic)
    const [locked, setLocked] = useState(true)
    const [showLoginButton, setShowLoginButton] = useState(false)
    const [extraClasses, setExtraClasses] = useState('')
    const [logoBigger, setLogoBigger] = useState(false)
    const {user, setUser} = useContext(AuthContext)
    const [email, setEmail] = useState()

    useEffect(() => {
        //so that it might be missed on saved auth
        setTimeout(() => setShowInitialOpenLogo(true), 500)
        setTimeout(() => setPlexHover(true), 800)
    }, [])
    useEffect(() => {
        if (plexHover) {
            setLocked(false)
        }
    }, [plexHover])
    useEffect(() => {
        if (!locked) {
            setGif(closingGif)
            setTimeout(() => setShowLoginButton(true), 1200)
            setTimeout(() => setLogoBigger(true), 780)
        }
    }, [locked])
    useEffect(() => {
        if (showLoginButton) {
            setExtraClasses('show')
        }
    }, [showLoginButton])

    const taglines = [
        'notes that think',
        'a self-organizing notebook',
        'a word-processor that processor your words',
        'A notebook that organizes itself',
        'A second brain',
    ]
    const emailText = useRef('')
    const [reactEmailText, setReactEmailText] = useState('')
    const [linkSent, setLinkSent] = useState(false)
    const emailIsAuthorized = useMemo(() => isAuthorizedEmail(reactEmailText), [reactEmailText])
    // // if(linkSent)

    return !user ? (
        <>
            <div className="startingPage-topBanner">
                {/* <Link to={'/login'} className="link smallerButton">
                    log in →
                </Link> */}
                <br></br>
            </div>
            <div className="startingPage">
                <div className={'everythingElse ' + extraClasses}>
                    <div className="plexusText brand">Plexus Notes</div>
                </div>
                {/* <FoldingAnimation
                    setPlexHover={setPlexHover}
                    logoBigger={logoBigger}
                    gif={gif}
                    setShowInitialOpenLogo={setShowInitialOpenLogo}
                /> */}
                <div>
                    &nbsp;
                    {showInitialOpenLogo ? (
                        <img
                            src={gif}
                            onMouseOver={() => setPlexHover(true)}
                            onMouseOut={() => setPlexHover(false)}
                            id="loader"
                            className={logoBigger ? 'bigger' : ''}
                        ></img>
                    ) : (
                        <></>
                    )}
                </div>
                {/* <a  style ={{'marginLeft': '0px'}} >Coming soon.</a> */}
                <div className={'everythingElse ' + extraClasses}>
                    {/* <div className="brand">
                        <div className="tagline">{taglines[0]}</div>
                    </div> */}
                    <div className={'startPageLinks'}>
                        <GoogleLogin />
                        <OneLiner />
                    </div>
                    <div style={{opacity: 0.5, margin: -5}}>
                        <SocialIcon
                            url="https://www.instagram.com/plexusnotes/"
                            style={{height: 25, width: 25, margin: 3}}
                        />
                        <SocialIcon
                            url="https://www.facebook.com/plexusnotes"
                            style={{height: 25, width: 25, margin: 3}}
                        />
                        <SocialIcon
                            url="https://www.twitter.com/notesthatthink"
                            style={{height: 25, width: 25, margin: 3}}
                        />
                    </div>
                    <div className="other-links">
                        {/* <a href="https://innovative-phosphorus-3ab.notion.site/Joining-Plexus-370dd63ea16d463e8b34ec629625d344">
                            careers
                        </a> */}
                        <a href="https://plexus.earth">plexus.earth</a>
                        <a href="https://innovative-phosphorus-3ab.notion.site/CEO-of-Plexus-Notes-ff3e0103f0f8457499a92568f2332cb9">
                            hiring
                        </a>

                        <a href="https://plexus.substack.com">newsletter</a>
                    </div>
                </div>
            </div>{' '}
        </>
    ) : (
        <Redirect to="/plex" />
    )
}
export default StartingPage

// const email = emailText.current

// {
//                                     }
//                                     setLinkSent(true)

//                                     var actionCodeSettings = {
//                                         // URL you want to redirect back to. The domain (www.example.com) for this
//                                         // URL must be in the authorized domains list in the Firebase Console.
//                                         url: 'https://plexusnotes.com/plex',
//                                         // This must be true.
//                                         handleCodeInApp: true,
//                                     }
//                                     auth.sendSignInLinkToEmail(email, actionCodeSettings)
//                                         .then(() => {
//                                             // The link was successfully sent. Inform the user.
//                                             // Save the email locally so you don't need to ask the user for it again
//                                             // if they open the link on the same device.
//                                             window.localStorage.setItem('emailForSignIn', email)
//                                             window.alert(
//                                                 "We're sending a verification link to " +
//                                                     email +
//                                                     '. \n You can close this tab now! \n— Davey'
//                                             )
//                                             // ...
//                                         })
//                                         .catch(error => {
//                                             var errorCode = error.code
//                                             var errorMessage = error.message
//                                             // ...
//                                         })
