import * as React from 'react'
import './Settings.css'
import TopLevelContext from '../../../Contexts/TopLevelContext'
import {Button} from 'react-bootstrap'
import {useDispatch, useSelector} from 'react-redux'
import {useHistory} from 'react-router-dom'
import AuthContext from '../../../Contexts/AuthContext'
import {gbl, simpleFirebaseWriter} from '../../PlexusDataContainer'
import {deleteAllAccountData} from '../../../UnifiedMutations/UnifiedMutations'
import {selectPage, selectPages} from '../../../Slices/PagesSlice'
import {downloadPages} from './Download'
import {isMobile} from '../../App'

function Actions() {
    const {logout} = React.useContext(TopLevelContext)
    const {user} = React.useContext(AuthContext)
    const dispatch = useDispatch()
    const history = useHistory()

    const pages = useSelector(selectPages)

    return (
        <div className="settings actions">
            <h1 className="settingsTitle">Actions</h1>

            <a
                href="https://forms.gle/fN9dPy3AgndtGZxd8"
                className="settingsLink"
                title="Share what you hate/love about Plexus, start a conversation, tell us how your day's going, etc."
            >
                Share Feedback!
            </a>

            {isMobile ? (
                <></>
            ) : (
                <a
                    className="settingsLink"
                    target="_blank"
                    rel="noreferrer noopener"
                    title="Download desktop app"
                    href="https://links.todesktop.com/e/c/eyJlbWFpbF9pZCI6ImRnUHhuZ1h4bmdVQkFBRjZNZVBhZTBrd0VKWkVLdXVhUWprPSIsImhyZWYiOiJodHRwczovL2RsLnRvZGVza3RvcC5jb20vMjEwNjIydG1lb3lwcDFqIiwibGlua19pZCI6MjU1LCJwb3NpdGlvbiI6MH0/87ab31cc19363c9fa3143c1991abf82131e2a6f52de616747adeec56f4b22c56"
                >
                    Download Desktop App
                </a>
            )}

            <div
                onClick={() => {
                    if (
                        window.confirm(
                            'Are you sure you would like to delete all of the notes in this account? Cannot be undone.'
                        )
                    ) {
                        deleteAllAccountData(pages, gbl, simpleFirebaseWriter)
                        // document.location.reload()
                    }
                }}
                className="settingsLink"
            >
                Delete All
            </div>
            <div onClick={logout} className="settingsLink">
                Log Out{' '}
                <div style={{fontSize: '.9em', color: 'gray'}}>{user ? user.email : ''}</div>
            </div>
            {isMobile ? (
                <></>
            ) : (
                <div
                    title="Download all pages as a .txt file."
                    onClick={() => {
                        downloadPages(pages)
                    }}
                    className="settingsLink"
                >
                    Download Notes
                </div>
            )}

            <a
                href="mailto:team@plexusnotes.com"
                className="settingsLink"
                title="Share what you hate/love about Plexus, start a conversation, tell us how your day's going, etc."
            >
                Email Us
            </a>
        </div>
    )
}

export default Actions
