import React, {useContext, useEffect, useMemo, useState} from 'react'
import {useDispatch, useSelector} from 'react-redux'
import {useSelected} from 'slate-react'
import {DOMNode} from 'slate-react/dist/utils/dom'
import {PlexusPage, PlexusParagraph} from '../../../Algorithms/EntityRecognizer/PageInterfaces'
import TopLevelContext from '../../../Contexts/TopLevelContext'
import {selectPages, selectSetEditorValue} from '../../../Slices/PagesSlice'
import {dragAndDrop} from '../../../UnifiedMutations/UnifiedMutations'
import {usePrevious} from '../../Miscellaneous/UsePreviousHook'
import {gbl, simpleFirebaseWriter} from '../../PlexusDataContainer'
import FolderLogic from '../FolderLogic/FolderLogic'
import PageInStore from '../PageInStore'
import {pageSorter} from '../PageStore'
import './PageTreeInStore.css'

export const FOLDER_FEATURE_ON = true
/**
 * Draggable
 * @param param0
 * @returns
 */
const PageTreeInStore = ({
    openForBusiness,
    titleIds,
    maxChars,
    index,
    page,
    level,
    isTopQueriedPage,
    autoFolders,
    path,
}: {
    openForBusiness
    titleIds
    maxChars
    index
    page: PlexusPage
    level?: number
    isTopQueriedPage: boolean
    autoFolders: boolean
    path: string[] // page id path, does not include current page
}) => {
    const TREE_IN_STORE_CSS_CLASS = 'page-tree-in-store'

    const {switchPage} = useContext(TopLevelContext)

    const pages = useSelector(selectPages)
    const connectedPages: PlexusPage[] = useMemo(() => {
        let result
        if (!FOLDER_FEATURE_ON) return []
        if (autoFolders) {
            const connectedReferences = FolderLogic.findConnectedReferences(
                page,
                pages,
                gbl,
                true
            ).sort(pageSorter)
            result = connectedReferences
        } else {
            const children = FolderLogic.findChildren(page, pages)
            result = children
        }

        //Filter out everything in path above and itselff
        return result
            ? result.filter(connectedPage => ![...path, page.id].includes(connectedPage.id))
            : []
    }, [pages, autoFolders])
    const [open, setOpen] = useState(false)
    //expand if matches titleId

    const previousTitleIds: string[] | undefined = usePrevious(titleIds)
    useEffect(() => {
        if (titleIds.includes(page.id)) {
            setOpen(true)
        }
    }, [titleIds])

    const [engagedToReceiveChild, setEngagedToReceiveChild] = useState(false)

    const DATA_TRANSFER_PATHS = {
        dragged: 'application/plexus/draggedEle',
        parent: 'application/plexus/draggedEleParent',
    }

    const disableDragAndDrop = false
    const handleDragStart = ev => {
        if (disableDragAndDrop) return
        //only look at innermost tree
        ev.stopPropagation()
        // ev.preventDefault()
        const targetElement: HTMLElement = ev.currentTarget
        //Set the dragged tree
        ev.dataTransfer.setData(DATA_TRANSFER_PATHS.dragged, targetElement.id)
        //Set the dragged tree's parent, if it exists
        const parent: HTMLElement = targetElement.parentElement.closest(
            `.${TREE_IN_STORE_CSS_CLASS}`
        )
        if (parent && parent.id) ev.dataTransfer.setData(DATA_TRANSFER_PATHS.parent, parent.id)
        ev.dataTransfer.effectAllowed = 'move'
    }
    const handleDragOver = ev => {
        if (disableDragAndDrop) return
        ev.preventDefault()
        //only look at innermost tree
        ev.stopPropagation()
        ev.dataTransfer.dropEffect = 'move'
        const draggedPageId = ev.dataTransfer.getData(DATA_TRANSFER_PATHS.dragged)
        if (ev.currentTarget && ev.currentTarget.id !== draggedPageId)
            ev.currentTarget.style.background = '#E8E8E8'
    }
    const handleDragLeave = ev => {
        if (disableDragAndDrop) return
        ev.preventDefault()
        //only look at innermost tree
        ev.stopPropagation()
        ev.dataTransfer.dropEffect = undefined
        if (ev.currentTarget) ev.currentTarget.style.background = 'none'
    }
    const handleDrop = ev => {
        if (disableDragAndDrop) return
        ev.preventDefault()
        //only look at innermost tree
        ev.stopPropagation()
        const childPageId = ev.dataTransfer.getData(DATA_TRANSFER_PATHS.dragged)
        const oldParentId = ev.dataTransfer.getData(DATA_TRANSFER_PATHS.parent)
        const newParentId = ev.currentTarget ? ev.currentTarget.id : undefined
        //Move one as folder of the other
        if (childPageId && childPageId !== newParentId && childPageId in pages) {
            simpleFirebaseWriter.dragAndDropNew(childPageId, oldParentId, newParentId)
        }
        // cleanup
        if (ev.currentTarget) ev.currentTarget.style.background = 'none'
        ev.dataTransfer.clearData()
    }
    return (
        <>
            <div
                id={page.id}
                key={page.id}
                className={TREE_IN_STORE_CSS_CLASS}
                draggable={true}
                onDragStart={handleDragStart}
                onDragOver={handleDragOver}
                onDragLeave={handleDragLeave}
                onDrop={handleDrop}
            >
                <div style={{display: 'flex', flexDirection: 'row', alignItems: 'center'}}>
                    <div
                        id="page-store-item-caret"
                        style={{
                            visibility:
                                connectedPages && connectedPages.length > 0
                                    ? 'visible'
                                    : 'hidden',
                        }}
                        className={'page-in-store-caret ' + (open ? ' ninety-degrees' : '')}
                        onClick={() => setOpen(prev => !prev)}
                    >
                        ▸
                    </div>

                    <PageInStore
                        key={page.id}
                        {...{
                            page,
                            openForBusiness,
                            titleIds,
                            maxChars,
                            index,
                            title: page.title,
                            isTopQueriedPage,
                        }}
                    />
                </div>
                <div style={{paddingLeft: '1.2em'}}>
                    {/* currently nesting is disabled. will fix soon */}
                    {open && (!level || level < 5) ? (
                        (connectedPages ? connectedPages : []).map(child => (
                            <PageTreeInStore
                                {...{
                                    page: child,
                                    openForBusiness,
                                    titleIds,
                                    maxChars,
                                    index,
                                    level: (level ? level : 0) + 1,
                                    isTopQueriedPage: false,
                                    autoFolders,
                                    path: [...path, page.id],
                                }}
                            />
                        ))
                    ) : (
                        <div></div>
                    )}
                </div>
            </div>

            <div
                id={'no-mans-land-' + page.lastUpdated}
                style={{height: '5px', background: 'transparent'}}
                onDragOver={handleDragOver}
                onDragLeave={handleDragLeave}
                onDrop={handleDrop}
            ></div>
        </>
    )
}

export default PageTreeInStore
