import React, {useEffect, useMemo, useState} from 'react'
import {Transforms} from 'slate'
import {useSlate} from 'slate-react'
import {PlexusParagraph} from '../../../Algorithms/EntityRecognizer/PageInterfaces'
import {splitNote} from '../../../Algorithms/TextNormalization/TextNormalization'
import {
    makePinnedNoteFromParagraph,
    makeSlateNoteFromText,
} from '../../../BackendDataManagement/Jan2022/FirebaseWriter22'
import PasteButton from './PasteButton'
import ToggleCaret from './ToggleCaret'

const ReferenceItem = ({
    text,
    wordStartIndex,
    para,
    query,
}: {
    text: string
    query: string
    wordStartIndex: number
    para: PlexusParagraph
}) => {
    const words = text.split(' ')
    const [open, setOpen] = useState(false)
    const lowerDelta = 10
    const wordDelta = open ? 999999 : lowerDelta
    const greatestIndices = delta => ({
        start: wordStartIndex - delta,
        end: wordStartIndex + delta + 2,
    })
    const theseGreatestIndices = greatestIndices(wordDelta)
    const startWordIndex = theseGreatestIndices.start > 0 ? theseGreatestIndices.start : 0
    const endWordIndex = theseGreatestIndices.end > 0 ? theseGreatestIndices.end : 0
    const cutOff = {start: startWordIndex > 0, end: endWordIndex < words.length}
    const showCaret = cutOff.start || cutOff.end
    const textToDisplay = useMemo(() => {
        const relevantPortion = words.slice(startWordIndex, endWordIndex).join(' ')
        return (cutOff.start ? '... ' : '') + relevantPortion + (cutOff.end ? ' ...' : '')
    }, [words, startWordIndex, endWordIndex, cutOff])

    const editor = useSlate()
    const [hover, setHover] = useState(false)
    return (
        <div
            className="reference-item"
            onMouseEnter={() => setHover(true)}
            onMouseLeave={() => setHover(false)}
        >
            <ToggleCaret
                showCaret={
                    greatestIndices(lowerDelta).start > 0 ||
                    greatestIndices(lowerDelta).end < words.length
                }
                on={open}
                handleClick={(e: any) => {
                    e.stopPropagation()
                    setOpen(prev => !prev)
                }}
            />
            {textToDisplay}

            <PasteButton
                visibility={hover ? 'visible' : 'hidden'}
                paraArr={[para]}
                handlePaste={() => {
                    Transforms.insertNodes(editor, [
                        makePinnedNoteFromParagraph(para),
                        makeSlateNoteFromText(''),
                    ])
                }}
            />
        </div>
    )
}

export default ReferenceItem
