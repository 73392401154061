import React, {useEffect, useRef} from 'react'
import {auth, provider} from '../../Components/App'

const GoogleLogin = ({emailIsAuthorized}, {emailIsAuthorized: boolean}) => {
    return (
        <div className="emailSignupContainer">
            {/* <input
                autoFocus
                id="referlistemail"
                className={
                    'email-text-input' +
                    (emailIsAuthorized ? ' authorized importantPhrase' : '')
                }
                placeholder="davey@plexusnotes.com"
                onKeyDown={event => {
                    if (event.key !== 'Enter') return
                    // const submitButton = document.getElementById('referlistbutton')
                    // submitButton.click()
                    auth.signInWithRedirect(provider)
                }}
            /> */}

            <div
                className={'get-early-access-button waitlistLink' + (true ? ' authorized' : '')}
                onClick={e => {
                    auth.signInWithRedirect(provider)
                }}
            >
                claim your notebook
            </div>
        </div>
    )
}

export default GoogleLogin
