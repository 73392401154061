import {createSlice} from '@reduxjs/toolkit'

export const initialState: {showStore: boolean; orderedConceptIds: string[]} = {
    showStore: true,
    orderedConceptIds: [],
}

export const ConceptStoreSlice = createSlice({
    name: 'conceptStore',
    initialState,
    reducers: {
        setShowStore: (state, action: {payload: boolean}) => {
            state.showStore = action.payload
        },
        toggleShowStore: state => {
            state.showStore = !state.showStore
        },
        setConcepts: (state, action: {payload: string[]}) => {
            state.orderedConceptIds = action.payload
        },
    },
})

export const {toggleShowStore, setShowStore, setConcepts} = ConceptStoreSlice.actions

export const selectShowStore = state => state.conceptStore.showStore
export const selectOrderedConceptIds = state => state.conceptStore.orderedConceptIds

export default ConceptStoreSlice.reducer
