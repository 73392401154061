// @flow strict

import {ClickEvent} from '@szhsin/react-menu'
import {stubFalse} from 'lodash'
import * as React from 'react'
import {useDispatch, useSelector, useStore} from 'react-redux'
import {Editor, Transforms} from 'slate'
import PlexusEntityRecognizer from '../../../Algorithms/EntityRecognizer/EnglishEntitiesHelpers/PlexusEntityRecognizer'
import PlexusEntityRecognizerTest from '../../../Algorithms/EntityRecognizer/EnglishEntitiesHelpers/PlexusEntityRecognizer.test'
import {PlexusPage, PlexusParagraph} from '../../../Algorithms/EntityRecognizer/PageInterfaces'
import GraphBuilderLite, {
    PageMap,
} from '../../../Algorithms/GraphBuilding/GraphBuilderLite/GraphBuilderLite'
import {
    makePinnedNoteFromParagraph,
    makeSlateNoteFromText,
} from '../../../BackendDataManagement/Jan2022/FirebaseWriter22'
import TopLevelContext from '../../../Contexts/TopLevelContext'
import {selectTitleIds, setTitleIds} from '../../../Slices/AppSlice'
import {isMobile} from '../../App'
import FolderLogic from '../../PageStore/FolderLogic/FolderLogic'
import PasteButton from './PasteButton'
import ReferenceItem from './ReferenceItem'
import ReferenceSectionTitle from './ReferenceSectionTitle'
import './ReferencesFromAPage.css'
import ToggleCaret from './ToggleCaret'

function ReferencesFromAPage({
    page,
    paraArr,
    editor,
}: {
    page: PlexusPage
    paraArr: PlexusParagraph[]
    editor: Editor
}) {
    const divId = page.id + '-references'
    const {switchPage} = React.useContext(TopLevelContext)
    const titleIds = useSelector(selectTitleIds)
    const dispatch = useDispatch()

    const [showInstances, setShowInstances] = React.useState(true)
    const parasToShow = paraArr
        .filter(para => !(para.paragraphIndex == 0 || para.text === page.title))
        .sort((a, b) => a.paragraphIndex - b.paragraphIndex)
    const [hover, setHover] = React.useState(false)
    const handleClick = () => {
        if (parasToShow.length > 0) setShowInstances(prev => !prev)
    }

    return (
        <div
            id={divId}
            key={divId}
            className="references-from-a-page"
            onMouseEnter={() => setHover(true)}
            onMouseLeave={() => setHover(false)}
            onClick={() => {
                if (page.id !== titleIds[0])
                    isMobile
                        ? switchPage(page.id)
                        : dispatch(setTitleIds([titleIds[0], page.id]))
            }}
        >
            <div className="toggle-and-title">
                <ToggleCaret
                    margin={true}
                    showCaret={parasToShow.length > 0}
                    on={showInstances}
                    handleClick={(e: any) => {
                        e.stopPropagation()
                        setShowInstances(prev => !prev)
                    }}
                />
                <ReferenceSectionTitle page={page} />
            </div>
            {showInstances ? (
                parasToShow.map((para: PlexusParagraph) => {
                    return (
                        <ReferenceItem
                            query={GraphBuilderLite.cleanPhrase(page.title).join(' ')}
                            para={para}
                            key={para.paragraphId}
                            text={para.text}
                            wordStartIndex={para.wordIndex}
                        />
                    )
                })
            ) : (
                <></>
            )}
        </div>
    )
}

export default ReferencesFromAPage
