import {string} from 'prop-types'
import {Descendant} from 'slate'
import {PlexusPage, PlexusParagraph} from '../../../Algorithms/EntityRecognizer/PageInterfaces'
import GraphBuilderLite, {
    PageMap,
    ParagraphMap,
    PlexusMapOfPages,
    PlexusPagesMap,
} from '../../../Algorithms/GraphBuilding/GraphBuilderLite/GraphBuilderLite'
import {anyCharIsAlphanumeric} from '../../../Algorithms/TextNormalization/TextNormalization'
import {arraysEqual, arrayUnique} from '../../../Miscellaneous/Miscellaneous'
import {timeFunction} from '../../../Testing/NotesTesting'
import {ReferenceElement} from '../../Page/SimplerSlateEditor/EditorContainer/slateConfig'
import {gbl} from '../../PlexusDataContainer'
import {emptySlateValue, shouldShowPage} from '../FilterPages'

export default class FolderLogic {
    static findOutgoers(page: PlexusPage, pagesMap: PlexusPagesMap): PlexusPage[] {
        let outgoerIds: string[] = []
        const outgoers: PageMap = page.outgoers
        if (!outgoers) return []
        const ids = Object.keys(outgoers)
        const filtered = ids.filter(id => id in pagesMap).map(id => pagesMap[id])
        return filtered
    }

    /**
     * Filter an array of sorted pages
     * @param sortedPages
     */
    static filterPagesArrAutomatic(sortedPages: PlexusPage[], pagesMap: PlexusPagesMap) {
        //neighborhood is obj of page ids
        const winningPages: {page: PlexusPage; neighborhood: {[pageKey: string]: true}}[] = []

        //Possibly add page, or don't
        sortedPages.forEach((page: PlexusPage) => {
            if (page.title.includes('vanced')) debugger
            //look at all the selected pages so far, least recent first. Is this one a part of it's group.
            let independent = true
            for (let i = winningPages.length - 1; i >= 0; i--) {
                const winningPage = winningPages[i] //see if page belongs to the selectedPages
                const belongs = page.id in winningPage.neighborhood
                if (belongs) {
                    i = -1 //skips to the end
                    independent = false
                }
            }
            if (independent)
                //if still indpeendnet at this point, it's a winning page
                winningPages.push({
                    page,
                    neighborhood: FolderLogic.findKNeighborhood(
                        page,
                        pagesMap,
                        gbl,
                        true,
                        3
                    ).reduce((neighborhoodMap, nextPage) => {
                        return {...neighborhoodMap, [nextPage.id]: true}
                    }, {}),
                })
        })
        const formattedWinners = winningPages.map(winningGroup => winningGroup.page)
        return formattedWinners
    }
    static findKNeighborhood(
        page: PlexusPage,
        pagesMap: PlexusPagesMap,
        gbl: GraphBuilderLite,
        useManualRelations: boolean = true,
        k: number = 1
    ) {
        let neighborhood = this.findConnectedReferences(page, pagesMap, gbl, useManualRelations)
        let i = 1
        while (i < k) {
            neighborhood = neighborhood.reduce((allPages, nextRoot) => {
                const nextNeighborhood = this.findConnectedReferences(
                    nextRoot,
                    pagesMap,
                    gbl,
                    useManualRelations
                )
                return [...allPages, ...nextNeighborhood, nextRoot]
            }, [])
            i++
        }
        return arrayUnique(neighborhood, (a, b) => a.id === b.id)
    }

    //incomers and outgoers
    static findConnectedReferences(
        page: PlexusPage,
        pagesMap: PlexusPagesMap,
        gbl: GraphBuilderLite,
        useManualRelations: boolean = true
    ) {
        const outgoers = this.findOutgoers(page, pagesMap)
        const incomers = this.findComputedIncomers(page, pagesMap, gbl)
        const children = useManualRelations ? this.findChildren(page, pagesMap) : []
        const parents = useManualRelations ? this.findParents(page, pagesMap) : []

        //eventually get unique from this
        const neighborhoodWithDups = [...outgoers, ...incomers, ...children, ...parents]
        return arrayUnique(neighborhoodWithDups)
    }

    static findChildren(
        page: PlexusPage,
        pagesMap: PlexusMapOfPages
    ): PlexusPage[] | undefined {
        const children = page.children
        if (children) {
            return Object.keys(children)
                .filter(childPageId => !pagesMap || childPageId in pagesMap)
                .map(id => pagesMap[id])
                .sort((a, b) => b.lastUpdated - a.lastUpdated)
        } else return []
    }
    /**
     * Returns id of perfect page
     * Finds unique
     * @param page
     * @param pagesMap
     * @returns
     */
    static findChildrenFromOutgoers(page: PlexusPage, pagesMap: PlexusPagesMap): PlexusPage[] {
        let perfectOutgoerIds: string[] = []

        const outgoers: PageMap = page.outgoers
        if (!outgoers) return []

        //For each page
        Object.entries(outgoers).forEach(([outgoingPageId, sourceParagraphs]) => {
            //for each paragraph of mine that refers to that page
            Object.values(sourceParagraphs).forEach(paragraph => {
                //does that paragraph's text match the page's text
                const refPage = pagesMap[outgoingPageId] as PlexusPage
                if (
                    // !emptySlateValue(refPage.slateValue)
                    refPage
                        ? FolderLogic.paragraphPerfectlyReferencesPage(paragraph, refPage)
                        : false
                )
                    perfectOutgoerIds.push(outgoingPageId)
            })
        })
        //get unique
        const uniqueChildrenIds = [...new Set(perfectOutgoerIds)]

        let perfectOutgoers = uniqueChildrenIds
            .map((id): PlexusPage => pagesMap[id])
            .filter(e => e)

        return perfectOutgoers
    }
    static findComputedIncomers(
        page: PlexusPage,
        pagesMap: PlexusPagesMap,
        gbl: GraphBuilderLite
    ): PlexusPage[] {
        const pagesWhereOccurs = gbl.findWhereOccursByPage(page.title)
        const pageIDsArr = Object.keys(pagesWhereOccurs)
            .filter(e => e !== page.id && e in pagesMap)
            .map(id => pagesMap[id])
        return pageIDsArr
    }

    static findParents(page: PlexusPage, pagesMap: PlexusMapOfPages): PlexusPage[] | undefined {
        const parents = page.parents
        if (parents) {
            return Object.keys(parents)
                .filter(parentPageId => !pagesMap || parentPageId in pagesMap)
                .map(id => pagesMap[id])
        } else return []
    }
    static findIncomers(page: PlexusPage, pagesMap: PlexusPagesMap): PlexusPage[] {
        let perfectIncomerIds: string[] = []
        const incomers: PageMap = page.incomers
        if (!incomers) return []

        //For each page
        Object.entries(incomers).forEach(([incomingPageId, sourceParagraphs]) => {
            //for each paragraph of mine that refers to that page
            Object.values(sourceParagraphs).forEach(paragraph => {
                //does that paragraph's text match the page's text
                if (true || FolderLogic.paragraphPerfectlyReferencesPage(paragraph, page))
                    perfectIncomerIds.push(incomingPageId)
            })
        })
        const uniqueParentIds = [...new Set(perfectIncomerIds)]

        let perfectIncomers = uniqueParentIds
            .map((id): PlexusPage => pagesMap[id])
            .filter(e => e)
        return perfectIncomers
    }

    static paragraphPerfectlyReferencesPage(
        paragraph: PlexusParagraph,
        page: PlexusPage
    ): boolean {
        const paragraphText = paragraph.text
        const refPageText = page.title
        return (
            paragraphText &&
            refPageText &&
            FolderLogic.cleanTextMatch(paragraphText, refPageText)
        )
    }

    //New clean text match function. Doesn't compute clean word until absolutely necessary
    static cleanTextMatch(string1: string, string2): boolean {
        return timeFunction(() => {
            // const decoratedWords = [
            //     string1.split(' ').filter(e => anyCharIsAlphanumeric(e)),
            //     string2.split(' ').filter(e => anyCharIsAlphanumeric(e)),
            // ]
            // if (decoratedWords[0].length != decoratedWords[1].length) return false
            // const cleanWord = GraphBuilderLite.cleanWord
            // loop: for (let i = 0; i < decoratedWords[0].length; i++) {
            //     //If they are equal, most times it'll be exact matches
            //     if (decoratedWords[0][i] == decoratedWords[1][i]) continue loop
            //     else if (cleanWord(decoratedWords[0][i]) === cleanWord(decoratedWords[1][i]))
            //         continue loop
            //     else return false
            // }
            // return true
            return arraysEqual(
                GraphBuilderLite.cleanPhrase(string1),
                GraphBuilderLite.cleanPhrase(string2)
            )
        }, 'clean match call for ' + string1)
    }

    static filterPagesForPageStore(
        pagesArr: PlexusPage[],
        pagesMap: PlexusMapOfPages,
        titleId: string
    ) {
        return pagesArr.filter(page => shouldShowPage(page, titleId, pagesMap))
    }
}
