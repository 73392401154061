import React, {
    useMemo,
    useCallback,
    useRef,
    useEffect,
    useState,
    KeyboardEventHandler,
    useContext,
} from 'react'
import {
    Editor,
    Transforms,
    Range,
    createEditor,
    Descendant,
    Text,
    Node,
    BasePoint,
    Point,
} from 'slate'
import {withHistory} from 'slate-history'
import {
    Slate,
    Editable,
    withReact,
    DefaultElement,
    ReactEditor,
    RenderLeafProps,
    DefaultLeaf,
} from 'slate-react'
import './EditorContainer.css'
import {CustomElement, FormattedText, SlateNoteElement} from './slateConfig'
import Reference from '../SlateElements/ReferenceElement/ReferenceElement'
import Leaf from './Leaf'
import uniqid from 'uniqid'
import ReferenceFinder from './ReferenceInsertion'
import {findLongestEntityPhrase} from '../../../../Algorithms/EntityRecognizer/EnglishEntitiesHelpers/EnglishEntities'
import {useDispatch, useSelector} from 'react-redux'
import {toggleShowStore} from '../../../../Slices/ConceptStoreSlice'

import {selectPage, selectPages, setSetEditorValue} from '../../../../Slices/PagesSlice'
import {
    PageOriginStory,
    PlexusPage,
} from '../../../../Algorithms/EntityRecognizer/PageInterfaces'
import {gbl, simpleFirebaseWriter} from '../../../PlexusDataContainer'
import DetectEntitiesButton from '../../../DetectEntitiesButton/DetectEntitiesButton'
import References from '../../References/References'
import {
    addPage,
    folderShortcut,
    paragraphCharLimit,
    processPage,
} from '../../../../UnifiedMutations/UnifiedMutations'
import Toolbar from '../../SelectionToolbar/Toolbar'
import TopLevelContext from '../../../../Contexts/TopLevelContext'
import {anyCharIsAlphanumeric} from '../../../../Algorithms/TextNormalization/TextNormalization'
import {getBulletPrefix, lineIsBullet, TAB} from './BulletLogic'
import {emptyDesc, emptySlateValue} from '../../../PageStore/FilterPages'
import FolderLogic from '../../../PageStore/FolderLogic/FolderLogic'
import PageChildren from '../../PageChildren/PageChildren'
import PageCloseButton from './PageCloseButton'
import SwapPagesButton from '../../Titles/SwapPagesButton'
import SwapPagesButtonNew from './SwapPagesButtonNew'
import NoteElement from '../SlateElements/NoteElement/NoteElement'
import PinnedNote from '../SlateElements/PinnedNote'
import {makeSlateNoteFromText} from '../../../../BackendDataManagement/Jan2022/FirebaseWriter22'

let prevValueLength = 0
//Make key generation happen specially, globally

const EditorContainer = ({
    pageId,
    givenValue,
    secondary,
}: {
    pageId: string
    givenValue?: any[]
    secondary: boolean
}) => {
    const pages = useSelector(selectPages)
    const page = useMemo(() => {
        return pageId in pages ? pages[pageId] : undefined
    }, [pages, pageId])
    // useSelector(selectPage(pageId))
    const dispatch = useDispatch()
    const {switchPage} = useContext(TopLevelContext)

    const [value, setValue] = useState<SlateNoteElement[]>((): SlateNoteElement[] =>
        page && page.slateValue && page.slateValue.length > 0 && 'type' in page.slateValue[0]
            ? page.slateValue
            : [{type: 'note', children: [{text: ''}], isTitle: true, id: `${pageId}-title`}]
    )

    const [editor] = useState(() =>
        withUniqueIds(withHtml(withReferences(withReact(withHistory(createEditor())))))
    )

    // Define a rendering function based on the element passed to `props`. We use
    // `useCallback` here to memoize the function for subsequent renders.
    const pageHasContent = value && !emptySlateValue(value)

    const renderElement = useCallback(
        props => {
            switch (props.element.type) {
                case 'reference':
                    return <Reference {...props} />
                case 'pinnedNote':
                    return <PinnedNote {...{props, pages}} />
                default:
                    return <NoteElement {...{props, pageHasContent}} />
            }
        },
        [value]
    )

    const renderLeaf = useCallback((props: RenderLeafProps) => {
        const horizontalPadding = props && props.leaf && props.leaf.padded ? '3px' : '0'
        // @ts-ignore

        if (props.leaf.placeholder) {
            return (
                <>
                    <DefaultLeaf {...props} />
                    <span
                        onClick={() => {
                            Transforms.select(editor, Editor.start(editor, []))
                            ReactEditor.focus(editor)
                        }}
                        style={{opacity: 0.3, position: 'absolute', top: '0.5em'}}
                        contentEditable={false}
                    >
                        Untitled
                    </span>
                </>
            )
        }

        return (
            <span
                {...props.attributes}
                style={{paddingRight: horizontalPadding, paddingLeft: horizontalPadding}}
            >
                {props.children}
            </span>
        )
    }, [])

    const handleChange = (newValue: SlateNoteElement[]) => {
        //save to firebase
        const {operations} = editor

        const isAstChange = operations.some(op => 'set_selection' !== op.type)

        // const multipleLines = prevValueLength != newValue.length

        //if a non selection change
        let updatedValue: SlateNoteElement[] = newValue
        if (isAstChange) {
            // prevValueLength = newValue.length
            //if there are two, make sure first is and second is not titular
            //@ts-ignore
            //@ts-ignore
            // const path = operations[operations.length - 1]?.path

            // if (path && path[0] <= 1) {
            //     let newTitleNote, newSecondNote
            //     if (newValue[0]) {
            //         //@ts-ignore
            //         newTitleNote = {...newValue[0], isTitle: true}
            //     }
            //     if (newValue[1]) {
            //         //@ts-ignore
            //         newSecondNote = {...newValue[1], isTitle: false}
            //     }
            //     const valueWithFormatting =
            //         newValue.length > 1
            //             ? [newTitleNote, newSecondNote, ...newValue.slice(2)]
            //             : newValue.length == 1
            //             ? [newTitleNote, ...newValue.slice(1)]
            //             : newValue
            //     updatedValue = valueWithFormatting
            // }

            // Save the value to Local Storage.
            simpleFirebaseWriter.setSlateValue(pageId, newValue)

            if (
                editor &&
                editor.children[0] &&
                page.title !== Node.string(editor.children[0])
            ) {
                simpleFirebaseWriter.setTitle(pageId, Node.string(editor.children[0]))
            }
            //set date last updated
            simpleFirebaseWriter.updateDateLastUpdated(pageId)
        }
        setValue(updatedValue)

        //if no reference insertion, but if there's a new letter typed, make sure it's not part of a reference.
    }
    useEffect(() => {
        // ReferenceFinder.checkForReference(editor)
    }, [value])
    useEffect(() => {
        document.addEventListener('keypress', e => {
            if (e.key === 'π') {
                processPage(page, gbl, simpleFirebaseWriter, setValue, pages, undefined, () => {
                    editor.selection = undefined
                })
            }
        })
    }, [])

    const bulletsEnabled = true
    const bulletDash = ' –  '

    const onKeyDown: KeyboardEventHandler = event => {
        const key: string = event.key
        if (event.key === 'π') {
            event.preventDefault()
            processPage(page, gbl, simpleFirebaseWriter, setValue, pages, undefined, () => {
                editor.selection = undefined
            })

            return
        } else if (event.metaKey && key === 's') {
            event.preventDefault()
            ReactEditor.blur(editor)
            processPage(page, gbl, simpleFirebaseWriter, setValue)
        } else if (event.metaKey && key === ',') {
            event.preventDefault()
            dispatch(toggleShowStore())
        }
        //testing conceptify
        //janky fix for styling shortcuts, for now.
        else if (event.metaKey && ['b', 'i', 'u'].includes(event.key)) {
            event.stopPropagation()
            event.preventDefault()
            const selection = editor.selection
            const highlightedText = selection ? Editor.string(editor, selection) : ''
            if (highlightedText) {
                addPage(highlightedText, gbl, simpleFirebaseWriter).promise.then(() => {
                    editor.selection = undefined
                    //then process this page again
                    processPage(page, gbl, simpleFirebaseWriter, setValue)
                })
            }
        } else if (event.metaKey) {
            if (key.toLocaleLowerCase() === '.') {
                //Get currently selected note
                const selectedNoteIndex = editor.selection
                    ? editor.selection.anchor.path[0]
                    : undefined
                if (!selectedNoteIndex) return // either zero or undefined
                const currentNoteText = Node.string(editor.children[selectedNoteIndex])
                // window.alert(currentNoteText)
                const startPoint: BasePoint = {path: [0], offset: 0}
                editor.selection = {anchor: startPoint, focus: startPoint}
                ReactEditor.blur(editor)
                folderShortcut(
                    currentNoteText,
                    page,
                    simpleFirebaseWriter,
                    gbl,
                    setValue,
                    switchPage
                )
            }
        } else if (key.toLocaleLowerCase() === 'enter') {
            //Deal with bullet dash
            //get current node
            if (bulletsEnabled) {
                const node = Node.get(editor, editor.selection.anchor.path.slice(0, 1))
                const thisNodeString = Node.string(node)

                if (lineIsBullet(thisNodeString)) {
                    event.preventDefault()
                    const bulletPrefix = getBulletPrefix(thisNodeString)
                    const edStart = Editor.start(
                        editor,
                        editor.selection.anchor.path.slice(0, 1)
                    )
                    if (bulletPrefix && thisNodeString.length > bulletPrefix.length) {
                        const noteToInsert: SlateNoteElement = {
                            type: 'note',
                            children: [{text: bulletPrefix}],
                            id: pageId + '-' + uniqid(),
                        }
                        Transforms.insertNodes(editor, [noteToInsert])
                    } else {
                        Transforms.delete(editor, {
                            at: edStart,
                            distance:
                                bulletPrefix === bulletDash ? bulletDash.length : TAB.length,
                            reverse: false,
                            unit: 'character',
                        })
                    }
                }
            }
        } else if (event.key.toLocaleLowerCase() === 'tab') {
            const insertTab = event => {
                // Prevent the ampersand character from being inserted.
                event.preventDefault()
                // Execute the `insertText` method when the event occurs.
                editor.insertText('\t')
            }
            if (bulletsEnabled) {
                event.preventDefault()
                const node = Node.get(editor, editor.selection.anchor.path.slice(0, 1))
                const thisNodeString = Node.string(node)

                if (lineIsBullet(thisNodeString)) {
                    const bulletPrefix = getBulletPrefix(thisNodeString)
                    if (event.shiftKey) {
                        const edStart = Editor.start(
                            editor,
                            editor.selection.anchor.path.slice(0, 1)
                        )

                        Transforms.delete(editor, {
                            at: edStart,
                            distance:
                                bulletPrefix === bulletDash ? bulletDash.length : TAB.length,
                            reverse: false,
                            unit: 'character',
                        })
                    } else {
                        const editorStart = Editor.start(
                            editor,
                            editor.selection.anchor.path.slice(0, 1)
                        )
                        Transforms.insertText(editor, '\t', {at: editorStart})
                    }
                } else insertTab(event)
            } else insertTab(event)
        } else if (event.key === ' ') {
            if (bulletsEnabled) {
                const node = Node.get(editor, editor.selection.anchor.path)
                const thisNodeString = Node.string(node)
                console.log('string', thisNodeString)
                const selectionRight = editor.selection.anchor.offset == 1
                const collapsed = Range.isCollapsed(editor.selection)
                const DASHES = {'-': true, '–': true}

                if (selectionRight && collapsed && thisNodeString[0] in DASHES) {
                    event.preventDefault()
                    Transforms.select(editor, {
                        focus: editor.selection.focus,
                        anchor: Editor.start(editor, editor.selection.anchor.path.slice(0, 2)),
                    })
                    editor.insertText(bulletDash)
                }
            }
        } else if (key.toLocaleLowerCase() === 'backspace') {
            if (bulletsEnabled) {
                const node = Node.get(editor, editor.selection.anchor.path)
                const thisNodeString = Node.string(node)
                const edStart = Editor.start(editor, editor.selection.anchor.path.slice(0, 1))
                if (lineIsBullet(thisNodeString)) {
                    const bulletPrefix = getBulletPrefix(thisNodeString)
                    if (editor.selection.anchor.offset <= bulletPrefix.length) {
                        Transforms.delete(editor, {
                            at: edStart,
                            distance:
                                bulletPrefix === bulletDash ? bulletDash.length : TAB.length,
                            reverse: false,
                            unit: 'character',
                        })
                    }
                }
            }
        }
    }
    useEffect(() => {
        const titleString =
            editor && editor.children[0] ? Node.string(editor.children[0]) : undefined
        if (typeof titleString === 'string' && ['', ' '].includes(titleString))
            ReactEditor.focus(editor)
        else if (
            titleString &&
            editor.children.length == 2 &&
            !anyCharIsAlphanumeric(Node.string(editor.children[1]))
        ) {
            Transforms.select(editor, Editor.end(editor, []))
            ReactEditor.focus(editor)
        }
    }, [])
    const selectionExists: boolean = useMemo(() => typeof editor.selection !== 'undefined', [
        editor.selection,
    ])

    return (
        <div className="editor-container" key={`${pageId}-editor`}>
            {/* conceptify toolbar */}
            <Slate editor={editor} value={value} onChange={handleChange}>
                {selectionExists ? (
                    <Toolbar
                        {...{editor, page, setValue, selectionExists, secondary}}
                        nodeId={pageId}
                    />
                ) : (
                    <></>
                )}

                <div
                    style={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        margin: '.4em',
                    }}
                >
                    {' '}
                    {!secondary ? (
                        <DetectEntitiesButton
                            setValue={setValue}
                            editor={editor}
                            secondary={secondary}
                        />
                    ) : (
                        // <></>
                        <>
                            <PageCloseButton />
                            <SwapPagesButtonNew />
                            <DetectEntitiesButton
                                setValue={setValue}
                                editor={editor}
                                secondary={secondary}
                            />
                        </>
                    )}
                </div>

                <Editable
                    decorate={([node, path]) => {
                        //For untitled label
                        // if (path[0] === 0) debugger
                        if (
                            !Editor.isEditor(node) &&
                            path[0] == 0 &&
                            //@ts-ignore
                            emptyDesc(editor.children[0])
                        ) {
                            return [
                                {
                                    anchor: {path: [0], offset: 0},
                                    focus: {path: [0], offset: 0},
                                    placeholder: true,
                                },
                            ]
                        }

                        return []
                    }}
                    renderElement={renderElement}
                    renderLeaf={renderLeaf}
                    onKeyDown={onKeyDown}
                    // placeholder={'Untitled'}
                />
                <div
                    className="buffer"
                    style={{height: '4vh'}}
                    onClick={() => {
                        Transforms.select(editor, Editor.end(editor, []))
                        ReactEditor.focus(editor)
                    }}
                ></div>

                <PageChildren page={page} />
                <References page={page} gbl={gbl} editor={editor} />
                <div className="buffer" style={{height: '20vh'}}></div>
            </Slate>
        </div>
    )
}

export default EditorContainer

const withHtml = editor => {
    const {insertData, isInline, isVoid} = editor

    editor.isInline = element => {
        return element.type === 'link' ? true : isInline(element)
    }

    editor.isVoid = element => {
        return element.type === 'image' ? true : isVoid(element)
    }

    editor.insertData = data => {
        const html = data.getData('text/html')
        // window.alert('insert')

        // if (html) {
        //     // const parsed = new DOMParser().parseFromString(html, 'text/html')
        //     // const fragment = deserialize(parsed.body)
        //     // Transforms.insertFragment(editor, fragment)
        //     // return
        //     return
        // }

        insertData(data)
    }

    return editor
}

const withReferences = (editor: Editor) => {
    const {isInline, isVoid} = editor
    editor.isInline = (element: CustomElement) => {
        return ['reference', 'pinnedNote'].includes(element.type) ? true : isInline(element)
    }
    editor.isVoid = (element: CustomElement) => {
        //experimenting here
        return element.type === 'pinnedNote' ? true : isVoid(element)
    }

    return editor
}

const withUniqueIds = (editor: Editor) => {
    const {insertBreak} = editor
    const deleteRange = (editor: Editor, range: Range): Point | null => {
        if (Range.isCollapsed(range)) {
            return range.anchor
        } else {
            const [, end] = Range.edges(range)
            const pointRef = Editor.pointRef(editor, end)
            Transforms.delete(editor, {at: range})
            return pointRef.unref()
        }
    }

    editor.insertBreak = (): void => {
        if (editor.selection) {
            insertBreak()
            //ensure a unique id on enter
            Transforms.setNodes(editor, {isTitle: false, id: uniqid() + uniqid()})
        }
    }

    //whenever a new node is added ensure unique id
    // editor.normalizeNode = entry => {
    //     const [node, path] = entry

    //     // If the element is a paragraph, ensure its children are valid.
    //     if (Element.isElement(node) && node.type === 'paragraph') {
    //       for (const [child, childPath] of Node.children(editor, path)) {
    //         if (Element.isElement(child) && !editor.isInline(child)) {
    //           Transforms.unwrapNodes(editor, { at: childPath })
    //           return
    //         }
    //       }
    //     }

    //     // Fall back to the original `normalizeNode` to enforce other constraints.
    //     normalizeNode(entry)
    //   }
    return editor
}
