import {Node} from 'slate'
import {
    PlexusMapOfPages,
    PlexusPagesMap,
} from '../../../Algorithms/GraphBuilding/GraphBuilderLite/GraphBuilderLite'
import {SlateNoteElement} from '../../Page/SimplerSlateEditor/EditorContainer/slateConfig'
import {emptySlateValue} from '../../PageStore/FilterPages'

export const textFromSlateValue = (slateValue: SlateNoteElement[]) => {
    return slateValue.map(note => Node.string(note)).join('\n')
}
export const downloadPages = (pagesMap: PlexusMapOfPages) => {
    // export function saveJSONToText(cy){
    //   let s = cy.elements()

    const s = Object.values(pagesMap)
        .sort((a, b) => {
            return -a.slateValue.length + b.slateValue.length
        })
        .reduce((previousString, nextPage) => {
            return previousString + '\n\n\n' + textFromSlateValue(nextPage.slateValue)
        }, '')
    var file = new Blob([s], {type: 'plain/text'}) //creates new plain text file
    debugger
    var a = document.createElement('a'),
        url = URL.createObjectURL(file)
    a.href = url
    let d = new Date()
    let month = d.getMonth()
    let date = d.getDate()
    let hour = d.getHours()
    let minutes = d.getMinutes()
    a.download = `Plexus Notes_${month + 1}-${date}.txt`
    document.body.appendChild(a)
    a.click()
    setTimeout(function () {
        document.body.removeChild(a)
        window.URL.revokeObjectURL(url)
    }, 0)
}
