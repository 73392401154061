import React from 'react'

const ToggleCaret = ({
    on,
    handleClick,
    showCaret,
    margin,
}: {
    showCaret: boolean
    on: boolean
    handleClick: any
    margin?: boolean
}) => {
    return (
        <div
            id="concept-caret"
            style={{
                visibility: showCaret ? 'visible' : 'hidden',
                fontStyle: 'normal',
                opacity: 0.7,
            }}
            className={on ? ' ninety-degrees' : '' + (margin ? ' referencesCaret' : '')}
            onClick={handleClick}
        >
            ▸
        </div>
    )
}

export default ToggleCaret
