import {FirebaseAuthConsumer} from '@react-firebase/auth'
import React, {useContext, useEffect} from 'react'
import {Route, Redirect, useRouteMatch} from 'react-router-dom'
import AuthContext from '../Contexts/AuthContext'

const PrivateRoute = ({component: Component, ...rest}) => {
    const {user, setUser} = useContext(AuthContext)

    return (
        <Route {...rest} render={props => (user ? <Component /> : <Redirect to="/login" />)} />
    )
}

export default PrivateRoute
