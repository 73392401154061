import {configureStore} from '@reduxjs/toolkit'
import uploadReducer from './Slices/UploadSlice'
import appReducer from './Slices/AppSlice'
import displayReducer from './Slices/DisplaySlice'
import conceptStoreReducer from './Slices/ConceptStoreSlice'
import pagesReducer from './Slices/PagesSlice'

export default configureStore({
    reducer: {
        upload: uploadReducer,
        app: appReducer,
        display: displayReducer,
        conceptStore: conceptStoreReducer,
        pages: pagesReducer,
    },
})
