import React from 'react'
import '../Page.css'
const ClosePageButton = ({onClick, symbol}) => {
    return (
        <div className="closePageButton" onClick={onClick}>
            {symbol ? symbol : '✕'}
        </div>
    )
}

export default ClosePageButton
