import React, {useState} from 'react'
import {Form, Button} from 'react-bootstrap'
import {Link} from 'react-router-dom'
import {auth} from '../Components/App'
import './SignIn.css'

const SignInView = ({onSubmit}) => {
    const [state, setState] = useState({email: '', password: ''})

    const handleChange = e => {
        setState({...state, [e.target.type]: e.target.value})
        console.log(state)
    }
    return (
        <div className="center sign-in-view-container">
            <Form
                onSubmit={evt => {
                    onSubmit(evt, state)
                }}
                className="signInView"
            >
                <Form.Group controlId="formBasicEmail" onChange={handleChange}>
                    <Form.Control className="loginInput" type="email" placeholder="Email" />
                </Form.Group>
                <Form.Group controlId="formBasicPassword" onChange={handleChange}>
                    <Form.Control
                        type="password"
                        className="loginInput"
                        placeholder="Password"
                    />
                </Form.Group>
                <br></br>
                <Button className="enterPlexus" variant="primary" type="submit">
                    <span style={{padding: '1%'}}>Log in</span>
                </Button>
                <br></br>

                {/* send password reset link here */}
                <div
                    className="reset-password-link"
                    onClick={() => {
                        //Ensure there's an email
                        const email = window.prompt(
                            'What email is connected to your Plexus account?',
                            state.email
                        )
                        auth.sendPasswordResetEmail(email)
                            .then(() => {
                                // Password reset email sent!
                                // ..
                            })
                            .catch(error => {
                                const errorCode = error.code
                                const errorMessage = error.message
                                // ..
                            })
                            .finally(() => {
                                window.alert(
                                    "If that account exists, you'll receive an email with a link to reset your password!"
                                )
                            })
                    }}
                    style={{fontSize: '2.8ex', color: 'lightgray', padding: '2pt'}}
                >
                    {' '}
                    Forgot password?{' '}
                </div>
                <Link to={'/'} style={{fontSize: '2.8ex', color: 'lightgray', padding: '2pt'}}>
                    {' '}
                    Back home{' '}
                </Link>
            </Form>
            <br></br>
        </div>
    )
}

export default SignInView
