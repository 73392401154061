import {createSlice} from '@reduxjs/toolkit'
import {Dispatch, SetStateAction} from 'react'
import {Descendant} from 'slate'
import {PlexusPage} from '../Algorithms/EntityRecognizer/PageInterfaces'
import {SlateNoteElement} from '../Components/Page/SimplerSlateEditor/EditorContainer/slateConfig'

const initialState: {
    theirPages: {[id: string]: PlexusPage}
    recentPages: PlexusPage[]
    setEditorValue: Dispatch<SetStateAction<Descendant[]>>
} = {
    theirPages: {},
    recentPages: [],
    setEditorValue: undefined,
}
export const PagesSlice = createSlice({
    name: 'pages',
    initialState,
    reducers: {
        setPages: (state, action) => {
            state.theirPages = action.payload
        },
        setPageSlate: (
            state,
            action: {payload: {pageId: string; value: SlateNoteElement[]}}
        ) => {
            state.theirPages[action.payload.pageId].slateValue = action.payload.value
        },
        setPageFull: (state, action: {payload: {pageId: string; value: PlexusPage}}) => {
            state.theirPages[action.payload.pageId] = action.payload.value
        },
        setRecentPages: (state, action: {payload: {value: PlexusPage[]}}) => {
            state.recentPages = action.payload.value
        },
        addRecentPage: (state, action: {payload: {value: PlexusPage}}) => {
            state.recentPages = [action.payload.value, ...state.recentPages]
        },
        deleteRecentPage: (state, action: {payload: {index: number}}) => {
            state.recentPages.splice(action.payload.index, 1)
        },
        deletePage: (state, action: {payload: {pageId: string}}) => {
            delete state.theirPages[action.payload.pageId]
        },
        setSetEditorValue: (state, action: {payload}) => {
            state.setEditorValue = action.payload
        },
    },
})

export const {
    setPages,
    setPageSlate,
    setPageFull,
    deletePage,
    setSetEditorValue,
    setRecentPages,
    addRecentPage,
    deleteRecentPage,
} = PagesSlice.actions
export const selectPage = (pageId: string) => state => state.pages.theirPages[pageId]
export const selectPages = (state): {[id: string]: PlexusPage} => state.pages.theirPages
export const selectSetEditorValue = (state): Dispatch<SetStateAction<Descendant[]>> =>
    state.pages.setEditorValue

export default PagesSlice.reducer
