import React from 'react'
import {useDispatch, useSelector} from 'react-redux'
import {selectTitleIds, setTitleIds} from '../../../../Slices/AppSlice'
import './PageCloseButton.css'

const PageCloseButton = () => {
    const dispatch = useDispatch()
    const titleId = useSelector(selectTitleIds)[0]
    return (
        <div className="page-close-button" onClick={() => dispatch(setTitleIds([titleId]))}>
            ✕
        </div>
    )
}

export default PageCloseButton
