// @flow strict

import {userInfo} from 'os'
import * as React from 'react'
import {useDispatch, useSelector} from 'react-redux'
import {useFocused, useSelected} from 'slate-react'
import {PageOriginStory} from '../../../../../Algorithms/EntityRecognizer/PageInterfaces'
import GraphBuilderLite from '../../../../../Algorithms/GraphBuilding/GraphBuilderLite/GraphBuilderLite'
import TopLevelContext from '../../../../../Contexts/TopLevelContext'
import {arraysEqual} from '../../../../../Miscellaneous/Miscellaneous'
import {selectTitleIds, setTitleIds} from '../../../../../Slices/AppSlice'
import {selectPages} from '../../../../../Slices/PagesSlice'
import {addPageAndFocus} from '../../../../../UnifiedMutations/UnifiedMutations'
import {analytics, isMobile} from '../../../../App'
import {emptySlateValue} from '../../../../PageStore/FilterPages'
import {gbl, simpleFirebaseWriter} from '../../../../PlexusDataContainer'
import {ReferenceElement} from '../../EditorContainer/slateConfig'
import './ReferenceElement.css'

function Reference({
    attributes,
    children,
    element,
}: {
    attributes: any
    children: any[]
    element: ReferenceElement
}) {
    const dispatch = useDispatch()
    const selected = useSelected()
    const focused = useFocused()
    const {switchPage} = React.useContext(TopLevelContext)
    const pages = useSelector(selectPages)
    const titleIds = useSelector(selectTitleIds)
    const titleId = titleIds[0]
    const secondaryTitleId = titleIds[1]
    const notTitle = !element.refPageId || element.refPageId !== titleId
    const styleSelected = selected && focused && notTitle
    const [clickedAlready, setClickedAlready] = React.useState(false)
    const whereElseOccurs = React.useMemo(() => {
        if (element.referenceText === 'versation') debugger
        const result = gbl.findWhereOccurs(element.referenceText)
        const otherPlaces = (result ? result : []).filter(
            para => para.paragraphId !== element.paragraphId && (!pages || para.pageId in pages)
        )
        return otherPlaces
    }, [Object.keys(pages).length])
    const perfectPageId: string | undefined = React.useMemo(() => {
        const cleanText = GraphBuilderLite.cleanPhrase
        if (!whereElseOccurs) return
        const perfectPages = whereElseOccurs
            .filter(
                para =>
                    para.paragraphIndex == 0 &&
                    arraysEqual(
                        para.cleanWords ? para.cleanWords : cleanText(para.text),
                        cleanText(element.referenceText)
                    )
            )
            .map(e => e.pageId)
        if (perfectPages.length > 0) {
            console.assert(perfectPages.length == 1)
            return perfectPages[0]
        } else return
    }, [whereElseOccurs])

    const perfectPageHasContent = React.useMemo(() => {
        if (!perfectPageId) return false
        else {
            return !emptySlateValue(pages[perfectPageId].slateValue)
        }
    }, [perfectPageId])

    const repeated = React.useMemo(() => whereElseOccurs && whereElseOccurs.length > 0, [
        whereElseOccurs,
    ])
    const clickable = repeated && notTitle

    const makeNewPageAndFocus = () =>
        addPageAndFocus(
            element.referenceText,
            gbl,
            simpleFirebaseWriter,
            showReferencePage,
            PageOriginStory.ACCEPTED
        )

    const [flareClass, setFlare] = React.useState(' flare')
    React.useEffect(() => {
        //remove flare after first second on rendering
        setTimeout(() => {
            setFlare('')
        }, 600)
    }, [])

    const showReferencePage = (newSecondaryId: string) => {
        if (isMobile) {
            switchPage(newSecondaryId)
        } else dispatch(setTitleIds([titleId, newSecondaryId]))
    }
    return (
        <span
            key={`${element.refPageId}-${element.referenceText}-reference`}
            className={
                'referenceElement' +
                (repeated ? ' repeats' : '') +
                (clickable ? ' clickable' : '') +
                (!notTitle ? ' title' : '') +
                (!perfectPageHasContent ? ' unconfirmed' : '') +
                flareClass
            }
            {...attributes}
            // contentEditable={false}
            style={{
                textDecoration: styleSelected ? 'none' : '',
                backgroundColor: styleSelected ? 'var(--background-purple)' : '',
            }}
            onClick={() => {
                analytics.logEvent('reference_click', {val: element.referenceText})

                const id = element.refPageId
                if (repeated) {
                    if (id && id in pages) {
                        showReferencePage(id)
                    }
                    //otherwise, find the id of the correct page if it exists
                    else if (perfectPageId && perfectPageId in pages) {
                        showReferencePage(perfectPageId)
                        // switchPage(perfectPageId)
                    } else {
                        makeNewPageAndFocus()
                    }
                }
            }}
            onDoubleClick={e => {
                if (!repeated) makeNewPageAndFocus()
            }}
        >
            {/* {element.referenceText} */}
            {children}
        </span>
    )
}

const mapEquals = (a: any, b: any, modifier: Function): boolean => {
    return modifier(a) === modifier(b)
}

export default Reference
