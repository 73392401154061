import React, {useRef, useEffect, useState, useContext, useMemo} from 'react'
import {Slate, ReactEditor, useSlate, useEditor, useSelected} from 'slate-react'
import {Editor, Transforms, Text, Descendant} from 'slate'

import {Button, Icon, Menu, Portal} from './components'
import {Range} from 'slate'
import './Toolbar.css'
import ConceptifyButtonInternal from './ConceptifyButtonInternal'
import {isAlphaNumeric} from '../../../Algorithms/TextNormalization/TextNormalization'
import {PlexusPage} from '../../../Algorithms/EntityRecognizer/PageInterfaces'

const toggleformat = (editor, format) => {
    const isActive = isFormatActive(editor, format)
    Transforms.setNodes(
        editor,
        {[format]: isActive ? null : true},
        {match: Text.isText, split: true}
    )
}

const isFormatActive = (editor, format) => {
    const [match] = Editor.nodes(editor, {
        match: n => n[format] === true,
        mode: 'all',
    })
    return !!match
}

const Toolbar = ({
    nodeId,
    page,
    setValue,
    selectionExists,
    secondary,
}: // editor,
{
    // editor: Editor
    page: PlexusPage
    setValue
    nodeId: string
    selectionExists: boolean
    secondary: boolean
}) => {
    const ref = useRef<HTMLDivElement | null>()
    const editor = useSlate()
    const selection = editor.selection
    const selected = useSelected()

    //update position of toolbar on scroll
    const [scrollCounter, setScrollCounter] = useState(0)

    const selectedPhrase = useMemo(
        () => (selection ? Editor.string(editor, selection) : undefined),
        [selection, editor]
    )

    const setElementPosition = () => {
        const el = ref.current
        if (!el) {
            return
        }

        if (
            !selection ||
            !ReactEditor.isFocused(editor) ||
            Range.isCollapsed(selection) ||
            Editor.string(editor, selection) === ''
        ) {
            el.removeAttribute('style')
            return
        }

        const start = Range.start(selection)
        const end = Range.end(selection)
        const letterBefore =
            start.offset > 0
                ? Editor.string(editor, {
                      anchor: {offset: start.offset - 1, path: start.path},
                      focus: start,
                  })
                : undefined
        const letterAfter = Editor.string(editor, {
            anchor: end,
            focus: {offset: end.offset + 1, path: end.path},
        })
        const alphaNumericChecks = {
            before: letterBefore && isAlphaNumeric(letterBefore, false),
            after: letterAfter && isAlphaNumeric(letterAfter, false),
        }

        //another condition to disable conceptify
        if (alphaNumericChecks.before || alphaNumericChecks.after) {
            el.removeAttribute('style')
            return
        }

        // if (Range.start(selection).offset > 0 > Editor.string(editor, selection))
        const domSelection = window.getSelection()
        const domRange = domSelection.getRangeAt(0)
        const rect = domRange.getBoundingClientRect()

        el.style.opacity = '1'
        el.style.top = `${rect.top + window.pageYOffset - el.offsetHeight - 6}px`
        el.style.left = `${
            rect.left + window.pageXOffset - el.offsetWidth / 2 + rect.width / 2
        }px`
    }
    const hideToolbar = () => {
        const el = ref.current
        if (!el) {
            return
        }
        el.removeAttribute('style')
    }
    useEffect(setElementPosition, [selection, editor])
    return (
        <Portal
            key={'toolbar' + (selection ? selection.anchor.offset : 'sdf') + '-' + '-' + nodeId}
        >
            <Menu ref={ref} className="toolbar">
                <ConceptifyButtonInternal
                    key={'-' + (selection ? selection.focus.offset : -1) + '-' + nodeId}
                    phrase={selectedPhrase}
                    {...{
                        hideToolbar,
                        editor,
                        page,
                        setValue,
                        secondary,
                    }}
                />
                {/* </div> */}
                {/* <FormatButton
                    format="deconcept"
                    icon="formatDeconceptify"
                    {...{selectedPhrase, blurSelf}}
                /> */}
            </Menu>
        </Portal>
    )
}

export default Toolbar
