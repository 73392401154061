import NodeToDisplay from '../Interfaces/NodeToDisplay'

export function concatName(prevVal, ele, i, eles) {
    if (prevVal) {
        return prevVal + ', ' + ele.data('name')
    } else {
        return ele.data('name')
    }
}

export const arrAvg = arr => arr.reduce((a, b) => a + b, 0) / arr.length
export function hasWhiteSpace(s) {
    return s.indexOf(' ') >= 0
}
export function collectionToString(eles) {
    let s = ''
    eles.forEach(function (ele) {
        s += ele.data('name') + ', '
    })
    return s
}
// const collectionToString = () => {
//   let s = ""
//   let eles = cyRef.nodes();
//   if(eles){
//       eles.forEach(function(ele){
//           s+= ele.data('name') + ", "
//       });
// //   }
//   console.log(s)
//   return s;
// }

//Convert object to array with corresponding indices:
// export function toArray(obj, range){
//   let arr = []
//   let numberKeys =
//   return Object.keys(obj).map(key => {Number(key)}).
// }
export function numberKeyDown(obj) {
    for (let i = 48; i <= 57; i++) {
        if (obj[i]) {
            return [true, i]
        }
    }
    return [false]
}

const unique = (value, index, self) => {
    return self.indexOf(value) === index
}
// export const getUniques = (arr) => {
//   console.log('uniques', arr.filter(unique))
//   return arr.filter(unique);

// }
// export const getUniques = (arr, titleNode?) => {
//     let result = []
//     arr.forEach(ele => {
//         //check if includes
//         let includes = false
//         result.forEach(ele2 => {
//             if (ele2.id() === ele.id()) includes = true
//         })
//         if (!includes && ele.id() !== titleNode?.id()) result.push(ele)
//     })
//     //console.log('uniques',result.map(ele=>ele.data('name')));
//     return result
// }

export function IsJsonString(str) {
    let jsonString = '{"1": ' + str + '}'
    try {
        JSON.parse(str)
    } catch (e) {
        return false
    }
    return true
}

export const shiftLettersList = () => {
    let i = 97
    return [...Array(26)]
        .reduce(a => a + String.fromCharCode(i++), '')
        .split('')
        .map(letter => `shift+${letter}`)
}

/**
 * Currently causes an infinite loop
 * @param word
 * @param thisStr
 * @param thatStr cannot include any of the same characters as thisStr
 */
export const replaceAll = (word: string, thisStr: string, thatStr: string): string => {
    if (thatStr.includes(thisStr)) {
        console.error(
            'cannot include the replacement target in the replacer string',
            thisStr,
            thatStr
        )
        return word
    }
    let newWord: string = word
    while (newWord.replace(thisStr, thatStr) !== newWord) {
        newWord = newWord.replace(thisStr, thatStr)
    }
    return newWord
}

export function arraysEqual(a, b) {
    if (a === b) return true
    if (a == null || b == null) return false
    if (a.length !== b.length) return false

    // If you don't care about the order of the elements inside
    // the array, you should sort both arrays here.
    // Please note that calling sort on an array will modify that array.
    // you might want to clone your array first.

    for (var i = 0; i < a.length; ++i) {
        if (a[i] !== b[i]) return false
    }
    return true
}
export function insertIntoSortedArray<T>(
    arr: T[],
    item: T,
    lessThanExistingEle: (existingEle: T, item: T) => boolean
): T[] {
    let indexToInsert: number
    loop: for (let index = 0; index < arr.length; index++) {
        //if item is less than than the existing element
        if (lessThanExistingEle(arr[index], item)) {
            indexToInsert = index
            break loop //to break for each loop
        }
    }

    if (indexToInsert == undefined) indexToInsert = arr.length
    arr.splice(indexToInsert, 0, item)
    return arr
}
export const onlyValueIs = (val: number, arr: number[]): boolean => {
    for (let i = 0; i < arr.length; i++) {
        if (arr[i] != val) return false
    }
    return true
}

//Determines if a string contains one of the substrings in a given arr
export const stringContainsOneOf = (str: string, arr: string[]) => {
    let contains = false
    arr.forEach(stopWord => {
        if (str.includes(stopWord)) contains = true
    })
    return contains
}

export function arrayUnique(array, equals: Function = (a, b) => a === b) {
    var a = array.concat()
    for (var i = 0; i < a.length; ++i) {
        for (var j = i + 1; j < a.length; ++j) {
            if (equals(a[i], a[j])) a.splice(j--, 1)
        }
    }

    return a
}
export const combineParallelArrays = (arr1: any[], arr2: any[]) => {
    return arr1.map((a, i) => [a, arr2[i]])
}
