import React, {useState} from 'react'
import {Node} from 'slate'
import {paragraphCharLimit} from '../../../../../UnifiedMutations/UnifiedMutations'
import TripleDotMenu from '../../../../TripleDotMenu/TripleDotMenu'
import {SlateNoteElement} from '../../EditorContainer/slateConfig'
import './NoteElement.css'

const NoteElement = ({props, pageHasContent}) => {
    const overLimit = Node.string(props.element).length > paragraphCharLimit
    const [hover, setHover] = useState(false)
    return (
        <div
            onMouseEnter={() => setHover(true)}
            onMouseLeave={() => setHover(false)}
            className={'slate-paragraph' + (props.element.isTitle ? ' title' : '')}
            style={{
                backgroundColor: overLimit ? 'rgb(242, 242, 242)' : '',
            }}
            {...props.attributes}
        >
            {/* <TripleDotMenu
                hover={hover}
                paragraphId={(props.element as SlateNoteElement).id}
                pageId={props.element.pageId}
            /> */}
            {props.children}
        </div>
    )
}

export default NoteElement
