import {replaceAll} from '../../Miscellaneous/Miscellaneous'
import {
    PageOriginStory,
    PlexusPage,
    PlexusParagraph,
} from '../../Algorithms/EntityRecognizer/PageInterfaces'
import uniqid from 'uniqid'
import {Descendant} from 'slate'
import {PageMap} from '../../Algorithms/GraphBuilding/GraphBuilderLite/GraphBuilderLite'
import {
    PinnedNoteElement,
    ReferenceElement,
    SlateNoteElement,
} from '../../Components/Page/SimplerSlateEditor/EditorContainer/slateConfig'
import FolderLogic from '../../Components/PageStore/FolderLogic/FolderLogic'
import {analytics} from '../../Components/App'

export const FOLDER_TRANSFER_PATH = 'foldersTransferOccurred'

/**
 * Look at database.rules.json for the firebase rules schema we're using.
 * Whenever we build + deploy the version of Plexus in this codebase, those database rules get pushed to firebase.
 * 
 * The biggest question here: why are pages stored in this big pool of pages across all plexus accounts, not distinguished by person?
Because that's how Firebase tends to recommend you model social media apps, in its examples of real time database usage.
How does privacy/security/auth work when all pages are in the same storage pool?
1. It still works. Firebase's Real-Time database lets you set permissions per JSON node, so even though all pages are in the same section, you can still customize security rules (and I did customize security rules) for each particular page.
How the rules are customized: each particular pages's rules depend on its authors.
2. The security rules are set in the Firebase Console > Realtime Database > Rules. 
The reference document firebase provides for rules: 
https://firebase.google.com/docs/database/security
Why model after social media apps?
Because interpersonal connections will be the biggest value proposition of Plexus.
You still might think it's questionable to make an architecture decision that prioritizes sharing at this
 */
class FirebaseWriter22 {
    rootFirebaseReference
    auth_id: string
    constructor(ref: any, auth_id: string) {
        this.rootFirebaseReference = ref
        this.auth_id = auth_id
    }

    deleteAllPersonData() {
        this.rootFirebaseReference.child(`people/${this.auth_id}`).remove()
        this.rootFirebaseReference
            .child('pages')
            .orderByChild('author_id')
            .equalTo(this.auth_id)
            .once('value', snap => {
                if (snap.exists() && snap.length > 0) {
                    snap.forEach(e => {
                        this.rootFirebaseReference.pages('pages/' + e.key).remove()
                    })
                }
            })
    }
    initialize(json: Object, callback: (error: Error | null) => any) {
        // this.firebaseReference.child('privateWeb/pageInfo/').set({hello: 1})
        this.rootFirebaseReference
            .set(json)
            .then(callback)
            .catch(function (error) {
                console.error(error)
            })
    }

    /**
     * There are six characters not allowed in firebase keys, per https://stackoverflow.com/questions/20363052/cant-post-data-containing-in-a-key-to-firebase/20363114#20363114
     * 1. .
     * 2. $
     * 3. [
     * 4. ]
     * 5. #
     * 6. /
     * @param word
     */
    static replacementMap: {[invalidCharacter: string]: string} = {
        '.': '-*-PERIOD-*-4815162342-*-',
        $: '-*-DOLLAR-SIGN-*-4815162342-*-',
        '[': '-*-LEFT-SQUARE-BRACKET-*-4815162342-*-',
        ']': '-*-RIGHT-SQUARE-BRACKET-*-4815162342-*-',
        '#': '-*-HASHTAG-*-4815162342-*-',
        '/': '-*-SLASH-*-4815162342-*-',
    }
    static makeValidKey(word: string): string {
        let validKey: string = word
        Object.entries(this.replacementMap).forEach(entry => {
            validKey = replaceAll(validKey, entry[0], entry[1])
        })
        return validKey
    }
    /**
     * Reverse direction from the above function
     * @param wordKey
     */
    static getWordFromKey(wordKey: string) {
        let ogWord: string = wordKey
        Object.entries(this.replacementMap).forEach(entry => {
            ogWord = replaceAll(ogWord, entry[1], entry[0])
        })
        return ogWord
    }

    // wipeDataBase() {
    //     if (window.confirm("Are you sure you'd like to wipe all the data in this account?")) {
    //         this.rootFirebaseReference.remove()
    //     }
    // }

    addPageAndSelect = (
        title: string = '',
        switchPage: (id: string) => void,
        origin: PageOriginStory
    ) => {
        const result = this.addPage(title, origin)
        result.promise.then(() => switchPage(result.page.id))
        return result
    }

    /**
     * Adds a page bi-directionally.
     * @param title
     */
    addPage = (
        title: string,
        originStory: PageOriginStory = PageOriginStory.MANUAL,
        providedSlateValue?: SlateNoteElement[],
        providedId?: string,
        givenTimestamp?: number
    ): {promise; page: PlexusPage} => {
        analytics.logEvent('new_page', {originStory, author: this.auth_id})
        const id = providedId ? providedId : uniqid()
        const slateValue: SlateNoteElement[] = providedSlateValue
            ? providedSlateValue
            : [{type: 'note', children: [{text: title}], isTitle: true, id: id + '-title'}]
        //always include a second line too
        slateValue.push({type: 'note', children: [{text: ''}], id: uniqid(), isTitle: false})
        const json: PlexusPage = {
            author_id: this.auth_id,
            title,
            id,
            slateValue,
            incomers: {},
            outgoers: {},
            originStory,
            lastUpdated: givenTimestamp ? givenTimestamp : Date.now(),
        }
        //need to set pages first, before backward reference from the person's firebase node
        const promise = this.rootFirebaseReference
            .child('pages/' + json.id)
            .set(json)
            .then(() => {
                this.rootFirebaseReference
                    .child(`people/${this.auth_id}/pages/${json.id}`)
                    .set(json.id)
            })
        return {promise, page: json}
    }

    markAsTransferred() {
        this.rootFirebaseReference.child('people/' + this.auth_id + '/transferred22').set(true)
    }
    markFoldersAsTransferred() {
        this.rootFirebaseReference
            .child('people/' + this.auth_id + '/' + FOLDER_TRANSFER_PATH)
            .set(true)
    }

    //TODO make sure this function draws outgoers correctly, also incomers--why not?
    ensureEdgesAreDrawnToPage(referencePageId: string, whereItRepeats: PlexusParagraph[]) {
        //Set up references properly
        //first: set up the incomers for the reference page page
        let updates = {}
        whereItRepeats.forEach((incomingRefParagraph: PlexusParagraph) => {
            //get the path to this incomer
            //don't link to self
            if (
                incomingRefParagraph.pageId !== referencePageId ||
                incomingRefParagraph.paragraphIndex != 0
            ) {
                updates[
                    `pages/${referencePageId}/incomers/${incomingRefParagraph.pageId}/${incomingRefParagraph.paragraphId}`
                ] = incomingRefParagraph
                //then also add outgoer
                updates[
                    `pages/${incomingRefParagraph.pageId}/outgoers/${referencePageId}/${incomingRefParagraph.paragraphId}`
                ] = incomingRefParagraph
            }
        })

        //incomerUpdates are set, push em
        this.rootFirebaseReference.update(updates)
    }

    addFolderRelation(parentPageId: string, childPageId: string) {
        let updates = {}
        updates[`pages/${parentPageId}/children/${childPageId}`] = true
        //then also add outgoer
        updates[`pages/${childPageId}/parents/${parentPageId}`] = true

        //incomerUpdates are set, push em
        return this.rootFirebaseReference.update(updates)
    }
    deleteFolderRelation(parentPageId: string, childPageId: string) {
        this.rootFirebaseReference
            .child(`pages/${parentPageId}/children/${childPageId}`)
            .remove()
            .then(() => {
                this.rootFirebaseReference
                    .child(`pages/${childPageId}/parents/${parentPageId}`)
                    .remove()
            })
    }

    dragAndDropNew(childPageId: string, oldParentPageId: string, newParentPageId: string) {
        if (childPageId) {
            //delete old folder relation
            if (oldParentPageId) {
                this.deleteFolderRelation(oldParentPageId, childPageId)
            }
            if (newParentPageId) {
                this.addFolderRelation(newParentPageId, childPageId)
            }
        }
    }

    // /**
    //  * Add text and make reference connection
    //  * @param sourceTitleParagraph
    //  * @param targetPage
    //  */
    // putOnePageUnderAnother(parentPage: PlexusPage, childPage: PlexusPage) {
    //     if (parentPage.id !== childPage.id) {
    //         const newChild = this.appendChildTitleToSlateValue(parentPage, childPage)
    //         this.makeReference(
    //             {
    //                 pageId: parentPage.id,
    //                 paragraphId: newChild.id,
    //                 text: childPage.title,
    //                 paragraphIndex: 1,
    //             } as PlexusParagraph,
    //             childPage
    //         )
    //     }
    // }

    newSlateValueWithAppendedLine(parentPage: PlexusPage, childPage: PlexusPage) {
        const oldSlateValue: SlateNoteElement[] = parentPage.slateValue
        if (oldSlateValue.length <= 0) return

        const newChild = makeSlateNoteElementWithReference(
            childPage.title,
            childPage.id,
            1,
            parentPage.id
        )

        const newSlateValue: SlateNoteElement[] = [
            oldSlateValue[0],
            newChild,
            ...oldSlateValue.slice(1),
        ]
        return {newChild, newSlateValue}
    }

    /**
     * Makes a reference connection between two pages, possibly a page and itself.
     * @param sourcePageId
     * @param targetPageId
     */
    makeReference(sourceParagraph: PlexusParagraph, targetPage: PlexusPage) {
        //get the path to this incomer
        //don't link to self
        const isPerfect = FolderLogic.paragraphPerfectlyReferencesPage(
            sourceParagraph,
            targetPage
        )
        //Firebase
        if (sourceParagraph.pageId !== targetPage.id || sourceParagraph.paragraphIndex != 0) {
            let updates = {}

            updates[
                `pages/${targetPage.id}/incomers/${sourceParagraph.pageId}/${sourceParagraph.paragraphId}`
            ] = {...sourceParagraph, isPerfect}
            //then also add outgoer
            updates[
                `pages/${sourceParagraph.pageId}/outgoers/${targetPage.id}/${sourceParagraph.paragraphId}`
            ] = {...sourceParagraph, isPerfect}

            //incomerUpdates are set, push em
            return this.rootFirebaseReference
                .update(updates)
                .then(() => console.log('make reference update was a success'))
                .catch(e => console.error('make reference update failure: ' + e))
        }
    }

    //Deprecated! in favor of reprocessing the page itself. Will deal with "Easier just to delete..." issue described below.
    //Deletes a reference edge in both direction
    //Easier just to delete the whole reference to that page. Hopefully doesn't mess up too much shit
    deleteReferenceEdge(
        sourceParagraph: PlexusParagraph,
        referencedPageId: string
    ): Promise<any> {
        //Delete the outgoer from the page that contains the source paragraph
        return this.rootFirebaseReference
            .child('pages/' + sourceParagraph.pageId + '/outgoers/' + referencedPageId)
            .remove()
            .then(() => {
                this.rootFirebaseReference
                    .child('pages/' + referencedPageId + '/incomers/' + sourceParagraph.pageId)
                    .remove()
            })
        //Delete the incomer from the referencedPageId
    }

    resetPage(pageId: string, actuallyDeletePage: boolean = true) {
        //1. Delete this page from all incomers' and outgoers' edge-records
        const root = this.rootFirebaseReference
        const pagePath = 'pages/' + pageId + '/'
        const outgoersRef = root.child(pagePath + 'outgoers')
        return (
            outgoersRef
                //Delete from all outgoers' "incomers" data
                .once('value', dataSnap => {
                    const val = dataSnap.val()
                    //should contain all outgoers, delete from relevant locations in firebase
                    if (val) {
                        const outgoerPageIds = Object.keys(val)
                        outgoerPageIds.forEach(otherPageId => {
                            this.rootFirebaseReference
                                .child('pages/' + otherPageId + '/incomers/' + pageId)
                                .remove()
                        })
                    }
                })
                //Delete from all incomers' "outgoers" data
                .then(() => {
                    //Only delete incomers to this page if this page is actually being deleted itself.
                    //  Otherwise, it's probably being used in order to reset the page before text processing, and incomers aren't affected by text
                    //processing except for title's processing, but that's different? Can fix this soon.
                    //TODO eventually: write a function to recompute incomers based on title modifications
                    if (actuallyDeletePage) {
                        const incomersRef = root.child(pagePath + 'incomers')
                        incomersRef.once('value', dataSnap => {
                            const val = dataSnap.val()
                            if (val) {
                                const incomerPageIds = Object.keys(val)
                                incomerPageIds.forEach(otherPageId => {
                                    this.rootFirebaseReference
                                        .child('pages/' + otherPageId + '/outgoers/' + pageId)
                                        .remove()
                                })
                            }
                        })
                    }
                })

                //Delete this page directly now
                //Note: this logic won't actually occur after the previous, just after the first thing.
                .then(() => {
                    //Check if intention is to actually delete the page as well
                    if (actuallyDeletePage) {
                        //then directly, from two locations
                        this.rootFirebaseReference.child('pages/' + pageId).remove()
                        this.rootFirebaseReference
                            .child(`people/${this.auth_id}/pages/${pageId}`)
                            .remove()
                    }
                    //Otherwise, still clear it's outgoers (and maybe incomers eventually)
                    else {
                        this.rootFirebaseReference
                            .child('pages/' + pageId + '/outgoers')
                            .remove()
                    }
                })
        )
    }

    setSlateValue = (
        titleId: string,
        value: Descendant[],
        resolve?: Function,
        reject?: Function
    ): Promise<any> => {
        return this.rootFirebaseReference
            .child('pages/' + titleId + '/slateValue')
            .set(value)
            .then(() => {
                if (resolve) resolve()
            })
            .catch(e => {
                console.error(e)
                if (reject) reject()
            })
    }

    //DEFUNCT: only call the first part now.
    // deleteLinesFromSlateValue = (
    //     titleId: string,
    //     oldSlateValue: SlateNoteElement[],
    //     defunctNoteIndices: number[]
    // ) => {
    //     const {newSlateValue, deletedNotes} = this.newSlateValueWithDeletedLines(
    //         defunctNoteIndices,
    //         oldSlateValue
    //     )
    //     const promise = this.setSlateValue(titleId, newSlateValue)
    //         .then(() => {
    //             debugger
    //         })
    //         .catch(e => console.log('Setting on delete causes an error:: ' + e))
    //     return {newSlateValue, deletedNotes, promise}
    // }

    /**
     * assuming defunct note indices are in order of ascending indices
     * @param defunctNoteIndices
     * @param oldSlateValue
     * @returns
     */
    newSlateValueWithDeletedLines = (
        defunctNoteIndices: number[],
        oldSlateValue: SlateNoteElement[]
    ): {newSlateValue: SlateNoteElement[]; deletedNotes: SlateNoteElement[]} => {
        let newSlateValue = [...oldSlateValue]
        let deletedNotes: SlateNoteElement[] = []
        //in ascending order, means add back once delete
        defunctNoteIndices.forEach(defunctIndex => {
            if (defunctIndex < newSlateValue.length && newSlateValue[defunctIndex]) {
                deletedNotes.push({...newSlateValue[defunctIndex], index: defunctIndex})
                newSlateValue[defunctIndex] = undefined
            }
        })
        //make sure each value exists
        newSlateValue = newSlateValue.filter(e => e)

        return {newSlateValue, deletedNotes}
    }
    setTitle = (titleId: string, titleText: string) => {
        this.rootFirebaseReference.child('pages/' + titleId + '/title').set(titleText)
    }
    setWord = (cleanedWord: string, value: PageMap) => {
        //going to have to serialize the word for firebase
        const validKey = FirebaseWriter22.makeValidKey(cleanedWord)
        //assuming firebase automaticallly makes keys along the path (eg dictionary) if don't already exist
        return this.rootFirebaseReference
            .child(`people/${this.auth_id}/dictionary/${validKey}`)
            .set(value)
    }

    deletePageWord = (cleanedWord: string, pageId: string, paragraphId: string) => {
        this.setPageWord(cleanedWord, pageId, paragraphId, null)
    }
    setPageWord = (
        cleanedWord: string,
        pageId: string,
        paragraphId: string,
        index: number | null = -1
    ) => {
        //going to have to serialize the word for firebase
        const validKey = FirebaseWriter22.makeValidKey(cleanedWord)
        //assuming firebase automaticallly makes keys along the path (eg dictionary) if don't already exist
        return this.rootFirebaseReference
            .child(`people/${this.auth_id}/pages/${pageId}/${paragraphId}/${validKey}`)
            .set(index)
    }
    deletePageWords = (pageId: string) => {
        //going to have to serialize the word for firebase
        //assuming firebase automaticallly makes keys along the path (eg dictionary) if don't already exist
        return this.rootFirebaseReference.child(`people/${this.auth_id}/pages/`).remove()
    }

    updateDateLastUpdated = (pageId: string) => {
        this.rootFirebaseReference.child(`pages/${pageId}/lastUpdated`).set(Date.now())
    }
}

export const makeSlateNoteFromText = (text: string): SlateNoteElement => {
    //creating a new id here, fine to do
    let newNoteId = uniqid()
    const newChild: SlateNoteElement = {
        type: 'note',
        //consists of one reference
        children: [{text}],
        id: newNoteId,
    }
    return newChild
}

export const makePinnedNoteFromParagraph = (paragraph: PlexusParagraph): PinnedNoteElement => {
    //creating a new id here, fine to do
    let newNoteId = uniqid()
    const newChild: PinnedNoteElement = {
        type: 'pinnedNote',
        //consists of one reference
        children: [{text: paragraph.text}, {text: ''}],
        paragraph,
        id: newNoteId,
    }
    return newChild
}

export const makeSlateNoteElementWithReference = (
    text: string,
    refPageId: string,
    paragraphIndex: number,
    pageId: string
): SlateNoteElement => {
    //creating a new id here, fine to do
    let newNoteId = refPageId + '-reference-' + uniqid()
    const newChild: SlateNoteElement = {
        type: 'note',
        //consists of one reference
        children: [
            {text: ''},

            {
                type: 'reference',
                children: [{text}],
                referenceText: text,
                refPageId: refPageId,
            } as ReferenceElement,
            {text: ''},
        ],
        id: newNoteId,
    }
    return newChild
}

export default FirebaseWriter22
