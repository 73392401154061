import React, {useEffect, useMemo, useState} from 'react'
import {useDispatch, useSelector} from 'react-redux'
import {selectShowStore, setShowStore} from '../../Slices/ConceptStoreSlice'
import './PageStore.css'
import {selectPages} from '../../Slices/PagesSlice'
import {selectTitleIds} from '../../Slices/AppSlice'
import menuicon from '../../Assets/menuicon.png'

const PageStoreTitleButton = () => {
    const dispatch = useDispatch()
    const showStore = useSelector(selectShowStore)
    const conceptCount = Object.keys(useSelector(selectPages)).length
    const titleIds = useSelector(selectTitleIds)
    const isMobile = useMemo(
        () =>
            /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
                navigator.userAgent
            ),
        []
    )

    const [clicked, setClicked] = useState(showStore)
    const [afterFirst, setAfterFirst] = useState(false)
    useEffect(() => {
        if (afterFirst) dispatch(setShowStore(true))
        else setAfterFirst(true)
    }, [clicked])

    return showStore ? (
        <></>
    ) : (
        <span
            id="concept-store-title"
            className={showStore ? 'open' : 'closed'}
            onClick={() => setClicked(prev => !prev)}
            title={showStore ? 'collapse concepts' : 'show concepts'}
        >
            {/* <span className="var-number">
                {true || conceptCount > 9 ? (
                    <span className="number-text">{conceptCount}</span>
                ) : (
                    numberToWordsConverter.toWords(conceptCount)
                )}
                </span>*/}
            <img className="menu-icon" src={menuicon} />
            {/* <div id="concept-caret" className={showStore ? ' ninety-degrees' : ''}>
                ▸
            </div> */}
            {/* search / create */}
        </span>
    )
}

export default PageStoreTitleButton
