import {SlateNoteElement} from '../../Components/Page/SimplerSlateEditor/EditorContainer/slateConfig'
import uniqid from 'uniqid'
//does not include the

//The rule for now: everything after the first page is in the folder of the first page

const sampleUseCaseContent = [
    `Sample Meetings
Click connect on either the page for either sample meeting to see some connecting magic.`,
    `Meeting with Davey
Studied Symbolic Systems at Williams, Dropped out
Symbolic Systems is a mix of graph theory, philosophy, and computer science.
Founded Plexus because he's obsessed with helping people make connections
Worked on Apple's Screen Time Team
Met Sasha, who was working on the Photos Knowledge team`,

    `Meeting with Sasha
Worked at Apple for five years
A graph theory wizard
Been in touch with Davey for a couple years
Getting rid of the CONNECT button (connection-making as you type)
   `,
]
const onboardingPagesContentTake3 = [
    `Welcome!
Plexus Notes is an app that links people, places, and ideas across scattered notes.
When you repeat a meaningful phrase across pages, press the Connect button, and Plexus will turn it into a link.
    `,

    `Tutorial Videos
Bringing over notes from Apple Notes: https://www.loom.com/share/e3a5ac7fd0ca448aa8fd500f739c46aa
Bringing over notes from Google Drive: https://www.loom.com/share/93c39b1e450c417781a3081441867a2f
Organizing your pages in the sidebar: https://www.loom.com/share/8ab7c0cbdf8e4e09aea53cffa6f0f3dd`,
    `Plexus Notes
Located at the top of every page, the connect button finds meaningful phrases in the current page that repeat across the rest of your notes.
It takes the words on the current page and uses them to search for you.`,
]

export const makeSlateNotesFromTextArr = (textArr: string[]) => {
    return textArr.map((text, i) => {
        const theNote: SlateNoteElement = {
            type: 'note',
            //consists of one reference
            children: [{text}],
            id: uniqid(),
            isTitle: i == 0,
        }
        return theNote
    })
}

const transformSampleContent = pageContent => makeSlateNotesFromTextArr(pageContent.split('\n'))
export const onboardingPages: SlateNoteElement[][] = onboardingPagesContentTake3.map(
    transformSampleContent
)
export const sampleUseCasePages: SlateNoteElement[][] = sampleUseCaseContent.map(
    transformSampleContent
)
