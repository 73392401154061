import Trie from '@ganorberg/data-structures-javascript/structures/tree.trie.prefix'
import subtlex from 'subtlex-word-frequencies'
import {strip} from '../TextNormalization/TextNormalization'

export const commonWordsArray = [
    'and',
    'the',
    'it',
    'they',
    'or',
    'but',
    'nor',
    'so',
    'for',
    'yet',
    'after',
    'although',
    'as',
    'if',
    'because',
    'before',
    'once',
    'since',
    'though',
    'till',
    'unless',
    'until',
    'what',
    'when',
    'whenever',
    'wherever',
    'whether',
    'while',
    'all',
    'just',
    'even',
    'new',
    'when',
    'then',
    'only',
    'also',
    'how',
    'well',
    'most',
    'not',
    'as',
    'up',
    'there',
    'a',
    'A',
    'an',
    'this',
    'some',
    'way',
    'his',
    'her',
    'your',
    'its',
    'our',
    'to',
    'of',
    'in',
    'for',
    'on',
    'with',
    'at',
    'by',
    'from',
    'out',
    'into',
    'now',
    'over',
    'after',
    'but',
    'about',
    'like',
    'it',
    'he',
    'you',
    'we',
    'she',
    'which',
    'him',
    'them',
    'any',
    'these',
    'us',
    'what',
    'who',
    'be',
    'have',
    'would',
    'get',
    'could',
    'see',
    'likes',
    'new',
    'its',
    'the',
    'it',
    'they',
    'or',
    'but',
    'nor',
    'so',
    'for',
    'yet',
    'after',
    'had',
    'were',
    'although',
    'as',
    'if',
    'because',
    'before',
    'once',
    'since',
    'though',
    'till',
    'unless',
    'until',
    'what',
    'when',
    'whenever',
    'whether',
    'while',
    'all',
    'just',
    'even',
    'new',
    'when',
    'only',
    'also',
    'how',
    'well',
    'most',
    'not',
    'as',
    'up',
    'there',
    'a',
    'an',
    'no',
    'yes',
    'this',
    'some',
    'way',
    'was',
    'his',
    'her',
    'their',
    'your',
    'its',
    'our',
    'to',
    'of',
    'in',
    'for',
    'on',
    'with',
    'at',
    'by',
    'from',
    'out',
    'into',
    'now',
    'over',
    'after',
    'but',
    'about',
    'like',
    'it',
    'he',
    'you',
    'they',
    'we',
    'she',
    'which',
    'him',
    'them',
    'any',
    'these',
    'us',
    'what',
    'who',
    'be',
    'have',
    'would',
    'get',
    'could',
    'see',
    'likes',
    'its',
    '.',
    ',',
    '-',
    '-',
    '—',
    '–',
    '!',
    '?',
    ':',
    ';',
    '/',
    'than',
    'that',
    'will',
    'has',
    'been',
    'is',
    'are',
    'which',
    'previously',
    "isn't",
    "it'll",
    "wasn't",
    "didn't",
    "don't",
    'where',
    "weren't",
    "aren't",
    "I'm",
]

export const nonCommonWordsArray = ['time']

/**
 * To do: decide between this one and the meaningful phrases array file
 */
export const nonCommonWordsTrie = new Trie()
for (let i = 0; i < nonCommonWordsArray.length; i++) {
    nonCommonWordsTrie.put(nonCommonWordsArray[i], 1) //dummy value
}
export const commonWordsTrie = new Trie()
for (let i = 0; i < commonWordsArray.length; i++) {
    commonWordsTrie.put(commonWordsArray[i], 1) //dummy value
}

//for constant time lookup
export const subtlexTrie = new Trie()
for (let i = 0; i < subtlex.length; i++) {
    subtlexTrie.put(strip(subtlex[i].word).strippedText, subtlex[i].count) //dummy value
}
