import React, {useState, useEffect, Fragment} from 'react'
import PlexusDataContainer from './PlexusDataContainer'
import {BrowserRouter, Switch, Route, Redirect, Link, withRouter} from 'react-router-dom'
import SignInEmail from '../Authentication/SignInEmail'
import firebase from 'firebase/app'
// import "firebase/auth";
import firebaseConfig from '../FirebaseConfig'
import PrivateRoute from '../Authentication/PrivateRoute'
import StartingPage from '../Authentication/StartingPage/StartingPage'
import {FirebaseAuthProvider} from '@react-firebase/auth'
import AuthContext from '../Contexts/AuthContext'
import * as FullStory from '@fullstory/browser'
import RedirectPage from '../Authentication/StartingPage/RedirectPage'

//Unclear if I need this wrapper component
export const app = firebase.initializeApp(firebaseConfig)
export const provider = new firebase.auth.GoogleAuthProvider()
export const auth = firebase.auth()
export const analytics = firebase.analytics()

let signed = false

export const isMobile = /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
    navigator.userAgent
)

// Confirm the link is a sign-in with email link.
if (auth.isSignInWithEmailLink(window.location.href)) {
    console.log('link is a sign in with email link')
    // Additional state parameters can also be passed via URL.
    // This can be used to continue the user's intended action before triggering
    // the sign-in operation.
    // Get the email if available. This should be available if the user completes
    // the flow on the same device where they started it.
    var email = window.localStorage.getItem('emailForSignIn')
    if (!email) {
        // User opened the link on a different device. To prevent session fixation
        // attacks, ask the user to provide the associated email again. For example:
        email = window.prompt('Please provide your email for confirmation')
    }
    // The client SDK will parse the code from the link for you.
    auth.signInWithEmailLink(email, window.location.href)
        .then(result => {
            // Clear email from storage.
            window.localStorage.removeItem('emailForSignIn')
            console.log('logging on via link was successful', result.user)

            // You can access the new user via result.user
            // Additional user info profile not available via:
            // result.additionalUserInfo.profile == null
            // You can check if the user is new or existing:
            // result.additionalUserInfo.isNewUser
        })
        .catch(error => {
            console.log('logging on via link, there was an error', error.code)
            // Some error occurred, you can inspect the code: error.code
            // Common errors could be invalid email and invalid or expired OTPs.
        })
} else {
    console.log('link is not sign in with email link')
}

/**
 * Contains routing and auth logic
 */
function App() {
    //whether persistence has already been checked
    const [persistenceChecked, setPersChecked] = useState(false)
    const [user, setUser] = useState(null)
    const emailLogin = (event, state) => {
        event.preventDefault()
        const {email, password} = state
        auth.signInWithEmailAndPassword(email, password).catch(err => {
            if (err.message.includes('disabled'))
                window.alert(
                    'This alpha account was deactivated (inactivity). To regain access to your notes and the Plexus beta, reach out to Davey (or join our waitlist!).'
                )
            else window.alert(err.message)
        })
    }
    firebase
        .auth()
        .setPersistence(firebase.auth.Auth.Persistence.LOCAL)
        .then(function () {
            // Existing and future Auth states are now persisted in the current
            // session only. Closing the window would clear any existing state even
            // if a user forgets to sign out.
            // ...
            // New sign-in will be persisted with session persistence.
            //console.log('in set persistence', {...auth}, auth);
            // if (auth.currentUser) {
            //     return firebase
            //         .auth()
            //         .signInWithEmailAndPassword(
            //             auth.currentUser.email,
            //             auth.currentUser.password
            //         )
            // }
        })
        .catch(function (error) {
            // Handle Errors here.
            var errorCode = error.code
            var errorMessage = error.message
            console.log(error.message)
        })
        .finally(e => {
            //resolve this loop before rendering the component
            setPersChecked(true)
        })
    useEffect(() => {
        auth.onAuthStateChanged(function (user) {
            if (user) {
                analytics.logEvent('log_in', {val: 'logging in!'})
                analytics.setUserId(user.uid)
                analytics.setUserProperties({email: user.email})
                setUser(user)

                // This is an example script - don't forget to change it!
                FullStory.identify(user.email, {
                    email: user.email,
                })
            } else {
                // User is signed out.
                // ...
            }
        })
    }, [])

    //the idea is not to do any redirects until persistence is checked
    return persistenceChecked ? (
        <AuthContext.Provider value={{user, setUser}}>
            <BrowserRouter>
                <div className="Plexus-container">
                    <Switch>
                        <PrivateRoute path="/plex" component={PlexusDataContainer} />
                        <Route
                            path="/login"
                            render={() => <SignInEmail emailLogin={emailLogin} />}
                        />
                        {/* <Route path="/" exact render={() => <RedirectPage />} /> */}
                        <Route
                            path="/"
                            render={() => <StartingPage emailLogin={emailLogin} />}
                        />
                        <Route
                            path="/landing"
                            render={() => <StartingPage emailLogin={emailLogin} />}
                        />

                        {/* <Route
                            path="/login"
                            render={() => <StartingPage emailLogin={emailLogin} />}
                        /> */}
                    </Switch>
                </div>
            </BrowserRouter>
        </AuthContext.Provider>
    ) : (
        <></>
    )
}

export default App
