import React, {
    Component,
    Dispatch,
    SetStateAction,
    useContext,
    useEffect,
    useMemo,
    useState,
} from 'react'
import './PageStore.css'
import TopLevelContext from '../../Contexts/TopLevelContext'
import {useDispatch, useSelector} from 'react-redux'
// import {Menu, MenuItem, MenuButton} from '@szhsin/react-menu'
// import '@szhsin/react-menu/dist/index.css'
// import '@szhsin/react-menu/dist/transitions/slide.css'

import {SelectionAction, selectTitleIds, setTitleIds} from '../../Slices/AppSlice'
import {arrayUnique} from '../../Miscellaneous/Miscellaneous'
import {selectShowStore, setShowStore} from '../../Slices/ConceptStoreSlice'
import AddPageButton from './AddPageButton'
import {selectPages, setPages} from '../../Slices/PagesSlice'
import {PageOriginStory, PlexusPage} from '../../Algorithms/EntityRecognizer/PageInterfaces'
import {pageIsContentfulOrManuallyMade, shouldShowPage} from './FilterPages'
import PageTreeInStore from './PageTreeInStore.tsx/PageTreeInStore'
import {gbl, simpleFirebaseWriter} from '../PlexusDataContainer'
import SearchPages, {initialSearchValue} from './SearchPages'
import GraphBuilderLite, {
    PlexusPagesMap,
} from '../../Algorithms/GraphBuilding/GraphBuilderLite/GraphBuilderLite'
import {ReactEditor, withReact} from 'slate-react'
import {withHistory} from 'slate-history'
import {createEditor, Descendant, Node, Transforms} from 'slate'
import {initial} from 'lodash'
import CloseStoreButton from './CloseStoreButton'
import ToggleStoreCaret from './ToggleStoreCaret'
import FolderLogic from './FolderLogic/FolderLogic'
import {string} from 'prop-types'
import {exists} from 'fs'
import {addPage, addPageAndFocus, processPage} from '../../UnifiedMutations/UnifiedMutations'
import {extractRawText} from 'mammoth'
import {makeSlateNotesFromTextArr} from '../../BackendDataManagement/Jan2022/InitOnboardingContent'
import {resolve} from 'path'
import UploadNotesButton from './UploadNotes/UploadNotesButton'
import {usePrevious} from '../Miscellaneous/UsePreviousHook'
import {isMobile} from '../App'

export function handleDrop(
    dataTransfer: DataTransfer,
    pages: PlexusPagesMap,
    openPage: Function,
    resolve: Function
): void {
    textFilesInDataTransfer(dataTransfer).then(textPromises => {
        const newPages = []

        //add pages

        const addPagesPromise = new Promise<any[]>(resolve => {
            const textPromisesLength = textPromises.length
            textPromises.forEach((textPromise, i) => {
                console.log('Promise!')
                textPromise.then((text: string) => {
                    const newLines = text.split('\n')

                    console.log('Text of file: ' + text)
                    const {promise, page} = addPage(
                        newLines[0],
                        gbl,
                        simpleFirebaseWriter,
                        true,
                        PageOriginStory.MANUAL,
                        makeSlateNotesFromTextArr(newLines)
                    )
                    newPages.push([promise, page])
                    if (newPages.length == textPromisesLength) {
                        promise.then(() => resolve(newPages))
                    }
                })
            })
        })
        //then process, not guaranteed after but ok for now

        addPagesPromise.then(newPages => {
            const newPagesLength = newPages.length
            newPages.forEach(([promise, page], i) =>
                promise.then(() => {
                    processPage(
                        page,
                        gbl,
                        simpleFirebaseWriter,
                        undefined,
                        pages,
                        page.slateValue
                    ).then(e => {
                        if (i == newPagesLength - 1) {
                            openPage(page.id)
                            resolve()
                        }
                    })
                })
            )
        })
    })
}

export const CREATE_NEW_ID = 'create-new'
export const pageSorter = (a, b) => {
    return (b.lastUpdated ? b.lastUpdated : 0) - (a.lastUpdated ? a.lastUpdated : 0)
}

function visitItem(item: FileSystemEntry, visitor: FileCallback) {
    if (item && item.isFile) {
        console.log('file')
        let file = item as FileSystemFileEntry
        file.file(visitor)
    } else if (item && item.isDirectory) {
        console.log('directory')
        let directory = item as FileSystemDirectoryEntry
        directory
            .createReader()
            .readEntries(entries => entries.forEach(entry => visitItem(entry, visitor)))
    } else {
        console.log('Uh oh')
    }
}
function textFilesInDataTransfer(dataTransfer: DataTransfer): Promise<Promise<string>[]> {
    const promise = new Promise<Promise<string>[]>(resolve => {
        let result = []

        if (!dataTransfer) {
            // TODO: log error
            return result
        }

        const items = dataTransfer.items
        for (let i = 0; i < items.length; i++) {
            let item = items[i].webkitGetAsEntry()
            visitItem(item, file => {
                if (file.type === 'text/plain') {
                    result.push(
                        new Promise<string>(resolve => {
                            file.text().then(text => resolve(file.name + '\n' + text))
                        })
                    )
                } else {
                    const components = file.name.split('.')
                    if (components.length !== 2) {
                        // TODO: return error
                        console.log(`Invalid file name {file.name}`)
                    } else if (components[1] === 'docx') {
                        file.arrayBuffer().then(arrayBuffer => {
                            result.push(
                                //janky way to make the promise return a string
                                new Promise<string>(resolve =>
                                    extractRawText({arrayBuffer: arrayBuffer}).then(x =>
                                        resolve(file.name + '\n' + x.value)
                                    )
                                )
                            )
                        })
                    } else {
                        // TODO: return error
                        console.log(`Unsuported file extension {fileExtension}`)
                        console.log(file)
                    }
                }
                debugger

                //super jank
                setTimeout(() => {
                    resolve(result)
                }, 200)
                // if (i == itemsLength - 1) {
                // }
            })
        }
    })

    return promise
}

const PageStore = () => {
    const {switchPage} = useContext(TopLevelContext)
    const dispatch = useDispatch()
    const titleIds = useSelector(selectTitleIds)
    const [dragOver, setDragOver] = useState(false)
    const openForBusiness = useSelector(selectShowStore)
    const maxChars = 48

    const pagesMap = useSelector(selectPages)
    const pagesArr = useMemo(() => {
        return Object.values(pagesMap)
    }, [pagesMap]) //used to have pagesMap in there

    const [manualFolders, setManualFolders] = useState(true)

    const pages: PlexusPage[] = useMemo(() => {
        if (manualFolders) {
            const unsorted = FolderLogic.filterPagesForPageStore(
                pagesArr,
                pagesMap,
                titleIds[0]
            )
            const sorted = [...unsorted.sort(pageSorter)].slice(0, 100)

            return sorted
        } else {
            const sortedPages = pagesArr.sort(pageSorter).slice(0, 100)
            const filteredPages = sortedPages.filter(pageIsContentfulOrManuallyMade)
            //Right automatic filterer here
            const filtered = FolderLogic.filterPagesArrAutomatic(filteredPages, pagesMap)
            return filtered
        }
    }, [titleIds[0], pagesArr, manualFolders])

    //
    //For search bar
    const [searchEditor] = React.useState(() => withReact(withHistory(createEditor())))

    const [searchValue, setSearchValue] = React.useState<Descendant[]>(initialSearchValue)

    const [query, setQuery] = useState('')
    const [queryFocused, setQueryFocused] = useState(false)

    const queriedPages = useMemo(() => {
        //Currently just goes through titles
        if (!query) return []
        const queryWords = GraphBuilderLite.cleanPhrase(query)
        let existsPerfect = false
        const result = pagesArr.filter(page => {
            if (page.id in pagesMap) {
                let thisIsPerfect = true

                //get all the title words cleaned
                const titleWords = GraphBuilderLite.cleanPhrase(page.title)

                //make sure it includes each of the query words
                for (let i = 0; i < queryWords.length; i++) {
                    const word = queryWords[i]
                    if (!titleWords.includes(word)) thisIsPerfect = false
                    if (!stringArrIncludes(titleWords, word)) return false
                }
                if (titleWords.length != queryWords.length) thisIsPerfect = false
                if (thisIsPerfect) existsPerfect = true
                return true
            }
            return false
        })
        const resultIds = result ? result.map(e => e.id) : []

        //Look at page content too.
        //See if I can write function for going through dictionary
        const newPageIds = Object.keys(gbl.findWhereOccursByPage(query, true))
        if (query === 'contestant') debugger
        const newResultIds = arrayUnique([...resultIds, ...newPageIds])
        const newResult = newResultIds
            .map(id => (id in pagesMap ? pagesMap[id] : undefined))
            .filter(e => e)

        //create new option
        const newOptionPage: PlexusPage = {
            author_id: undefined,
            slateValue: [],
            title: query,
            id: CREATE_NEW_ID, //hacky way to tell
            incomers: undefined,
            outgoers: undefined,
        }
        if (!existsPerfect) newResult.push(newOptionPage)
        return newResult
    }, [query, pagesMap])
    React.useEffect(() => {
        setQuery(Node.string(searchEditor))
    }, [searchValue])

    //Set to a particular page if no page is set
    useEffect(() => {
        if (!titleIds || titleIds.length == 0 || !pagesMap[titleIds[0]]) {
            if (pages[0]) switchPage(pages[0].id)
        }
    }, [titleIds, pages.length])

    return (
        <div id="concept-store-container">
            <div id="concept-store">
                <ToggleStoreCaret symbol={undefined} />
                <div className="concept-store-buttons">
                    {/* {isMobile ? <></> : <UploadNotesButton />} */}
                    <AddPageButton
                        {...{
                            openForBusiness,
                            focusEditor: () => {
                                Transforms.select(searchEditor, [])
                                ReactEditor.focus(searchEditor)
                            },
                        }}
                    />
                </div>
                <div className="search-and-caret">
                    <SearchPages
                        {...{
                            topQueriedPageId:
                                queriedPages.length > 0 ? queriedPages[0].id : undefined,
                            searchEditor,
                            searchValue,
                            queryFocused,
                            setQueryFocused,
                            setSearchValue,
                        }}
                    />
                </div>
                {openForBusiness ? (
                    <div className="concept-list-in-store">
                        {/* <div
                            style={{
                                alignSelf: 'center',
                                textAlign: 'center',
                                fontStyle: 'italic',
                                fontSize: 'small',
                                opacity: 0.5,
                                cursor: 'pointer',
                            }}
                        >
                            <span
                                onClick={() => setManualFolders(true)}
                                style={{opacity: !manualFolders ? 0.5 : 1}}
                            >
                                manual
                            </span>
                            <span style={{opacity: 0.5}}> / </span>
                            <span
                                onClick={() => setManualFolders(false)}
                                style={{opacity: manualFolders ? 0.5 : 1}}
                            >
                                dynamic
                            </span>
                        </div> */}
                        {(query ? queriedPages : pages).map((page: PlexusPage, index) => {
                            const isTopQueriedPage =
                                queryFocused && query.length > 0 && index == 0
                            if (page.id === CREATE_NEW_ID) {
                                return (
                                    <div
                                        className={
                                            'concept-in-store' +
                                            (isTopQueriedPage ? ' topQueriedPage' : '')
                                        }
                                        style={{marginLeft: '1em'}}
                                        //need to de dep logic with enter
                                        onClick={() => {
                                            addPageAndFocus(
                                                Node.string(searchEditor),
                                                gbl,
                                                simpleFirebaseWriter,
                                                switchPage,
                                                PageOriginStory.MANUAL
                                            )
                                            searchEditor.selection = undefined
                                            setSearchValue(initialSearchValue)
                                        }}
                                    >
                                        Create new "{page.title}"
                                    </div>
                                )
                            }
                            return (
                                <PageTreeInStore
                                    key={page.id}
                                    {...{
                                        page,
                                        openForBusiness,
                                        titleIds,
                                        maxChars,
                                        index,
                                        title: page.title,
                                        isTopQueriedPage,
                                        autoFolders: !manualFolders,
                                        path: [],
                                    }}
                                />
                            )
                        })}
                    </div>
                ) : (
                    <></>
                )}
                <br></br>
            </div>
        </div>
    )
}

export default PageStore

const stringArrIncludes = (stringArr: string[], query: string) => {
    for (let i = 0; i < stringArr.length; i++) {
        const thisString = stringArr[i]
        if (thisString.includes(query)) return true
    }
    return false
}
