import {FirebaseAuthConsumer} from '@react-firebase/auth'
import React, {useState, useEffect, useContext} from 'react'
import {Redirect, withRouter} from 'react-router-dom'

import {auth, provider} from '../Components/App'
import AuthContext from '../Contexts/AuthContext'
import SignInView from './SignInView'

function SignInEmail({emailLogin}) {
    const [signedIn, setSignedIn] = useState(false)
    const handleSubmit = e => {
        e.preventDefault()
    }
    const {user, setUser} = useContext(AuthContext)

    useEffect(() => {}, [])
    return user ? <Redirect to="/plex" /> : <SignInView onSubmit={emailLogin} />
}
export default SignInEmail
