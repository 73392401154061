import {uniqueId} from 'lodash'
import {Descendant} from 'slate'
import FirebaseWriter22 from '../../BackendDataManagement/Jan2022/FirebaseWriter22'
import {
    FormattedText,
    NotePart,
    ReferenceElement,
    SlateNoteElement,
} from '../../Components/Page/SimplerSlateEditor/EditorContainer/slateConfig'
import FolderLogic from '../../Components/PageStore/FolderLogic/FolderLogic'
import GraphBuilderLite from '../GraphBuilding/GraphBuilderLite/GraphBuilderLite'
import {EnglishEntity, getRawWords} from './EnglishEntitiesHelpers/EnglishEntities'
import {PlexusParagraph} from './PageInterfaces'

//This file contains a main function, `getSlateNoteFromWordsAndEntities` which returns a SlateEditorValue
// given a paragraph and its entities

/**
 * For a given Plexus paragraph, along with an array of entities it contains,
 * returns that paragraph as a SlateEditorValue (so it can be rendered)
 * @param paragraph a paragraph of a given page of notes
 * @param entities a list of entities that were detected within the given paragraph
 * @returns
 */
export const getSlateNoteFromWordsAndEntities = (
    paragraph: PlexusParagraph,
    entities: EnglishEntity[]
): SlateNoteElement => {
    //This array of words is different than the decorated words found above
    const words: string[] = getRawWords(paragraph.text)
    let wordsAndEntities: (string | EnglishEntity)[] = words

    //replace proper words with entities
    //move backwards so indices don't get fucked (enttities should be returned backwards anyway)
    entities.reverse().forEach(entity => {
        const {startIndex, endIndex} = entity.wordRange //perfect, this where I could make the substitution
        wordsAndEntities = [
            ...wordsAndEntities.slice(0, startIndex),
            {...entity, phrase: words.slice(startIndex, endIndex).join(' ')},
            ...wordsAndEntities.slice(endIndex),
        ]
    })

    const newChildren: NotePart[] =
        wordsAndEntities.length > 0
            ? wordsAndEntities.reduce(
                  (childrenSoFar: NotePart[], part: string | EnglishEntity, index) => {
                      let meatNode:
                          | ReferenceElement
                          | FormattedText = getNotePartFromEnglishEntity(part, paragraph)
                      let spaceNode = {text: index != 0 ? ' ' : ''}
                      childrenSoFar = childrenSoFar.concat(spaceNode, meatNode)
                      return childrenSoFar
                  },
                  []
              )
            : [{text: ''}]

    //Clean up slate note's children
    const cleanedNoteChildren = cleanupSlateNoteDescendants(newChildren)

    //Ensure an end text node
    const newNote: SlateNoteElement = {
        id: paragraph.paragraphId,
        type: 'note',
        children: cleanedNoteChildren,
        index: paragraph.paragraphIndex,
    }

    return newNote
}

export const getNotePartFromEnglishEntity = (
    part: string | EnglishEntity,
    paragraph: PlexusParagraph
): ReferenceElement | FormattedText => {
    let meatNode: ReferenceElement | FormattedText //the word or reference

    if (typeof part === 'string') {
        meatNode = {text: part}
    } else {
        const referenceText = (part as EnglishEntity).phrase
        //check if title
        if (
            paragraph.paragraphIndex == 0 &&
            FolderLogic.cleanTextMatch(paragraph.text, referenceText)
        ) {
            meatNode = {text: part.phrase}
        } else {
            meatNode = {
                type: 'reference',
                referenceText,
                children: [{text: referenceText}],
                paragraphId: paragraph.paragraphId,
                paragraphIndex: paragraph.paragraphIndex,
            }
        }
    }
    return meatNode
}
/**
 * Quite janky
 */
export const configureGraphForRepeatingEntities = (
    entities: EnglishEntity[],
    simpleFirebaseWriter: FirebaseWriter22,
    gbl: GraphBuilderLite,
    thisParagraph: PlexusParagraph
): EnglishEntity[] => {
    //for each repeating entity
    entities.forEach((entity: EnglishEntity) => {
        if (entityRepeats(entity)) {
            //assert that it does have at least one location (should technically have two, but the other can be the present paragraph, which will get processed elsewhere anyway)
            console.assert(
                entity.refPageId || (entity.whereItRepeats && entity.whereItRepeats.length > 0)
            )

            //Ensure there's a page for it
            //Does a referenced page already exist for this one
            //this function should recalc, I think
            const referencedPage = findExistingPageForRepeatingEntity(entity, gbl)

            //FOR NOW, JUST HOOK DRAW EDGES TO EXISTING PAGES
            //should keep folder structure without making new pages until click
            if (referencedPage) {
                simpleFirebaseWriter.ensureEdgesAreDrawnToPage(referencedPage, [
                    ...entity.whereItRepeats,
                    thisParagraph,
                ])
                entity.refPageId = referencedPage
            }
        }
    })

    //no modifications yet. eventually, will modify
    return entities
}

const filterEntitiesForRepeating = (entities: EnglishEntity[]): EnglishEntity[] => {
    return entities.filter(entity => entity.refPageId || entity.whereItRepeats.length > 0)
}
const entityRepeats = (entity: EnglishEntity): boolean => {
    return (
        (entity.refPageId && entity.refPageId.length > 0) ||
        (entity.whereItRepeats && entity.whereItRepeats.length > 0)
    )
}

/**
 * Checks if a page exists already in the graph for this entity (given the entity's data)
 * @param entity
 * @returns the page id if that page exists
 */
const findExistingPageForRepeatingEntity = (
    entity: EnglishEntity,
    gbl: GraphBuilderLite
): string | undefined => {
    //this is the easy way out
    const firstTryPageId = entity.refPageId
    if (firstTryPageId) return firstTryPageId

    //otherwise, look through all places where it repeats, and see if any of them are perfect
    //eventually, will update this when update the word entry data structure to only include titles
    //refind it now
    const locsWhereRepeats: PlexusParagraph[] = gbl.findWhereOccurs(entity.phrase)
    //entity.whereItRepeats

    //find if one of them has paragraphIndex 0 (means its title) and perfectly matching text (same number of words would do, or same cleaned phrase)
    const theOne: PlexusParagraph[] = locsWhereRepeats
        ? locsWhereRepeats.filter((loc: PlexusParagraph) => {
              return (
                  //   loc.paragraphIndex && //caused error with 0!
                  loc.paragraphIndex == 0 && FolderLogic.cleanTextMatch(loc.text, entity.phrase)
              )
          })
        : undefined

    //shouldn't be more than one page with the same title
    // (need to ensure this for now)
    console.assert(theOne.length <= 1)
    //if the one exists already, return it
    return theOne[0] ? theOne[0].pageId : undefined
}

const cleanupSlateNoteDescendants = (rawDescendants: Descendant[]): NotePart[] => {
    //slate editor needs text elements at either end
    const wrappedWithSpaces = [{text: ''}, ...rawDescendants, {text: ''}]

    //concatenate all adjacent text children.
    // so that instead of looking like ... {text: 'hello'}, {text: ' '}, {text: 'aria'}... it looks like ...{text: 'hello aria'}...
    const concatenatedChildren = concatenateSlateNoteDescendants(wrappedWithSpaces)
    return concatenatedChildren
}

const concatenateSlateNoteDescendants = (wrappedWithSpaces: Descendant[]) => {
    const concatenatedChildren = wrappedWithSpaces.reduce((childrenSoFar, nextOne) => {
        const lastChildSoFar =
            childrenSoFar.length > 0 ? childrenSoFar[childrenSoFar.length - 1] : undefined
        if (lastChildSoFar) {
            //If the last one and this one are both text types, combine em
            if (
                'text' in lastChildSoFar &&
                typeof lastChildSoFar.text === 'string' &&
                'text' in nextOne &&
                typeof nextOne.text === 'string'
            ) {
                lastChildSoFar.text = lastChildSoFar.text + nextOne.text
                return childrenSoFar
            }
            //otherwise don't combine
            else return [...childrenSoFar, nextOne]
        } else {
            return [nextOne]
        }
    }, [])
    return concatenatedChildren
}
